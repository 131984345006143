import { PluginRegistry, Plugin, PluginInfo } from 'plugin-system'

export default class ConfigPlugin extends Plugin {
  constructor(readonly env: Record<string, any>) {
    super()
  }

  get info(): PluginInfo {
    return { name: 'config' }
  }

  async activate(registry: PluginRegistry): Promise<void> {
    const config = Object.entries(this.env).reduce((acc, [key, value]) => {
      acc[key.replace(/^VITE_/, '')] = value
      return acc
    }, {} as Record<string, string>)

    registry.fillSlot('shell.config', config)
  }
}
