import debug from 'debug'
import React from 'react'
import * as ReactRouter from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserOptions, BrowserTracing } from '@sentry/browser'
import { Plugin, PluginInfo, PluginRegistry } from 'plugin-system'

const log = debug('shell:SentryPlugin')

export default class SentryPlugin extends Plugin {
  get info(): PluginInfo {
    return { name: 'sentry' }
  }

  async activate(registry: PluginRegistry): Promise<void> {
    log('activate')
    const config = registry.slots.getValue('shell.config')

    if (!config.SENTRY_DSN) {
      log('No SENTRY_DSN provided. Skipping Sentry initialization.')
      return
    }

    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/
    const options: BrowserOptions = {
      dsn: config.SENTRY_DSN,
      release: config.SENTRY_RELEASE,
      environment: config.SENTRY_ENVIRONMENT,
      integrations: [
        new BrowserTracing({
          // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            ReactRouter.useLocation,
            ReactRouter.useNavigationType,
            ReactRouter.createRoutesFromChildren,
            ReactRouter.matchRoutes,
          ),
        }),
      ],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    }

    Sentry.init(options)

    log('Sentry initialized', options)

    registry.fillSlot(
      'shell.router.wrapCreateBrowserRouter',
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.wrapCreateBrowserRouter,
    )

    registry.fillSlot(
      'shell.content',
      // https://docs.sentry.io/platforms/javascript/guides/react/features/component-tracking/
      Sentry.withProfiler(({ children }: React.PropsWithChildren<{}>) => <>{children}</>),
      { container: true },
    )
  }

  deactivate(registry: PluginRegistry): void {
    Sentry.close(5000).then(() => log('Sentry closed'))
  }
}
