import { Plugin } from 'plugin-system'
import * as portal from 'portal'
import * as payments from 'payments'
import * as community from 'community'
import * as drops from 'drops'
import * as settings from 'settings'
import RouterPlugin from '@/plugins/RouterPlugin'
import ShellPlugin from '@/plugins/ShellPlugin'
import ConfigPlugin from '@/plugins/ConfigPlugin'
import LaunchDarklyFeatureFlagsPlugin from '@/plugins/LaunchDarklyFeatureFlagsPlugin'
import SentryPlugin from '@/plugins/SentryPlugin'

const plugins: Plugin[] = [
  new ShellPlugin(),
  new ConfigPlugin(window.env),
  new SentryPlugin(),
  new LaunchDarklyFeatureFlagsPlugin(),
  new payments.PaymentsPlugin(),
  new drops.AirdropPlugin(),
  new drops.FreeClaimPlugin(),
  new drops.TokenOfAppreciationPlugin(),
  new drops.TokenOfOnlineParticipationPlugin(),
  new drops.TokenOfRealLifeAttendancePlugin(),
  new drops.PrimarySalePlugin(),
  new drops.DropToEarnPlugin(),
  new drops.LifeOnLineaPlugin(),
  new drops.DropActionsPlugin(),
  new drops.DropOverviewPlugin(),
  new drops.DropDetailsPlugin(),
  new community.MainPlugin(),
  new community.SnapshotPlugin(),
  new community.FollowersPlugin(),
  new community.AudiencesPlugin(),
  new settings.ProfileEditPlugin(),
  new settings.ProfileSetupPlugin(),
  new portal.ApiPlugin(),
  new portal.AuthPlugin(),
  new portal.LayoutPlugin(),
  new portal.ThemeDefaultPlugin(),
  new portal.NotificationsPlugin(),
  new RouterPlugin(),
]

export default plugins
