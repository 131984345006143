import {
  LDClient,
  LDContext,
  LDFlagChangeset,
  LDFlagSet,
  initialize as LDClient_initialize,
} from 'launchdarkly-js-client-sdk'
import {
  PLUGIN_ACTIVATION_SLOT,
  Plugin,
  PluginActivationSlotData,
  PluginInfo,
  PluginRegistry,
} from 'plugin-system'
import { proxy, snapshot } from 'valtio'
import { subscribeKey } from 'valtio/utils'
import debug from 'debug'
import { AuthClearEvent, AuthPlatformSetEvent } from 'common-ui'

const log = debug('shell:LaunchDarklyFeatureFlagsPlugin')

const DEFAULT_CONTEXT: LDContext = {
  kind: 'user',
  key: 'user',
}

interface State {
  flags: LDFlagSet
  context: LDContext
}

export default class LaunchDarklyFeatureFlagsPlugin extends Plugin<State> {
  private ldClient?: LDClient
  private featureFlagsPrefix: string = ''

  constructor() {
    super()
    this.state = proxy({ flags: {}, context: {} })

    subscribeKey(this.state, 'flags', () => {
      log('state', snapshot(this.state.flags))
    })
  }

  get info(): PluginInfo {
    return { name: 'feature-flags' }
  }

  onFlagChangeListener!: (flags: LDFlagChangeset) => void

  async updateUserContext(context: LDContext) {
    log('setting new context', JSON.stringify(context))

    this.state.context = context

    return this.ldClient?.identify(context)
  }

  async activate(registry: PluginRegistry): Promise<void> {
    log('activate')
    const config = registry.slots.getValue('shell.config')

    const featureFlagsClientId = config.FEATURE_FLAGS_CLIENT_ID
    this.featureFlagsPrefix = config.FEATURE_FLAGS_PREFIX

    if (!featureFlagsClientId) {
      log('No FEATURE_FLAGS_CLIENT_ID provided. Skipping LaunchDarkly initialization.')
      return
    }

    this.state.context = DEFAULT_CONTEXT

    try {
      const ldClient = LDClient_initialize(featureFlagsClientId, DEFAULT_CONTEXT, {
        bootstrap: 'localStorage',
        streaming: false,
      })
      await ldClient.waitUntilReady()
      this.state.flags = ldClient.allFlags()
      this.onFlagChangeListener = flags => this.onFlagsChange(flags, registry)
      ldClient.on('change', this.onFlagChangeListener)
      this.ldClient = ldClient
    } catch (error) {
      log('error', error)
      return
    }

    // Update LD context when user logs in
    registry.subscribeEvent<AuthPlatformSetEvent>('auth.platform.set', async event => {
      // Limit the org context to only when org-context flag is enabled
      const { org } = event.data

      if (!org.id) {
        return
      }

      const newContext = {
        kind: 'organization',
        key: org.id,
      }

      await this.updateUserContext(newContext)
    })

    // Update LD context when user logs out
    registry.subscribeEvent<AuthClearEvent>('auth.clear', async () => {
      await this.updateUserContext(DEFAULT_CONTEXT)
    })

    registry.fillSlot<PluginActivationSlotData>(
      PLUGIN_ACTIVATION_SLOT.id,
      this.willActivatePlugin.bind(this),
    )
  }

  deactivate(registry: PluginRegistry) {
    log('deactivate')
    this.ldClient?.off('change', this.onFlagChangeListener)
    this.state.flags = {}
  }

  private onFlagsChange(flags: LDFlagChangeset, registry: PluginRegistry) {
    log('onFlagsChange', flags)

    // set current values
    for (const [flag, change] of Object.entries(flags)) {
      this.state.flags[flag] = change.current
    }

    // activate
    for (const plugin of Object.values(registry.map)) {
      const flag = this.getFlagName(plugin)
      const change = flags[flag]
      if (change?.current === true) {
        registry.activate(plugin.key)
      }
    }

    // deactivate (in reverse order)
    for (const plugin of Object.values(registry.map).reverse()) {
      const flag = this.getFlagName(plugin)
      const change = flags[flag]
      if (change?.current === false) {
        registry.deactivate(plugin.key)
      }
    }
  }

  private willActivatePlugin(plugin: Plugin): boolean {
    const flag = this.getFlagName(plugin)
    const value = this.state.flags[flag]
    log('willActivatePlugin', {
      flag,
      value,
      disabledByDefault: plugin.info.disabledByDefault,
    })

    if (value == null) {
      return !plugin.info.disabledByDefault
    }

    return value
  }

  private getFlagName(plugin: Plugin): string {
    return (this.featureFlagsPrefix ?? '') + plugin.key.replace('@', '-')
  }
}
