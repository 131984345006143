var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/plugins/profile-setup/Plugin.tsx
import { Plugin } from "plugin-system";

// src/components/profile-setup/CreatorProfileSetupDialog.tsx
import {
  useApi as useApi2,
  useEditOrgNotificationSettings,
  useEditOrgProfile,
  useOrgProfile,
  useUpdatePayoutAddress
} from "common-api";
import { NotificationEvent, WizardSingleDialog, useFeatureFlags as useFeatureFlags2, useMobileEnabled } from "common-ui";
import { useCallback, useEffect as useEffect2, useMemo as useMemo2, useState as useState2 } from "react";
import { useSnapshot as useSnapshot2 } from "valtio";
import { useAuth as useAuth2 } from "common-ui";
import { usePluginRegistry } from "plugin-system";
import { Stack as Stack4, Typography as Typography4 } from "@mui/material";

// src/state/profile-setup/index.ts
import {
  WizardDialogManager
} from "common-ui";
import { proxy as proxy4 } from "valtio";

// ../../common/api/src/sdks/sdk-platform-beta.ts
import globalAxios2 from "axios";

// ../../common/api/src/sdks/base.ts
import globalAxios from "axios";

// ../../common/api/src/sdks/sdk-platform-beta.ts
var EditOrganizationProfileInputCreatorRolesEnum = {
  Artist: "ARTIST",
  ContentCreator: "CONTENT_CREATOR",
  EventOrganizer: "EVENT_ORGANIZER",
  CommunityManager: "COMMUNITY_MANAGER",
  NoneApply: "NONE_APPLY"
};
var OrganizationProfileOutputCreatorRolesEnum = {
  Artist: "ARTIST",
  ContentCreator: "CONTENT_CREATOR",
  EventOrganizer: "EVENT_ORGANIZER",
  CommunityManager: "COMMUNITY_MANAGER",
  NoneApply: "NONE_APPLY"
};

// src/state/profile-setup/username.ts
import { proxy } from "valtio";
import * as z from "zod";
var validationSchema = z.object({
  userSlug: z.string({ required_error: "Username is required." }).min(5, { message: "Username must contain at least 5 characters" }).max(75).regex(
    /^[a-zA-Z0-9._-]+$/,
    "Username can only contain letters, numbers, underscores, periods and hyphens."
  ),
  creatorRoles: z.array(z.string()).optional(),
  subscribed: z.boolean().optional()
});
var defaultStateValues = {
  userSlug: "",
  creatorRoles: [],
  subscribed: false
};
var reset = () => {
  for (const key in defaultState) {
    ;
    state[key] = defaultState[key];
  }
};
var defaultState = {
  stepType: "CREATOR_USERNAME",
  userSlug: "",
  title: "",
  isStatic: true,
  customButtons: null,
  description: "",
  disabled: false,
  readOnly: false,
  applicable: true,
  validationSchema,
  status: "NOT_STARTED",
  committed: void 0,
  commitType: "BACKEND",
  defaults: defaultStateValues,
  reset
};
var state = proxy(__spreadValues({}, defaultState));
var commit = (editOrgProfile, updateEmailNotificationSettings, candidateState, captureMarketingUpdatesConsent) => __async(void 0, null, function* () {
  var _a;
  try {
    if (captureMarketingUpdatesConsent) {
      yield updateEmailNotificationSettings.mutateAsync({
        marketing_updates_consent: candidateState.subscribed
      });
    }
    return yield editOrgProfile.mutateAsync({
      slug: candidateState.userSlug,
      creator_roles: !((_a = candidateState.creatorRoles) == null ? void 0 : _a.length) ? [EditOrganizationProfileInputCreatorRolesEnum.NoneApply] : candidateState.creatorRoles
    }).then((res) => res == null ? void 0 : res.data);
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
});

// src/state/profile-setup/optional.ts
import { isAddress } from "ethers";
import { proxy as proxy2 } from "valtio";
import * as z2 from "zod";
var validationSchema2 = z2.object({
  bannerUrl: z2.string().min(1).optional(),
  avatarUrl: z2.string().min(1).optional(),
  displayName: z2.string().min(1).max(80).optional(),
  creatorBio: z2.string().min(1).max(64).optional(),
  websiteLink: z2.string().url().optional(),
  instagramLink: z2.string().min(1).refine((value) => /^.*instagram\.com\/[\w.]+$/i.test(value), {
    message: "Please enter a valid Instagram profile link."
  }).optional(),
  twitterLink: z2.string().min(1).refine((value) => /^.*(twitter|x)\.com\/\w+$/i.test(value), {
    message: "Please enter a valid X profile link."
  }).optional(),
  discordLink: z2.string().min(1).refine((value) => /^.*discord\.(gg|com)\/.+$/i.test(value), {
    message: "Please enter a valid Discord invite link."
  }).optional(),
  payoutAddress: z2.string().max(42).nullish().refine((val) => !val || isAddress(val), {
    message: "Please enter a valid payout address."
  }),
  organizationId: z2.string().nullish()
});
var defaultStateValues2 = {
  bannerUrl: "",
  avatarUrl: "",
  displayName: "",
  creatorBio: "",
  websiteLink: "",
  instagramLink: "",
  twitterLink: "",
  discordLink: "",
  payoutAddress: ""
};
var reset2 = () => {
  for (const key in defaultState2) {
    ;
    state2[key] = defaultState2[key];
  }
};
var defaultState2 = {
  stepType: "OPTIONAL_INFO",
  displayName: "",
  creatorBio: "",
  title: "",
  websiteLink: "",
  instagramLink: "",
  twitterLink: "",
  discordLink: "",
  customButtons: null,
  isStatic: true,
  description: "",
  disabled: false,
  readOnly: false,
  applicable: true,
  validationSchema: validationSchema2,
  status: "NOT_STARTED",
  committed: void 0,
  commitType: "BACKEND",
  defaults: defaultStateValues2,
  reset: reset2
};
var state2 = proxy2(__spreadValues({}, defaultState2));
var commit2 = (editOrgProfile, updatePayoutAddress, candidateState) => __async(void 0, null, function* () {
  if (candidateState.payoutAddress && candidateState.organizationId) {
    yield updatePayoutAddress({
      organizationId: candidateState.organizationId,
      payoutAddress: candidateState.payoutAddress
    });
  }
  return yield editOrgProfile.mutateAsync({
    media: {
      banner: candidateState.bannerUrl || null,
      avatar: candidateState.avatarUrl || null
    },
    socials: {
      instagram: candidateState.instagramLink || null,
      twitter: candidateState.twitterLink || null,
      discord: candidateState.discordLink || null
    },
    public_name: candidateState.displayName || null,
    description: candidateState.creatorBio || null
  }).then((res) => res == null ? void 0 : res.data);
});

// src/state/profile-setup/complete.ts
import { proxy as proxy3 } from "valtio";
import * as z3 from "zod";
var validationSchema3 = z3.object({});
var defaultStateValues3 = {};
var reset3 = () => {
  for (const key in defaultState3) {
    ;
    state3[key] = defaultState3[key];
  }
};
var defaultState3 = {
  stepType: "COMPLETE",
  title: "",
  isStatic: false,
  description: "",
  disabled: false,
  readOnly: false,
  applicable: true,
  validationSchema: validationSchema3,
  status: "NOT_STARTED",
  committed: void 0,
  commitType: "MEMORY",
  defaults: defaultStateValues3,
  reset: reset3
};
var state3 = proxy3(__spreadValues({}, defaultState3));

// src/state/profile-setup/index.ts
var stepTypeStateMap = {
  CREATOR_USERNAME: state,
  OPTIONAL_INFO: state2,
  COMPLETE: state3
};
var rootStateDefaults = {
  open: true,
  //set this based on the user's profile setup status, specifically their slug
  isStatic: true,
  bootstrapContext: void 0,
  previousStepType: void 0,
  nextStepType: "OPTIONAL_INFO",
  customButtons: null,
  activeStep: state,
  nextButtonEnabled: false
};
var defaultStateValues4 = __spreadValues({
  defaults: rootStateDefaults
}, rootStateDefaults);
var state4 = proxy4(__spreadValues({}, defaultStateValues4));
var ProfileSetupWizardDialogManager = class extends WizardDialogManager {
  bootstrap() {
    console.error("not implemented for Phosphor profile setup");
  }
  memoryActiveStepCommit(activeStepCandidateValues) {
    this.wizardState.activeStep.committed = activeStepCandidateValues ? __spreadValues({}, activeStepCandidateValues) : {};
    this.wizardState.activeStep.status = "COMMITTED";
  }
  commitActiveStepState(activeStepCandidateValues, _, commitBag, _pluginRegistry, formikRef) {
    return __async(this, null, function* () {
      var _a, _b, _c, _d;
      const result = {};
      switch (this.wizardState.activeStep.stepType) {
        case "CREATOR_USERNAME":
          try {
            yield commit(
              commitBag.editOrgProfile,
              commitBag.updateEmailNotificationSettings,
              activeStepCandidateValues,
              commitBag.captureMarketingUpdatesConsent
            );
            this.memoryActiveStepCommit(activeStepCandidateValues);
            state.disabled = false;
          } catch (error) {
            const errorMessage = (_c = (_b = (_a = error == null ? void 0 : error.response) == null ? void 0 : _a.data) == null ? void 0 : _b.error) == null ? void 0 : _c.detail.replace(/slug/gi, "username").replace(/reserved/gi, "already taken");
            (_d = formikRef.current) == null ? void 0 : _d.setErrors({
              userSlug: errorMessage
            });
            result.success = false;
          }
          break;
        case "OPTIONAL_INFO":
          yield commit2(
            commitBag.editOrgProfile,
            commitBag.updatePayoutAddress,
            activeStepCandidateValues
          );
          this.memoryActiveStepCommit(activeStepCandidateValues);
          state2.disabled = false;
          break;
        case "COMPLETE":
          this.memoryActiveStepCommit(activeStepCandidateValues);
          state3.disabled = false;
          break;
      }
      return result;
    });
  }
  canCloseAfterCommit() {
    return false;
  }
};
var manager = new ProfileSetupWizardDialogManager(
  state4,
  stepTypeStateMap
);

// src/components/profile-setup/step-content/Username.tsx
import {
  Divider,
  FormControlLabel,
  Link,
  Stack,
  Checkbox as Checkbox2,
  TextField as TextField2,
  Typography,
  InputAdornment,
  CircularProgress
} from "@mui/material";
import { useFormikContext } from "formik";
import { useValidateOrgSlugAvailability } from "common-api";
import { FORM_PREVENT_SUBMIT, useFeatureFlags } from "common-ui";

// src/components/CreatorRoleAutocomplete.tsx
import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import { startCase } from "lodash";
import { jsx, jsxs } from "react/jsx-runtime";
var CreatorRoleAutocomplete = ({
  initialValues,
  onChange,
  size = "medium"
}) => {
  const [activeValues, setActiveValues] = useState(
    initialValues != null ? initialValues : []
  );
  const options = useMemo(() => {
    return Object.values(EditOrganizationProfileInputCreatorRolesEnum).map((role) => {
      return { value: role, label: startCase(role.toLowerCase().replace("_", " ")) };
    });
  }, []);
  const activeOptions = useMemo(() => {
    if (!(options == null ? void 0 : options.length) || !(activeValues == null ? void 0 : activeValues.length))
      return [];
    return activeValues.map((value) => {
      return options.find((option) => option.value === value);
    }).filter(Boolean);
  }, [activeValues, options]);
  return /* @__PURE__ */ jsx(
    Autocomplete,
    {
      multiple: true,
      size,
      id: "creator-role-autocomplete",
      options,
      disableCloseOnSelect: true,
      "data-testid": "creator-role-autocomplete",
      getOptionLabel: (option) => option.label,
      renderOption: (props, option, { selected }) => /* @__PURE__ */ jsxs("li", __spreadProps(__spreadValues({}, props), { children: [
        /* @__PURE__ */ jsx(Checkbox, { style: { marginRight: 8 }, checked: selected }),
        option.label
      ] })),
      renderTags: (value, getTagProps) => value.map((option, index) => /* @__PURE__ */ jsx(
        Chip,
        __spreadValues({
          color: "secondary",
          variant: "filled",
          label: `${option.label}`
        }, getTagProps({ index }))
      )),
      onChange: (_, chosenOpts) => {
        const selectedNoneApply = chosenOpts.some(
          (option) => option.value === EditOrganizationProfileInputCreatorRolesEnum.NoneApply
        );
        const newOptValues = selectedNoneApply ? [EditOrganizationProfileInputCreatorRolesEnum.NoneApply] : chosenOpts.map(
          (option) => option.value
        );
        setActiveValues(newOptValues);
        onChange(newOptValues);
      },
      value: activeOptions,
      sx: {
        ".MuiInputBase-root": { height: "auto", pb: 1 },
        flexGrow: 1
      },
      renderInput: (params) => /* @__PURE__ */ jsx(
        TextField,
        __spreadProps(__spreadValues({}, params), {
          label: "Self-Identification",
          InputProps: __spreadProps(__spreadValues({}, params.InputProps), {
            inputProps: __spreadProps(__spreadValues({}, params.inputProps), {
              placeholder: activeValues.length === 0 ? "Choose the closest match to you, as a creator" : ""
              // Placeholder logic
            })
          })
        })
      )
    }
  );
};

// src/components/profile-setup/step-content/Username.tsx
import { Fragment, jsx as jsx2, jsxs as jsxs2 } from "react/jsx-runtime";
var UsernameContent = ({
  agreeToTerms,
  setAgreeToTerms
}) => {
  const { onboardingMarketingUpdatesConsent } = useFeatureFlags();
  const formik = useFormikContext();
  const { mutateAsync: validateSlugAvailability } = useValidateOrgSlugAvailability();
  return /* @__PURE__ */ jsxs2(Stack, { spacing: 1, children: [
    /* @__PURE__ */ jsx2(
      TextField2,
      {
        fullWidth: true,
        id: "userSlug",
        name: "userSlug",
        type: "text",
        label: "Choose your username",
        placeholder: "Username",
        autoFocus: true,
        "data-testid": "profile-setup-slug",
        value: formik.values["userSlug"] || "",
        onChange: (e) => __async(void 0, null, function* () {
          formik.handleChange(e);
          formik.setFieldValue(FORM_PREVENT_SUBMIT, true, false);
          const data = yield validateSlugAvailability(e.target.value);
          if (data && !data.isAvailable) {
            formik.setFieldError("userSlug", "The slug is not available for use.");
          }
          formik.setFieldValue(FORM_PREVENT_SUBMIT, false, false);
        }),
        onBlur: formik.handleBlur,
        error: formik.touched["userSlug"] && Boolean(formik.errors["userSlug"]),
        disabled: formik.isSubmitting,
        helperText: formik.touched["userSlug"] && formik.errors["userSlug"],
        InputProps: formik.values[FORM_PREVENT_SUBMIT] && !formik.errors["userSlug"] ? {
          endAdornment: /* @__PURE__ */ jsx2(InputAdornment, { position: "end", children: /* @__PURE__ */ jsx2(CircularProgress, { size: 18 }) })
        } : /* @__PURE__ */ jsx2(Fragment, {})
      }
    ),
    /* @__PURE__ */ jsx2(
      CreatorRoleAutocomplete,
      {
        initialValues: formik.values.creatorRoles,
        size: "small",
        onChange: (values) => {
          formik.setFieldValue("creatorRoles", values != null ? values : []);
        }
      }
    ),
    /* @__PURE__ */ jsx2(Divider, { sx: { p: 1 } }),
    /* @__PURE__ */ jsx2(
      FormControlLabel,
      {
        control: /* @__PURE__ */ jsx2(
          Checkbox2,
          {
            checked: agreeToTerms,
            onChange: (e) => setAgreeToTerms(e.target.checked),
            "data-testid": "terms-checkbox"
          }
        ),
        label: /* @__PURE__ */ jsxs2(Typography, { variant: "body2", children: [
          "I have read and agree to ConsenSys'",
          " ",
          /* @__PURE__ */ jsx2(Link, { href: "https://consensys.io/privacy-policy/", target: "_blank", rel: "noopener", children: "Privacy Policy" }),
          " ",
          "and",
          " ",
          /* @__PURE__ */ jsx2(Link, { href: "https://consensys.io/terms-of-use/", target: "_blank", rel: "noopener", children: "Terms of Use" })
        ] })
      }
    ),
    onboardingMarketingUpdatesConsent && /* @__PURE__ */ jsx2(
      FormControlLabel,
      {
        control: /* @__PURE__ */ jsx2(
          Checkbox2,
          {
            checked: formik.values["subscribed"] || false,
            onChange: (e) => formik.setFieldValue("subscribed", e.target.checked),
            "data-testid": "notification-settings-checkbox"
          }
        ),
        label: /* @__PURE__ */ jsx2(Typography, { variant: "body2", children: "Keep me informed with updates and special announcements from Phosphor (unsubscribe at any time)" })
      }
    )
  ] });
};

// src/components/profile-setup/step-content/Optional.tsx
import { Box, Stack as Stack2, TextField as TextField3, Typography as Typography2 } from "@mui/material";
import { useFormikContext as useFormikContext2 } from "formik";
import {
  CommonInputLabelStack,
  FORM_PREVENT_CANCEL,
  FORM_PREVENT_SUBMIT_LOADING,
  SingleFileServerUpload,
  useAuth
} from "common-ui";
import { COMMON_IMAGE_MIME_TYPES } from "common-ui";
import { useApi } from "common-api";
import { useSnapshot } from "valtio";
import { useEffect } from "react";
import { jsx as jsx3, jsxs as jsxs3 } from "react/jsx-runtime";
var OptionalContent = () => {
  var _a;
  const { axios } = useApi();
  const { platform } = useAuth();
  const { activeStep } = useSnapshot(manager.wizardState);
  const fileUploadUri = axios ? (axios == null ? void 0 : axios.defaults.baseURL) + "/uploads" : "";
  const {
    values,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    errors,
    setFieldTouched,
    setFieldValue
  } = useFormikContext2();
  useEffect(() => {
    var _a2, _b;
    if ((_a2 = platform == null ? void 0 : platform.org) == null ? void 0 : _a2.id) {
      setFieldValue("organizationId", (_b = platform == null ? void 0 : platform.org) == null ? void 0 : _b.id, false);
    }
  }, [(_a = platform == null ? void 0 : platform.org) == null ? void 0 : _a.id]);
  return /* @__PURE__ */ jsxs3(Stack2, { spacing: 1, children: [
    /* @__PURE__ */ jsxs3(Box, { children: [
      /* @__PURE__ */ jsx3(CommonInputLabelStack, { label: "Profile picture and banner", isOptional: true, forCtrl: "custom" }),
      /* @__PURE__ */ jsxs3(
        Stack2,
        {
          sx: {
            position: "relative",
            width: "100%",
            marginBottom: "4em !important",
            marginTop: "5px !important"
          },
          children: [
            /* @__PURE__ */ jsx3(Box, { sx: { borderRadius: "5px", opacity: "95%" }, children: /* @__PURE__ */ jsx3(
              SingleFileServerUpload,
              {
                testId: "profile-banner-upload",
                height: "150px",
                width: "100%",
                maxFileSize: "2MB",
                disabled: isSubmitting || (activeStep == null ? void 0 : activeStep.readOnly),
                acceptedFileTypes: COMMON_IMAGE_MIME_TYPES,
                titleLabel: "Upload a profile banner",
                subtitleLabel: "",
                captionLabel: ``,
                uploadUri: fileUploadUri,
                uploadAuthHeader: axios == null ? void 0 : axios.defaults.headers.Authorization,
                onFileUploadedUrlChange: (organizationBannerUrl) => {
                  setFieldValue("bannerUrl", organizationBannerUrl, true);
                  setFieldTouched("bannerUrl", true, false);
                },
                onUploadingChange: (isUploading) => {
                  setFieldValue(FORM_PREVENT_SUBMIT_LOADING, isUploading, false);
                  setFieldValue(FORM_PREVENT_CANCEL, isUploading, false);
                }
              }
            ) }),
            /* @__PURE__ */ jsx3(
              Box,
              {
                sx: {
                  position: "absolute",
                  top: "35%",
                  left: "3%",
                  backgroundColor: (theme) => theme.palette.shades.white,
                  borderRadius: "5px"
                },
                children: /* @__PURE__ */ jsx3(
                  SingleFileServerUpload,
                  {
                    testId: "profile-avatar-upload",
                    height: "150px",
                    width: "150px",
                    maxFileSize: "2MB",
                    disabled: isSubmitting || (activeStep == null ? void 0 : activeStep.readOnly),
                    acceptedFileTypes: COMMON_IMAGE_MIME_TYPES,
                    titleLabel: "Upload avatar",
                    subtitleLabel: "",
                    captionLabel: ``,
                    uploadUri: fileUploadUri,
                    uploadAuthHeader: axios == null ? void 0 : axios.defaults.headers.Authorization,
                    onFileUploadedUrlChange: (organizationBannerUrl) => {
                      setFieldValue("avatarUrl", organizationBannerUrl, true);
                      setFieldTouched("avatarUrl", true, false);
                    },
                    onUploadingChange: (isUploading) => {
                      setFieldValue(FORM_PREVENT_SUBMIT_LOADING, isUploading, false);
                      setFieldValue(FORM_PREVENT_CANCEL, isUploading, false);
                    }
                  }
                )
              }
            )
          ]
        }
      )
    ] }),
    /* @__PURE__ */ jsx3(
      TextField3,
      {
        fullWidth: true,
        id: "displayName",
        name: "displayName",
        type: "text",
        "data-testid": "profile-setup-display-name",
        label: /* @__PURE__ */ jsx3(CommonInputLabelStack, { label: "Display Name", isOptional: true }),
        autoFocus: true,
        inputProps: {
          readOnly: activeStep == null ? void 0 : activeStep.readOnly
        },
        value: values["displayName"],
        onChange: handleChange,
        onBlur: handleBlur,
        error: touched["displayName"] && Boolean(errors["displayName"]),
        disabled: isSubmitting,
        helperText: touched["displayName"] && errors["displayName"]
      }
    ),
    /* @__PURE__ */ jsx3(
      TextField3,
      {
        fullWidth: true,
        id: "creatorBio",
        name: "creatorBio",
        type: "text",
        inputProps: {
          readOnly: activeStep == null ? void 0 : activeStep.readOnly
        },
        "data-testid": "profile-setup-bio",
        label: /* @__PURE__ */ jsx3(CommonInputLabelStack, { label: "Bio (64 character limit)", isOptional: true }),
        value: values["creatorBio"],
        onChange: handleChange,
        onBlur: handleBlur,
        error: touched["creatorBio"] && Boolean(errors["creatorBio"]),
        disabled: isSubmitting,
        helperText: touched["creatorBio"] && errors["creatorBio"]
      }
    ),
    /* @__PURE__ */ jsx3(
      TextField3,
      {
        fullWidth: true,
        id: "websiteLink",
        name: "websiteLink",
        type: "text",
        inputProps: {
          readOnly: activeStep == null ? void 0 : activeStep.readOnly
        },
        "data-testid": "profile-setup-website-link",
        label: /* @__PURE__ */ jsx3(CommonInputLabelStack, { label: "Website link", isOptional: true }),
        value: values["websiteLink"],
        onChange: handleChange,
        onBlur: handleBlur,
        error: touched["websiteLink"] && Boolean(errors["websiteLink"]),
        disabled: isSubmitting,
        helperText: touched["websiteLink"] && errors["websiteLink"]
      }
    ),
    /* @__PURE__ */ jsx3(
      TextField3,
      {
        fullWidth: true,
        name: "payoutAddress",
        label: /* @__PURE__ */ jsx3(
          CommonInputLabelStack,
          {
            label: "Payout Address",
            helpDescription: "This wallet address will receive the funds from collectible sales.",
            inputReadOnly: activeStep == null ? void 0 : activeStep.readOnly
          }
        ),
        "data-testid": "profile-setup-payout-address",
        type: "text",
        autoFocus: true,
        inputProps: {
          readOnly: activeStep == null ? void 0 : activeStep.readOnly
        },
        value: values.payoutAddress,
        onChange: handleChange,
        onBlur: () => {
          handleBlur("payoutAddress");
        },
        error: touched.payoutAddress && Boolean(errors.payoutAddress),
        disabled: isSubmitting,
        helperText: touched.payoutAddress && errors.payoutAddress ? errors.payoutAddress : ""
      }
    ),
    /* @__PURE__ */ jsx3(Typography2, { variant: "body1", sx: { my: 1 }, children: "Social Links" }),
    /* @__PURE__ */ jsx3(
      TextField3,
      {
        fullWidth: true,
        id: "instagramLink",
        name: "instagramLink",
        type: "text",
        inputProps: {
          readOnly: activeStep == null ? void 0 : activeStep.readOnly
        },
        "data-testid": "profile-setup-instagram-link",
        label: /* @__PURE__ */ jsx3(CommonInputLabelStack, { label: "Instagram", isOptional: true }),
        value: values["instagramLink"],
        onChange: handleChange,
        onBlur: handleBlur,
        error: touched["instagramLink"] && Boolean(errors["instagramLink"]),
        disabled: isSubmitting,
        helperText: touched["instagramLink"] && errors["instagramLink"]
      }
    ),
    /* @__PURE__ */ jsx3(
      TextField3,
      {
        fullWidth: true,
        id: "twitterLink",
        name: "twitterLink",
        type: "text",
        inputProps: {
          readOnly: activeStep == null ? void 0 : activeStep.readOnly
        },
        "data-testid": "profile-setup-twitter-link",
        label: /* @__PURE__ */ jsx3(CommonInputLabelStack, { label: "X", isOptional: true }),
        value: values["twitterLink"],
        onChange: handleChange,
        onBlur: handleBlur,
        error: touched["twitterLink"] && Boolean(errors["twitterLink"]),
        disabled: isSubmitting,
        helperText: touched["twitterLink"] && errors["twitterLink"]
      }
    ),
    /* @__PURE__ */ jsx3(
      TextField3,
      {
        fullWidth: true,
        id: "discordLink",
        name: "discordLink",
        type: "text",
        inputProps: {
          readOnly: activeStep == null ? void 0 : activeStep.readOnly
        },
        "data-testid": "profile-setup-discord-link",
        label: /* @__PURE__ */ jsx3(CommonInputLabelStack, { label: "Discord Invite", isOptional: true }),
        value: values["discordLink"],
        onChange: handleChange,
        onBlur: handleBlur,
        error: touched["discordLink"] && Boolean(errors["discordLink"]),
        disabled: isSubmitting,
        helperText: touched["discordLink"] && errors["discordLink"]
      }
    )
  ] });
};

// src/components/profile-setup/step-content/Complete.tsx
import { Stack as Stack3, Typography as Typography3 } from "@mui/material";
import { jsx as jsx4, jsxs as jsxs4 } from "react/jsx-runtime";
var CompleteContent = () => {
  return /* @__PURE__ */ jsxs4(Stack3, { spacing: 1, children: [
    /* @__PURE__ */ jsx4(Typography3, { children: "Your account setup is complete and you are ready to begin sharing your creations with the world." }),
    /* @__PURE__ */ jsx4(Typography3, { children: "Dive into Phosphor and explore its array of features to launch your first drop and engage with your audience." }),
    /* @__PURE__ */ jsx4(Typography3, { children: "Remember, you can always access your profile from the top right corner of the page." })
  ] });
};

// src/events.ts
var SettingsProfileOnboardNeededEvent = class {
  constructor() {
    this.id = "settings.profile-onboard.needed";
  }
};
var SettingsProfileOnboardStartEvent = class {
  constructor() {
    this.id = "settings.profile-onboard.start";
  }
};
var SettingsProfileOnboardCompletedEvent = class {
  constructor() {
    this.id = "settings.profile-onboard.completed";
  }
};

// src/components/profile-setup/CreatorProfileSetupDialog.tsx
import { Fragment as Fragment2, jsx as jsx5, jsxs as jsxs5 } from "react/jsx-runtime";
function CreatorProfileSetupDialog() {
  const isMobile = useMobileEnabled();
  return isMobile ? /* @__PURE__ */ jsx5(Fragment2, {}) : /* @__PURE__ */ jsx5(CreatorProfileSetupDialogInner, {});
}
function CreatorProfileSetupDialogInner() {
  var _a, _b, _c, _d;
  const { platform } = useAuth2();
  const { axios } = useApi2();
  const pluginRegistry = usePluginRegistry();
  const { onboardingMarketingUpdatesConsent: captureMarketingUpdatesConsent } = useFeatureFlags2();
  const [locationPath, setLocationPath] = useState2((_a = window.location) == null ? void 0 : _a.pathname);
  const [agreeToTerms, setAgreeToTerms] = useState2(false);
  const { activeStep, open } = useSnapshot2(manager.wizardState);
  const editOrgProfile = useEditOrgProfile((_b = platform == null ? void 0 : platform.org) == null ? void 0 : _b.id);
  const updateEmailNotificationSettings = useEditOrgNotificationSettings();
  const { mutateAsync: updatePayoutAddress } = useUpdatePayoutAddress();
  const {
    isError: orgProfileError,
    isPending: orgProfilePending,
    data: organizationProfile,
    isFetched: orgProfileFetched
  } = useOrgProfile((_c = platform == null ? void 0 : platform.org) == null ? void 0 : _c.id);
  useEffect2(() => {
    return pluginRegistry.events.subscribe("shell.router.state-change", (eventData) => {
      var _a2;
      setLocationPath((_a2 = eventData == null ? void 0 : eventData.location) == null ? void 0 : _a2.pathname);
    });
  }, [pluginRegistry]);
  useEffect2(() => {
    manager.wizardState.nextButtonEnabled = agreeToTerms;
  }, [agreeToTerms]);
  useEffect2(() => {
    if ((organizationProfile == null ? void 0 : organizationProfile.slug) && activeStep.stepType === "CREATOR_USERNAME" || !(locationPath == null ? void 0 : locationPath.includes("setup"))) {
      manager.wizardState.open = false;
    }
  }, [organizationProfile, activeStep, orgProfilePending, orgProfileFetched, locationPath]);
  useEffect2(() => {
    if (open && (activeStep == null ? void 0 : activeStep.stepType) === "CREATOR_USERNAME" && !(organizationProfile == null ? void 0 : organizationProfile.slug) && !orgProfilePending && orgProfileFetched && (locationPath == null ? void 0 : locationPath.includes("setup"))) {
      pluginRegistry.events.publish(new SettingsProfileOnboardStartEvent());
    } else if (!open && (organizationProfile == null ? void 0 : organizationProfile.slug) && (locationPath == null ? void 0 : locationPath.includes("setup"))) {
      pluginRegistry.events.publish(new SettingsProfileOnboardCompletedEvent());
    } else if (!open && !(organizationProfile == null ? void 0 : organizationProfile.slug) && !orgProfilePending && orgProfileFetched && !(locationPath == null ? void 0 : locationPath.includes("setup"))) {
      pluginRegistry.events.publish(new SettingsProfileOnboardNeededEvent());
    }
  }, [
    activeStep == null ? void 0 : activeStep.stepType,
    open,
    organizationProfile,
    orgProfilePending,
    orgProfileFetched,
    pluginRegistry.events,
    locationPath
  ]);
  const handleClose = useCallback(() => __async(this, null, function* () {
    if (activeStep.stepType === "CREATOR_USERNAME" && !(organizationProfile == null ? void 0 : organizationProfile.slug)) {
      pluginRegistry.events.publish(
        new NotificationEvent({
          message: "Please complete the profile setup to continue",
          options: {
            type: "info"
          }
        })
      );
      manager.wizardState.open = true;
    } else if (organizationProfile == null ? void 0 : organizationProfile.slug) {
      pluginRegistry.events.publish(new SettingsProfileOnboardCompletedEvent());
    }
  }), [activeStep.stepType, pluginRegistry, organizationProfile == null ? void 0 : organizationProfile.slug]);
  const nextStepActionButtonLabel = useMemo2(() => {
    if (activeStep && activeStep.stepType === "OPTIONAL_INFO") {
      return "Complete";
    } else if (activeStep && activeStep.stepType === "COMPLETE") {
      return "Explore Phosphor";
    } else
      return "Next";
  }, [activeStep, activeStep == null ? void 0 : activeStep.stepType]);
  const getStepContent = (stepType) => {
    return /* @__PURE__ */ jsxs5(Fragment2, { children: [
      stepType === "CREATOR_USERNAME" && /* @__PURE__ */ jsx5(UsernameContent, { agreeToTerms, setAgreeToTerms }),
      stepType === "OPTIONAL_INFO" && /* @__PURE__ */ jsx5(OptionalContent, {}),
      stepType === "COMPLETE" && /* @__PURE__ */ jsx5(CompleteContent, {})
    ] });
  };
  const getStepSubTitle = (stepType) => {
    return /* @__PURE__ */ jsxs5(Fragment2, { children: [
      stepType === "CREATOR_USERNAME" && /* @__PURE__ */ jsx5(Typography4, { variant: "body2", children: "Choose and claim your unique username that will represent your presence on Phosphor" }),
      stepType === "OPTIONAL_INFO" && /* @__PURE__ */ jsxs5(Stack4, { spacing: 1, children: [
        /* @__PURE__ */ jsx5(Typography4, { variant: "body2", children: "Add a personal touch to your Phosphor profile by sharing a few extra details about yourself." }),
        /* @__PURE__ */ jsx5(Typography4, { variant: "body2", children: "This is where your fans, both new and loyal, will connect with you for exclusive Airdrops, updates, and more. Make it a space that truly represents you!" })
      ] }),
      stepType === "COMPLETE" && /* @__PURE__ */ jsx5(Typography4, { variant: "body1", children: "Congratulations!" })
    ] });
  };
  if (activeStep.stepType === "CREATOR_USERNAME" && (organizationProfile == null ? void 0 : organizationProfile.slug) || orgProfilePending || orgProfileError || !((_d = platform == null ? void 0 : platform.org) == null ? void 0 : _d.id) || !(locationPath == null ? void 0 : locationPath.includes("setup"))) {
    return null;
  } else {
    return /* @__PURE__ */ jsx5(
      WizardSingleDialog,
      {
        title: /* @__PURE__ */ jsxs5(Stack4, { sx: { flex: 1, display: "flex", flexDirection: "column", rowGap: 1 }, children: [
          /* @__PURE__ */ jsx5(Typography4, { variant: "bodyXL", children: "Set up your account" }),
          getStepSubTitle(activeStep.stepType)
        ] }),
        minWidth: 650,
        commitBag: {
          editOrgProfile,
          updateEmailNotificationSettings,
          updatePayoutAddress,
          axios,
          captureMarketingUpdatesConsent
        },
        renderStepContent: getStepContent,
        stepActionOptions: {
          nextButtonLabel: nextStepActionButtonLabel,
          nextButtonDisabled: activeStep.stepType === "CREATOR_USERNAME" ? !agreeToTerms : false,
          commandId: ["COMPLETE"].includes(activeStep.stepType) ? "portal.profile-edit.open" : "",
          backButtonLabel: ["COMPLETE"].includes(activeStep.stepType) ? "View your Profile" : "Back",
          showBackButton: !["CREATOR_USERNAME"].includes(activeStep.stepType),
          backButtonSx: {
            "&.Mui-disabled": {
              background: "#eaeaea",
              color: "#c0c0c0"
            },
            color: "black ",
            border: "1px solid black "
          },
          nextButtonSx: { color: "black " }
        },
        manager,
        onClose: handleClose
      }
    );
  }
}

// src/plugins/profile-setup/Plugin.tsx
import { jsx as jsx6 } from "react/jsx-runtime";
var ProfileSetupPlugin = class extends Plugin {
  get info() {
    return {
      name: "profile-setup",
      title: "Profile Setup",
      disabledByDefault: false
    };
  }
  activate(registry) {
    return __async(this, null, function* () {
      registry.fillSlot("shell.content", () => /* @__PURE__ */ jsx6(CreatorProfileSetupDialog, {}));
    });
  }
};

// src/plugins/profile-edit/Plugin.tsx
import { Plugin as Plugin2 } from "plugin-system";
import { PORTAL_APP_BAR_USER_PROFILE_SLOT } from "common-ui";

// src/components/profile-edit/CreatorProfileEdit.tsx
import { useEffect as useEffect3, useState as useState3 } from "react";
import {
  useApi as useApi3,
  useEditOrgNotificationSettings as useEditOrgNotificationSettings2,
  useEditOrgProfile as useEditOrgProfile2,
  useGetOrgNotificationSettings as useGetOrgNotificationSettings2,
  useOrgProfile as useOrgProfile2,
  useUpdatePayoutAddress as useUpdatePayoutAddress2
} from "common-api";
import { NotificationEvent as NotificationEvent2, useAuth as useAuth4, useFeatureFlags as useFeatureFlags4, useMobileEnabled as useMobileEnabled2 } from "common-ui";
import { useQueryClient } from "@tanstack/react-query";
import { subscribe } from "valtio";
import { usePluginRegistry as usePluginRegistry2 } from "plugin-system";
import { ListItemButton, Typography as Typography6 } from "@mui/material";

// src/components/profile-edit/CreatorProfileEditForm.tsx
import {
  Dialog,
  DialogContent,
  Stack as Stack5,
  Typography as Typography5,
  IconButton,
  Box as Box2,
  TextField as TextField4,
  Button,
  DialogTitle,
  DialogActions,
  CircularProgress as CircularProgress2,
  InputAdornment as InputAdornment2,
  FormControlLabel as FormControlLabel2,
  Checkbox as Checkbox3
} from "@mui/material";
import { toFormikValidationSchema } from "zod-formik-adapter";
import CloseIcon from "@mui/icons-material/Close";
import {
  CommonInputLabelStack as CommonInputLabelStack2,
  SingleFileServerUpload as SingleFileServerUpload2,
  FORM_PREVENT_CANCEL as FORM_PREVENT_CANCEL2,
  LoadingButton,
  FORM_PREVENT_SUBMIT_LOADING as FORM_PREVENT_SUBMIT_LOADING2,
  FORM_PREVENT_SUBMIT as FORM_PREVENT_SUBMIT2,
  useFeatureFlags as useFeatureFlags3
} from "common-ui";
import { COMMON_IMAGE_MIME_TYPES as COMMON_IMAGE_MIME_TYPES2, useAuth as useAuth3 } from "common-ui";
import { Formik } from "formik";
import { useSnapshot as useSnapshot3 } from "valtio";
import {
  useCreatorProfilePageUri,
  useGetOrgNotificationSettings,
  useOrgPayoutConfig,
  useValidateOrgSlugAvailability as useValidateOrgSlugAvailability2
} from "common-api";

// src/state/profile-edit/index.ts
import { proxy as proxy5 } from "valtio";
import { isAddress as isAddress2 } from "ethers";
import * as z4 from "zod";
var validationSchema4 = z4.object({
  bannerUrl: z4.string().min(1).optional(),
  avatarUrl: z4.string().min(1).optional(),
  displayName: z4.string().min(1).max(32).optional(),
  creatorSlug: z4.string({ required_error: "Username is required." }).min(1).max(32).regex(
    /^[a-zA-Z0-9._-]+$/,
    "Username can only contain letters, numbers, underscores, periods and hyphens."
  ),
  creatorBio: z4.string().min(1).max(140).optional(),
  oldWebsiteLink: z4.string().optional(),
  websiteLink: z4.string().url().optional(),
  instagramLink: z4.string().min(1).refine((value) => /^.*instagram\.com\/[\w.]+$/i.test(value), {
    message: "Please enter a valid Instagram profile link."
  }).optional(),
  twitterLink: z4.string().min(1).refine((value) => /^.*(twitter|x)\.com\/\w+$/i.test(value), {
    message: "Please enter a valid X profile link."
  }).optional(),
  discordLink: z4.string().min(1).refine((value) => /^.*discord\.(gg|com)\/.+$/i.test(value), {
    message: "Please enter a valid Discord invite link."
  }).optional(),
  creatorRoles: z4.array(
    z4.enum([
      EditOrganizationProfileInputCreatorRolesEnum.Artist,
      EditOrganizationProfileInputCreatorRolesEnum.CommunityManager,
      EditOrganizationProfileInputCreatorRolesEnum.ContentCreator,
      EditOrganizationProfileInputCreatorRolesEnum.ContentCreator,
      EditOrganizationProfileInputCreatorRolesEnum.EventOrganizer,
      EditOrganizationProfileInputCreatorRolesEnum.NoneApply
    ])
  ).optional(),
  oldPayoutAddress: z4.string().max(42).nullish(),
  marketingUpdatesConsent: z4.boolean().optional(),
  payoutAddress: z4.string().max(42).nullish().refine((val) => !val || isAddress2(val), {
    message: "Please enter a valid payout address."
  })
}).superRefine((val, ctx) => {
  if (!val.websiteLink && val.oldWebsiteLink) {
    ctx.addIssue({
      code: z4.ZodIssueCode.custom,
      message: "Website link cannot be cleared once set.",
      path: ["websiteLink"]
    });
  }
  if (!val.payoutAddress && val.oldPayoutAddress) {
    ctx.addIssue({
      code: z4.ZodIssueCode.custom,
      message: "Payout address cannot be cleared once set.",
      path: ["payoutAddress"]
    });
  }
});
var defaultState4 = {
  open: false,
  displayName: "",
  bannerUrl: "",
  avatarUrl: "",
  creatorBio: "",
  creatorSlug: "",
  websiteLink: "",
  instagramLink: "",
  twitterLink: "",
  discordLink: "",
  marketingUpdatesConsent: false
};
var setOrganizationProfileData = (data) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m;
  state5.displayName = (_a = data == null ? void 0 : data.data) == null ? void 0 : _a.public_name;
  state5.bannerUrl = (_c = (_b = data == null ? void 0 : data.data) == null ? void 0 : _b.media) == null ? void 0 : _c.banner;
  state5.avatarUrl = (_e = (_d = data == null ? void 0 : data.data) == null ? void 0 : _d.media) == null ? void 0 : _e.avatar;
  state5.creatorBio = (_f = data == null ? void 0 : data.data) == null ? void 0 : _f.description;
  state5.creatorSlug = (_g = data == null ? void 0 : data.data) == null ? void 0 : _g.slug;
  state5.instagramLink = (_i = (_h = data == null ? void 0 : data.data) == null ? void 0 : _h.socials) == null ? void 0 : _i.instagram;
  state5.twitterLink = (_k = (_j = data == null ? void 0 : data.data) == null ? void 0 : _j.socials) == null ? void 0 : _k.twitter;
  state5.discordLink = (_m = (_l = data == null ? void 0 : data.data) == null ? void 0 : _l.socials) == null ? void 0 : _m.discord;
};
var state5 = proxy5(defaultState4);
function openProfileEditModal() {
  state5.open = true;
}
function closeProfileEditModal() {
  state5.open = false;
}
var profile_edit_default = state5;

// src/components/profile-edit/CreatorProfileEditForm.tsx
import { Fragment as Fragment3, jsx as jsx7, jsxs as jsxs6 } from "react/jsx-runtime";
var CreatorProfileEditForm = ({
  open,
  handleClose,
  handleSubmit,
  organizationProfile,
  axios
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h;
  const { platform } = useAuth3();
  const { data: payoutConfig, isPending: payoutConfigPending } = useOrgPayoutConfig(
    open ? (_a = platform == null ? void 0 : platform.org) == null ? void 0 : _a.id : void 0
  );
  const { onboardingMarketingUpdatesConsent } = useFeatureFlags3();
  const { data: notificationSettings } = useGetOrgNotificationSettings();
  const { uri: creatorProfilePageUri, isPending: creatorProfilePageUriPending } = useCreatorProfilePageUri(open ? (_b = platform == null ? void 0 : platform.org) == null ? void 0 : _b.id : void 0);
  const defaultState5 = useSnapshot3(profile_edit_default);
  const fileUploadUri = axios ? (axios == null ? void 0 : axios.defaults.baseURL) + "/uploads" : "";
  const handleOpenNewWindow = () => {
    window.open(creatorProfilePageUri, "_blank", "noopener,noreferrer");
  };
  const { mutateAsync: validateSlugAvailability } = useValidateOrgSlugAvailability2();
  if (creatorProfilePageUriPending || payoutConfigPending) {
    return /* @__PURE__ */ jsx7(Fragment3, {});
  }
  return /* @__PURE__ */ jsx7(
    Formik,
    {
      enableReinitialize: true,
      validateOnBlur: false,
      initialValues: {
        bannerUrl: defaultState5.bannerUrl || ((_c = organizationProfile == null ? void 0 : organizationProfile.media) == null ? void 0 : _c.banner) || "",
        avatarUrl: defaultState5.avatarUrl || ((_d = organizationProfile == null ? void 0 : organizationProfile.media) == null ? void 0 : _d.avatar) || "",
        displayName: defaultState5.displayName || (organizationProfile == null ? void 0 : organizationProfile.public_name) || "",
        creatorBio: defaultState5.creatorBio || (organizationProfile == null ? void 0 : organizationProfile.description) || "",
        creatorSlug: defaultState5.creatorSlug || (organizationProfile == null ? void 0 : organizationProfile.slug) || "",
        oldWebsiteLink: defaultState5.websiteLink || (organizationProfile == null ? void 0 : organizationProfile.website) || "",
        websiteLink: defaultState5.websiteLink || (organizationProfile == null ? void 0 : organizationProfile.website) || "",
        instagramLink: defaultState5.instagramLink || ((_e = organizationProfile == null ? void 0 : organizationProfile.socials) == null ? void 0 : _e.instagram) || "",
        twitterLink: defaultState5.twitterLink || ((_f = organizationProfile == null ? void 0 : organizationProfile.socials) == null ? void 0 : _f.twitter) || "",
        discordLink: defaultState5.discordLink || ((_g = organizationProfile == null ? void 0 : organizationProfile.socials) == null ? void 0 : _g.discord) || "",
        creatorRoles: defaultState5.creatorRoles || (organizationProfile == null ? void 0 : organizationProfile.creator_roles) || [],
        organizationId: ((_h = platform == null ? void 0 : platform.org) == null ? void 0 : _h.id) || "",
        payoutAddress: (defaultState5 == null ? void 0 : defaultState5.payoutAddress) || (payoutConfig == null ? void 0 : payoutConfig.payout_address) || "",
        oldPayoutAddress: (defaultState5 == null ? void 0 : defaultState5.oldPayoutAddress) || (payoutConfig == null ? void 0 : payoutConfig.payout_address) || "",
        marketingUpdatesConsent: (notificationSettings == null ? void 0 : notificationSettings.marketing_updates_consent) || false,
        preventCancel: false,
        preventSubmit: false,
        preventSubmitLoading: false
      },
      validationSchema: toFormikValidationSchema(validationSchema4),
      onSubmit: (_values, actions) => {
        actions.submitForm();
      },
      children: (props) => /* @__PURE__ */ jsx7("form", { children: /* @__PURE__ */ jsxs6(
        Dialog,
        {
          sx: {
            ".MuiDialog-paper": { minHeight: 650, minWidth: 650 }
          },
          open,
          onClose: handleClose,
          children: [
            /* @__PURE__ */ jsxs6(DialogTitle, { sx: { justifyContent: "space-between" }, children: [
              /* @__PURE__ */ jsxs6(Stack5, { sx: { flex: 1, display: "flex", flexDirection: "column", rowGap: 1 }, children: [
                /* @__PURE__ */ jsx7(Typography5, { variant: "h4", children: "Update Phosphor Studio Profile" }),
                /* @__PURE__ */ jsx7(Typography5, { variant: "body1", children: "Update your profile with a few details about yourself." })
              ] }),
              /* @__PURE__ */ jsx7(IconButton, { "aria-label": "close", onClick: handleClose, size: "large", children: /* @__PURE__ */ jsx7(CloseIcon, { fontSize: "small" }) })
            ] }),
            /* @__PURE__ */ jsx7(
              DialogContent,
              {
                sx: {
                  display: "flex",
                  flexDirection: "column"
                },
                children: /* @__PURE__ */ jsxs6(
                  Stack5,
                  {
                    sx: {
                      flex: 2,
                      display: "flex",
                      flexDirection: "column",
                      rowGap: 1,
                      backgroundColor: "white",
                      color: "black"
                    },
                    children: [
                      /* @__PURE__ */ jsxs6(Box2, { children: [
                        /* @__PURE__ */ jsx7(
                          CommonInputLabelStack2,
                          {
                            label: "Profile picture and banner",
                            isOptional: true,
                            forCtrl: "custom"
                          }
                        ),
                        /* @__PURE__ */ jsxs6(
                          Stack5,
                          {
                            sx: {
                              position: "relative",
                              width: "100%",
                              marginBottom: "4em !important",
                              marginTop: "5px !important"
                            },
                            children: [
                              /* @__PURE__ */ jsx7(
                                Box2,
                                {
                                  sx: {
                                    backgroundColor: (theme) => theme.palette.shades.white,
                                    borderRadius: "5px",
                                    opacity: "95%"
                                  },
                                  children: /* @__PURE__ */ jsx7(
                                    SingleFileServerUpload2,
                                    {
                                      testId: "profile-banner-upload",
                                      height: "150px",
                                      width: "100%",
                                      maxFileSize: "2MB",
                                      initialValue: props.values.bannerUrl,
                                      disabled: props.isSubmitting,
                                      acceptedFileTypes: COMMON_IMAGE_MIME_TYPES2,
                                      titleLabel: "Upload a profile banner",
                                      subtitleLabel: "",
                                      captionLabel: ``,
                                      uploadUri: fileUploadUri,
                                      uploadAuthHeader: axios == null ? void 0 : axios.defaults.headers.Authorization,
                                      onFileUploadedUrlChange: (organizationBannerUrl) => {
                                        props.setFieldValue("bannerUrl", organizationBannerUrl, true);
                                        props.setFieldTouched("bannerUrl", true, false);
                                      },
                                      onUploadingChange: (isUploading) => {
                                        props.setFieldValue(FORM_PREVENT_SUBMIT_LOADING2, isUploading, false);
                                        props.setFieldValue(FORM_PREVENT_CANCEL2, isUploading, false);
                                      }
                                    }
                                  )
                                }
                              ),
                              /* @__PURE__ */ jsx7(
                                Box2,
                                {
                                  sx: {
                                    position: "absolute",
                                    top: "35%",
                                    left: "3%",
                                    backgroundColor: (theme) => theme.palette.shades.white,
                                    borderRadius: "5px"
                                  },
                                  children: /* @__PURE__ */ jsx7(
                                    SingleFileServerUpload2,
                                    {
                                      testId: "profile-avatar-upload",
                                      height: "150px",
                                      width: "150px",
                                      maxFileSize: "2MB",
                                      initialValue: props.values.avatarUrl,
                                      disabled: props.isSubmitting,
                                      acceptedFileTypes: COMMON_IMAGE_MIME_TYPES2,
                                      titleLabel: "Upload avatar",
                                      subtitleLabel: "",
                                      captionLabel: ``,
                                      uploadUri: fileUploadUri,
                                      uploadAuthHeader: axios == null ? void 0 : axios.defaults.headers.Authorization,
                                      onFileUploadedUrlChange: (organizationBannerUrl) => {
                                        props.setFieldValue("avatarUrl", organizationBannerUrl, true);
                                        props.setFieldTouched("avatarUrl", true, false);
                                      },
                                      onUploadingChange: (isUploading) => {
                                        props.setFieldValue(FORM_PREVENT_SUBMIT_LOADING2, isUploading, false);
                                        props.setFieldValue(FORM_PREVENT_CANCEL2, isUploading, false);
                                      }
                                    }
                                  )
                                }
                              )
                            ]
                          }
                        )
                      ] }),
                      /* @__PURE__ */ jsx7(
                        TextField4,
                        {
                          fullWidth: true,
                          id: "displayName",
                          name: "displayName",
                          type: "text",
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-display-name",
                          label: /* @__PURE__ */ jsx7(CommonInputLabelStack2, { label: "Display Name", isOptional: true }),
                          autoFocus: true,
                          value: props.values.displayName,
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          error: props.touched["displayName"] && Boolean(props.errors["displayName"]),
                          disabled: props.isSubmitting,
                          helperText: props.touched["displayName"] && props.errors["displayName"]
                        }
                      ),
                      /* @__PURE__ */ jsx7(
                        TextField4,
                        {
                          fullWidth: true,
                          id: "creatorSlug",
                          name: "creatorSlug",
                          type: "text",
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-creator-slug",
                          label: /* @__PURE__ */ jsx7(CommonInputLabelStack2, { label: "Username" }),
                          autoFocus: true,
                          value: props.values.creatorSlug,
                          onChange: (e) => __async(void 0, null, function* () {
                            props.handleChange(e);
                            props.setFieldValue(FORM_PREVENT_SUBMIT2, true, false);
                            const data = yield validateSlugAvailability(e.target.value);
                            if (data && !data.isAvailable) {
                              props.setFieldError("creatorSlug", "The username is not available for use.");
                            }
                            props.setFieldValue(FORM_PREVENT_SUBMIT2, false, false);
                          }),
                          onBlur: props.handleBlur,
                          error: props.touched["creatorSlug"] && Boolean(props.errors["creatorSlug"]),
                          disabled: props.isSubmitting,
                          InputProps: props.values[FORM_PREVENT_SUBMIT2] && !props.errors["creatorSlug"] ? {
                            endAdornment: /* @__PURE__ */ jsx7(InputAdornment2, { position: "end", children: /* @__PURE__ */ jsx7(CircularProgress2, { size: 18 }) })
                          } : /* @__PURE__ */ jsx7(Fragment3, {}),
                          helperText: props.touched["creatorSlug"] && /* @__PURE__ */ jsx7(Fragment3, { children: props.errors["creatorSlug"] ? props.errors["creatorSlug"] : /* @__PURE__ */ jsx7(Fragment3, { children: "Once you changed the username, all your previous profile links will cease to work. This might take 1-2 min to take effect." }) })
                        }
                      ),
                      /* @__PURE__ */ jsx7(
                        TextField4,
                        {
                          fullWidth: true,
                          id: "creatorBio",
                          name: "creatorBio",
                          type: "text",
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-bio",
                          label: /* @__PURE__ */ jsx7(CommonInputLabelStack2, { label: "Bio", isOptional: true }),
                          value: props.values.creatorBio,
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          error: props.touched["creatorBio"] && Boolean(props.errors["creatorBio"]),
                          disabled: props.isSubmitting,
                          helperText: props.touched["creatorBio"] && props.errors["creatorBio"]
                        }
                      ),
                      /* @__PURE__ */ jsx7(
                        TextField4,
                        {
                          fullWidth: true,
                          id: "websiteLink",
                          name: "websiteLink",
                          type: "text",
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-website-link",
                          label: /* @__PURE__ */ jsx7(CommonInputLabelStack2, { label: "Website link", isOptional: true }),
                          value: props.values["websiteLink"],
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          error: props.touched["websiteLink"] && Boolean(props.errors["websiteLink"]),
                          disabled: props.isSubmitting,
                          helperText: props.touched["websiteLink"] && props.errors["websiteLink"]
                        }
                      ),
                      /* @__PURE__ */ jsx7(
                        TextField4,
                        {
                          fullWidth: true,
                          name: "payoutAddress",
                          label: /* @__PURE__ */ jsx7(
                            CommonInputLabelStack2,
                            {
                              label: "Payout Address",
                              helpDescription: "This wallet address will receive the funds from collectible sales.",
                              inputReadOnly: props.isSubmitting
                            }
                          ),
                          type: "text",
                          autoFocus: true,
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-payout-address",
                          value: props.values.payoutAddress,
                          onChange: props.handleChange,
                          onBlur: () => {
                            props.handleBlur("payoutAddress");
                          },
                          error: Boolean(props.errors.payoutAddress),
                          disabled: props.isSubmitting,
                          helperText: props.errors.payoutAddress ? props.errors.payoutAddress : ""
                        }
                      ),
                      /* @__PURE__ */ jsx7(Typography5, { variant: "body1", sx: { my: 1 }, children: "Social Links" }),
                      /* @__PURE__ */ jsx7(
                        TextField4,
                        {
                          fullWidth: true,
                          id: "instagramLink",
                          name: "instagramLink",
                          type: "text",
                          "data-testid": "profile-edit-instagram-link",
                          label: /* @__PURE__ */ jsx7(CommonInputLabelStack2, { label: "Instagram", isOptional: true }),
                          value: props.values["instagramLink"],
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          error: props.touched["instagramLink"] && Boolean(props.errors["instagramLink"]),
                          disabled: props.isSubmitting,
                          helperText: props.touched["instagramLink"] && props.errors["instagramLink"]
                        }
                      ),
                      /* @__PURE__ */ jsx7(
                        TextField4,
                        {
                          fullWidth: true,
                          id: "twitterLink",
                          name: "twitterLink",
                          type: "text",
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-twitter-link",
                          label: /* @__PURE__ */ jsx7(CommonInputLabelStack2, { label: "X", isOptional: true }),
                          value: props.values["twitterLink"],
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          error: props.touched["twitterLink"] && Boolean(props.errors["twitterLink"]),
                          disabled: props.isSubmitting,
                          helperText: props.touched["twitterLink"] && props.errors["twitterLink"]
                        }
                      ),
                      /* @__PURE__ */ jsx7(
                        TextField4,
                        {
                          fullWidth: true,
                          id: "discordLink",
                          name: "discordLink",
                          type: "text",
                          inputProps: {
                            readOnly: props.isSubmitting
                          },
                          "data-testid": "profile-edit-discord-link",
                          label: /* @__PURE__ */ jsx7(CommonInputLabelStack2, { label: "Discord Invite", isOptional: true }),
                          value: props.values["discordLink"],
                          onChange: props.handleChange,
                          onBlur: props.handleBlur,
                          error: props.touched["discordLink"] && Boolean(props.errors["discordLink"]),
                          disabled: props.isSubmitting,
                          helperText: props.touched["discordLink"] && props.errors["discordLink"]
                        }
                      ),
                      /* @__PURE__ */ jsx7(
                        CreatorRoleAutocomplete,
                        {
                          initialValues: props.values.creatorRoles,
                          onChange: (values) => {
                            props.setFieldValue("creatorRoles", values != null ? values : []);
                          }
                        }
                      ),
                      onboardingMarketingUpdatesConsent && /* @__PURE__ */ jsx7(
                        FormControlLabel2,
                        {
                          control: /* @__PURE__ */ jsx7(
                            Checkbox3,
                            {
                              checked: props.values.marketingUpdatesConsent,
                              onChange: (e) => props.setFieldValue("marketingUpdatesConsent", e.target.checked),
                              "data-testid": "notification-settings-checkbox"
                            }
                          ),
                          label: /* @__PURE__ */ jsx7(Typography5, { variant: "body2", children: "Keep me informed with updates and special announcements from Phosphor" })
                        }
                      )
                    ]
                  }
                )
              }
            ),
            /* @__PURE__ */ jsxs6(DialogActions, { children: [
              /* @__PURE__ */ jsx7(
                Button,
                {
                  "data-testid": "back",
                  variant: "outlined",
                  sx: {
                    "&.Mui-disabled": {
                      background: "#eaeaea",
                      color: "#c0c0c0"
                    },
                    color: "black ",
                    border: "1px solid black "
                  },
                  onClick: handleOpenNewWindow,
                  children: "View Profile"
                }
              ),
              /* @__PURE__ */ jsx7(
                LoadingButton,
                {
                  "data-testid": "next",
                  variant: "contained",
                  type: "submit",
                  sx: {
                    "&.Mui-disabled": {
                      background: "#eaeaea",
                      color: "#c0c0c0"
                    },
                    color: "black"
                  },
                  loading: props.isSubmitting || props.values[FORM_PREVENT_SUBMIT_LOADING2],
                  title: props.values[FORM_PREVENT_SUBMIT_LOADING2] ? "This button is disabled because there is an action taking place on the form that has yet to complete." : void 0,
                  loadingText: props.isSubmitting ? "Saving..." : "Waiting...",
                  disabled: props.isSubmitting || props.values[FORM_PREVENT_SUBMIT2] === true || props.values[FORM_PREVENT_SUBMIT_LOADING2] === true || !props.isValid,
                  onClick: () => handleSubmit == null ? void 0 : handleSubmit(props.values),
                  children: "Update"
                }
              )
            ] })
          ]
        }
      ) })
    }
  );
};

// src/assets/icons/updateProfile.svg
import * as React from "react";
import { jsx as jsx8 } from "react/jsx-runtime";
var SvgUpdateProfile = (props) => /* @__PURE__ */ jsx8("svg", __spreadProps(__spreadValues({ width: 18, height: 18, viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props), { children: /* @__PURE__ */ jsx8("path", { d: "M13.0514 2.73889L14.4576 1.33265C15.0678 0.722451 16.0572 0.722451 16.6674 1.33265C17.2775 1.94284 17.2775 2.93216 16.6674 3.54235L4.69349 15.5162C4.25292 15.9568 3.70953 16.2806 3.11241 16.4585L0.875 17.125L1.54148 14.8876C1.71936 14.2905 2.04321 13.7471 2.48377 13.3065L13.0514 2.73889ZM13.0514 2.73889L15.25 4.93749", stroke: "#27272A", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }) }));
var updateProfile_default = SvgUpdateProfile;

// src/components/profile-edit/CreatorProfileEdit.tsx
import { Fragment as Fragment4, jsx as jsx9, jsxs as jsxs7 } from "react/jsx-runtime";
function CreatorProfileEdit() {
  const isMobile = useMobileEnabled2();
  const pluginRegistry = usePluginRegistry2();
  const { platform } = useAuth4();
  const {
    mutateAsync: updatePayoutAddress,
    isError: updatePayoutAddressError,
    reset: resetUpdatePayoutAddress
  } = useUpdatePayoutAddress2();
  const [open, setOpen] = useState3(false);
  const queryClient = useQueryClient();
  const { axios } = useApi3();
  const organizationId = platform == null ? void 0 : platform.org.id;
  const {
    mutateAsync: saveOrgProfile,
    isSuccess: savedOrgProfile,
    reset: resetSaveOrgProfile,
    isError: saveOrgProfileError
  } = useEditOrgProfile2(organizationId);
  const { data: organizationProfile, isPending } = useOrgProfile2(organizationId);
  const { onboardingMarketingUpdatesConsent } = useFeatureFlags4();
  const { data: notificationSettings, isSuccess } = useGetOrgNotificationSettings2();
  const { mutateAsync: updateEmailNotificationSettings } = useEditOrgNotificationSettings2();
  useEffect3(() => {
    if (!savedOrgProfile)
      return;
    pluginRegistry.events.publish(
      new NotificationEvent2({
        message: "Profile updated successfully",
        options: {
          type: "success",
          toastId: "profile-update-success"
        }
      })
    );
    if (resetSaveOrgProfile)
      resetSaveOrgProfile();
  }, [savedOrgProfile, pluginRegistry, resetSaveOrgProfile]);
  useEffect3(() => {
    if (!saveOrgProfileError && !updatePayoutAddressError)
      return;
    pluginRegistry.events.publish(
      new NotificationEvent2({
        message: "Profile update failed, please try again. If this problem persists, please contact support.",
        contactSupport: true,
        options: {
          type: "error",
          toastId: "profile-update-failed"
        }
      })
    );
    if (resetSaveOrgProfile && saveOrgProfileError)
      resetSaveOrgProfile();
    if (resetUpdatePayoutAddress && updatePayoutAddressError)
      resetUpdatePayoutAddress();
  }, [
    saveOrgProfileError,
    updatePayoutAddressError,
    pluginRegistry,
    resetSaveOrgProfile,
    resetUpdatePayoutAddress
  ]);
  const handleOpen = () => {
    queryClient.invalidateQueries({ queryKey: ["ORGANIZATION_PROFILE", organizationId] });
    openProfileEditModal();
  };
  const handleClose = () => {
    closeProfileEditModal();
  };
  useEffect3(() => {
    subscribe(profile_edit_default, () => {
      if (profile_edit_default.open) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    });
  }, []);
  const handleSubmit = (values) => __async(this, null, function* () {
    var _a;
    try {
      if (organizationId && values.payoutAddress !== values.oldPayoutAddress) {
        yield updatePayoutAddress({
          organizationId,
          payoutAddress: values.payoutAddress
        });
      }
      const saveOrgProfileVariables = {
        description: values.creatorBio || null,
        public_name: values.displayName || null,
        slug: values.creatorSlug,
        socials: {
          instagram: values.instagramLink || null,
          twitter: values.twitterLink || null,
          discord: values.discordLink || null
        },
        media: {
          banner: values.bannerUrl || null,
          avatar: values.avatarUrl || null
        },
        creator_roles: !((_a = values.creatorRoles) == null ? void 0 : _a.length) ? [OrganizationProfileOutputCreatorRolesEnum.NoneApply] : values.creatorRoles
      };
      if (values.websiteLink) {
        saveOrgProfileVariables.website = values.websiteLink;
      }
      if (onboardingMarketingUpdatesConsent && isSuccess && values.marketingUpdatesConsent !== notificationSettings.marketing_updates_consent) {
        yield updateEmailNotificationSettings({
          marketing_updates_consent: values.marketingUpdatesConsent
        });
      }
      yield saveOrgProfile(saveOrgProfileVariables);
      handleClose();
    } catch (e) {
    }
  });
  if (isMobile)
    return /* @__PURE__ */ jsx9(Fragment4, {});
  return /* @__PURE__ */ jsxs7(Fragment4, { children: [
    /* @__PURE__ */ jsxs7(
      ListItemButton,
      {
        sx: {
          display: "flex",
          flexDirection: "row"
        },
        onClick: handleOpen,
        children: [
          /* @__PURE__ */ jsx9(updateProfile_default, {}),
          /* @__PURE__ */ jsx9(Typography6, { fontWeight: "600", marginLeft: "12px", children: isPending ? "Getting Profile ..." : "Update Profile" })
        ]
      }
    ),
    !isPending && /* @__PURE__ */ jsx9(
      CreatorProfileEditForm,
      {
        open,
        handleClose,
        handleSubmit,
        organizationProfile,
        axios
      }
    )
  ] });
}

// src/components/AccountDetails.tsx
import { useCallback as useCallback2, useState as useState5 } from "react";
import { AuthClearEvent, useAuth as useAuth5, useFeatureFlags as useFeatureFlags5 } from "common-ui";
import {
  useAllPublicNetworks,
  useApi as useApi4,
  useEditOrgNotificationSettings as useEditOrgNotificationSettings3,
  useEditOrgProfile as useEditOrgProfile3,
  useGetOrgNotificationSettings as useGetOrgNotificationSettings3,
  useOrgPayoutConfig as useOrgPayoutConfig2,
  useOrgProfile as useOrgProfile3,
  useUpdatePayoutAddress as useUpdatePayoutAddress3,
  useRequestProfileClosure
} from "common-api";
import CloseIcon3 from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import {
  Button as Button3,
  Dialog as Dialog3,
  DialogActions as DialogActions3,
  DialogContent as DialogContent3,
  DialogTitle as DialogTitle2,
  IconButton as IconButton3,
  ListItemButton as ListItemButton2,
  Stack as Stack7,
  Tooltip,
  Typography as Typography8
} from "@mui/material";
import { usePluginRegistry as usePluginRegistry3 } from "plugin-system";

// src/components/profile-delete/CreatorProfileDeleteForm.tsx
import { useState as useState4 } from "react";
import Dialog2 from "@mui/material/Dialog";
import { Box as Box3, Button as Button2, DialogActions as DialogActions2, DialogContent as DialogContent2, FormGroup, IconButton as IconButton2, Stack as Stack6, Typography as Typography7 } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CloseIcon2 from "@mui/icons-material/Close";
import FormControlLabel3 from "@mui/material/FormControlLabel";
import Checkbox4 from "@mui/material/Checkbox";
import { jsx as jsx10, jsxs as jsxs8 } from "react/jsx-runtime";
var CreatorProfileDeleteForm = ({
  open,
  handleClose,
  handleDelete
}) => {
  const [deleteEnabled, setDeleteEnabled] = useState4(false);
  const handleAcknowledgmentClicked = () => {
    if (!deleteEnabled) {
      setDeleteEnabled(true);
    } else {
      setDeleteEnabled(false);
    }
  };
  return /* @__PURE__ */ jsx10(
    Dialog2,
    {
      sx: {
        ".MuiDialog-paper": { minHeight: 490, minWidth: 700 }
      },
      open,
      onClose: handleClose,
      children: /* @__PURE__ */ jsx10(Stack6, { children: /* @__PURE__ */ jsxs8(DialogContent2, { dividers: false, children: [
        /* @__PURE__ */ jsxs8(Box3, { sx: { display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 2 }, children: [
          /* @__PURE__ */ jsx10(IconButton2, { color: "error", "aria-label": "error", size: "large", style: { background: "#feebea" }, children: /* @__PURE__ */ jsx10(ErrorOutlineOutlinedIcon, { fontSize: "small" }) }),
          /* @__PURE__ */ jsx10(IconButton2, { "aria-label": "close", onClick: handleClose, size: "large", children: /* @__PURE__ */ jsx10(CloseIcon2, { fontSize: "small" }) })
        ] }),
        /* @__PURE__ */ jsx10(Typography7, { variant: "bodyL", children: "Deleting your profile is permanent and cannot be undone." }),
        /* @__PURE__ */ jsx10(Typography7, { variant: "body1", children: "By choosing to delete your profile, you acknowledge that:" }),
        /* @__PURE__ */ jsx10(Box3, { children: /* @__PURE__ */ jsx10(Typography7, { variant: "body1", children: /* @__PURE__ */ jsxs8("ul", { children: [
          /* @__PURE__ */ jsx10("li", { children: "All active and planned Drops will be ended" }),
          /* @__PURE__ */ jsx10("li", { children: "Your Drops and Collectibles will no longer be visible on Phosphor - blockchain records of your Collectibles will persist" }),
          /* @__PURE__ */ jsx10("li", { children: "Your login credentials will be disabled and deleted" }),
          /* @__PURE__ */ jsx10("li", { children: "Your username will be made available for future users" }),
          /* @__PURE__ */ jsx10("li", { children: "Your profile content will be removed" }),
          /* @__PURE__ */ jsx10("li", { children: "You will be removed from Phosphor-related marketing lists (if present)" }),
          /* @__PURE__ */ jsx10("li", { children: "Audience Lists, Snapshots and Follower Lists will be deleted" }),
          /* @__PURE__ */ jsx10("li", { children: "Your avatar, display name and user handle will no longer be present anywhere on the Phosphor platform" })
        ] }) }) }),
        /* @__PURE__ */ jsxs8(Stack6, { direction: "row", children: [
          /* @__PURE__ */ jsx10(FormGroup, { children: /* @__PURE__ */ jsx10(FormControlLabel3, { control: /* @__PURE__ */ jsx10(Checkbox4, { onChange: handleAcknowledgmentClicked }), label: "" }) }),
          /* @__PURE__ */ jsx10(Typography7, { variant: "bodyL", children: "I have read the above and I understand that deleting my profile is permanent and cannot be undone." })
        ] }),
        /* @__PURE__ */ jsxs8(DialogActions2, { sx: { justifyContent: "end", margin: 0 }, children: [
          /* @__PURE__ */ jsx10(
            Button2,
            {
              onClick: handleDelete,
              color: "error",
              variant: "outlined",
              disabled: !deleteEnabled,
              sx: {
                "&:hover": {
                  color: "white !important",
                  background: "#ff0000"
                }
              },
              children: "Permanantly Delete My Profile"
            }
          ),
          /* @__PURE__ */ jsx10(
            Button2,
            {
              "data-testid": "next",
              variant: "contained",
              sx: {
                "&.Mui-disabled": {
                  background: "#eaeaea",
                  color: "#c0c0c0"
                },
                color: (theme) => `${theme.palette.primary.main} !important`,
                "&:hover": {
                  color: "white !important"
                }
              },
              onClick: handleClose,
              children: "Keep My Profile Open"
            }
          )
        ] })
      ] }) })
    }
  );
};

// src/assets/icons/accountDetails.svg
import * as React2 from "react";
import { jsx as jsx11, jsxs as jsxs9 } from "react/jsx-runtime";
var SvgAccountDetails = (props) => /* @__PURE__ */ jsxs9("svg", __spreadProps(__spreadValues({ width: 18, height: 13, viewBox: "0 0 18 13", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props), { children: [
  /* @__PURE__ */ jsx11("path", { d: "M0.875 1.9375C0.875 1.41973 1.29473 1 1.8125 1H6.8125C7.33027 1 7.75 1.41973 7.75 1.9375V5.0625C7.75 5.58027 7.33027 6 6.8125 6H1.8125C1.29473 6 0.875 5.58027 0.875 5.0625V1.9375Z", stroke: "#27272A", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx11("path", { d: "M10.875 3.1875C10.875 2.66973 11.2947 2.25 11.8125 2.25H16.1875C16.7053 2.25 17.125 2.66973 17.125 3.1875V10.0625C17.125 10.5803 16.7053 11 16.1875 11H11.8125C11.2947 11 10.875 10.5803 10.875 10.0625V3.1875Z", stroke: "#27272A", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx11("path", { d: "M2.125 9.4375C2.125 8.91973 2.54473 8.5 3.0625 8.5H7.4375C7.95527 8.5 8.375 8.91973 8.375 9.4375V11.3125C8.375 11.8303 7.95527 12.25 7.4375 12.25H3.0625C2.54473 12.25 2.125 11.8303 2.125 11.3125V9.4375Z", stroke: "#27272A", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" })
] }));
var accountDetails_default = SvgAccountDetails;

// src/components/AccountDetails.tsx
import { Fragment as Fragment5, jsx as jsx12, jsxs as jsxs10 } from "react/jsx-runtime";
function AccountDetails() {
  const [open, setOpen] = useState5(false);
  const [editProfileOpen, setEditProfileOpen] = useState5(false);
  const [deleteProfileOpen, setDeleteProfileOpen] = useState5(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setEditProfileOpen(false);
    setDeleteProfileOpen(false);
  };
  return /* @__PURE__ */ jsxs10(Fragment5, { children: [
    /* @__PURE__ */ jsxs10(
      ListItemButton2,
      {
        onClick: handleOpen,
        sx: {
          color: "primary.main",
          display: "flex",
          flexDirection: "row"
        },
        children: [
          /* @__PURE__ */ jsx12(accountDetails_default, {}),
          /* @__PURE__ */ jsx12(Typography8, { fontWeight: "600", marginLeft: "12px", children: "Account Details" })
        ]
      }
    ),
    /* @__PURE__ */ jsx12(
      AccountDetailsDialog,
      {
        open,
        handleClose,
        setEditProfileOpen,
        editProfileOpen,
        setDeleteProfileOpen,
        deleteProfileOpen
      }
    )
  ] });
}
function AccountDetailsRow({ label, value }) {
  return /* @__PURE__ */ jsxs10(Fragment5, { children: [
    /* @__PURE__ */ jsx12(Typography8, { variant: "bodyL", sx: { textAlign: "right" }, children: label }),
    /* @__PURE__ */ jsx12(Typography8, { variant: "body1", children: value })
  ] });
}
function AccountDetailsDialog({
  open,
  handleClose,
  setEditProfileOpen,
  editProfileOpen,
  setDeleteProfileOpen,
  deleteProfileOpen
}) {
  const { data: allNetworks, isPending: networksPending } = useAllPublicNetworks();
  const { platform } = useAuth5();
  const { axios } = useApi4();
  const organizationId = platform == null ? void 0 : platform.org.id;
  const { data: orgPayoutConfig, isPending: orgPayoutConfigLoading } = useOrgPayoutConfig2(organizationId);
  const { data: organizationProfile, isPending } = useOrgProfile3(organizationId);
  const { mutateAsync: updatePayoutAddress } = useUpdatePayoutAddress3();
  const { mutateAsync: saveOrgProfile } = useEditOrgProfile3(organizationId);
  const { profileClosureEnabled, onboardingMarketingUpdatesConsent } = useFeatureFlags5();
  const { data: notificationSettings, isSuccess } = useGetOrgNotificationSettings3();
  const { mutateAsync: updateEmailNotificationSettings } = useEditOrgNotificationSettings3();
  const { mutateAsync: closeProfile } = useRequestProfileClosure();
  const userEmail = platform == null ? void 0 : platform.user.email;
  const userId = platform == null ? void 0 : platform.user.id;
  const userNetworks = platform == null ? void 0 : platform.org.network_ids;
  const walletAddress = orgPayoutConfig == null ? void 0 : orgPayoutConfig.payout_address;
  const networkDisplayNames = userNetworks == null ? void 0 : userNetworks.map((id) => {
    var _a;
    return ((_a = allNetworks.find((network) => network.id === id)) == null ? void 0 : _a.name) || `Unknown Network (${id})`;
  }).join(", ");
  const navigate = useNavigate();
  const pluginRegistry = usePluginRegistry3();
  const logout = useCallback2(() => {
    navigate("/auth/logout");
    pluginRegistry.events.publish(new AuthClearEvent());
  }, [navigate, pluginRegistry.events]);
  const handleDeleteProfile = () => __async(this, null, function* () {
    const orgId = platform == null ? void 0 : platform.org.id;
    yield closeProfile({ organizationId: orgId != null ? orgId : "" });
    logout();
  });
  const handleSubmit = (values) => __async(this, null, function* () {
    var _a;
    try {
      if (organizationId && values.payoutAddress !== values.oldPayoutAddress) {
        yield updatePayoutAddress({
          organizationId,
          payoutAddress: values.payoutAddress
        });
      }
      if (onboardingMarketingUpdatesConsent && isSuccess && values.marketingUpdatesConsent !== notificationSettings.marketing_updates_consent) {
        yield updateEmailNotificationSettings({
          marketing_updates_consent: values.marketingUpdatesConsent
        });
      }
      const saveOrgProfileVariables = {
        description: values.creatorBio || null,
        public_name: values.displayName || null,
        slug: values.creatorSlug,
        socials: {
          instagram: values.instagramLink || null,
          twitter: values.twitterLink || null,
          discord: values.discordLink || null
        },
        media: {
          banner: values.bannerUrl || null,
          avatar: values.avatarUrl || null
        },
        creator_roles: !((_a = values.creatorRoles) == null ? void 0 : _a.length) ? [OrganizationProfileOutputCreatorRolesEnum.NoneApply] : values.creatorRoles
      };
      if (values.websiteLink) {
        saveOrgProfileVariables.website = values.websiteLink;
      }
      yield saveOrgProfile(saveOrgProfileVariables);
      setEditProfileOpen(false);
    } catch (error) {
      console.error("Profile update failed", error);
    }
  });
  return /* @__PURE__ */ jsxs10(
    Dialog3,
    {
      sx: {
        ".MuiDialog-paper": { minHeight: 300, minWidth: 650 }
      },
      open,
      onClose: handleClose,
      children: [
        /* @__PURE__ */ jsxs10(DialogTitle2, { sx: { justifyContent: "space-between" }, children: [
          /* @__PURE__ */ jsxs10(Stack7, { sx: { flex: 1, display: "flex", flexDirection: "column", rowGap: 1 }, children: [
            /* @__PURE__ */ jsx12(Typography8, { variant: "h4", children: "Account Details" }),
            /* @__PURE__ */ jsx12(Typography8, { variant: "body1", children: "Detailed information about your account. These can be handy to know in the event of any issues." })
          ] }),
          /* @__PURE__ */ jsx12(IconButton3, { "aria-label": "close", onClick: handleClose, size: "large", children: /* @__PURE__ */ jsx12(CloseIcon3, { fontSize: "small" }) })
        ] }),
        /* @__PURE__ */ jsxs10(
          DialogContent3,
          {
            sx: {
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              rowGap: 2,
              columnGap: 2
            },
            children: [
              /* @__PURE__ */ jsx12(AccountDetailsRow, { label: "Registered Email:", value: userEmail }),
              !orgPayoutConfigLoading && walletAddress && /* @__PURE__ */ jsxs10(Fragment5, { children: [
                /* @__PURE__ */ jsx12(Typography8, { variant: "bodyL", sx: { textAlign: "right" }, children: "Payout Address:" }),
                /* @__PURE__ */ jsx12(Tooltip, { title: "Click to update Payout Address", children: /* @__PURE__ */ jsx12(
                  Typography8,
                  {
                    variant: "body1",
                    onClick: () => setEditProfileOpen(true),
                    sx: { cursor: "pointer" },
                    children: walletAddress
                  }
                ) })
              ] }),
              !networksPending && /* @__PURE__ */ jsx12(AccountDetailsRow, { label: "Enabled Networks:", value: networkDisplayNames }),
              /* @__PURE__ */ jsx12(AccountDetailsRow, { label: "Organization ID:", value: organizationId }),
              /* @__PURE__ */ jsx12(AccountDetailsRow, { label: "User ID:", value: userId })
            ]
          }
        ),
        /* @__PURE__ */ jsxs10(DialogActions3, { sx: { justifyContent: "end" }, children: [
          profileClosureEnabled && /* @__PURE__ */ jsx12(
            Button3,
            {
              "data-testid": "next",
              variant: "outlined",
              onClick: () => setDeleteProfileOpen(true),
              color: "error",
              sx: {
                "&:hover": {
                  color: "white !important",
                  background: "#ff0000"
                }
              },
              children: "Delete Profile"
            }
          ),
          /* @__PURE__ */ jsx12(
            Button3,
            {
              "data-testid": "next",
              variant: "contained",
              sx: {
                "&.Mui-disabled": {
                  background: "#eaeaea",
                  color: "#c0c0c0"
                },
                color: (theme) => `${theme.palette.primary.main} !important`,
                "&:hover": {
                  color: "white !important"
                }
              },
              onClick: () => setEditProfileOpen(true),
              children: "Edit Profile"
            }
          )
        ] }),
        editProfileOpen && !isPending && /* @__PURE__ */ jsx12(
          CreatorProfileEditForm,
          {
            open: editProfileOpen,
            handleClose,
            handleSubmit,
            organizationProfile,
            axios
          }
        ),
        profileClosureEnabled && deleteProfileOpen && !isPending && /* @__PURE__ */ jsx12(
          CreatorProfileDeleteForm,
          {
            open: deleteProfileOpen,
            handleClose,
            handleDelete: handleDeleteProfile
          }
        )
      ]
    }
  );
}

// src/components/profile-view/CreatorProfileChip.tsx
import { useCreateCreatorProfilePageUri } from "common-api";
import { useAuth as useAuth6 } from "common-ui";
import { ListItemButton as ListItemButton3, Typography as Typography9 } from "@mui/material";

// src/assets/icons/exportIcon.svg
import * as React3 from "react";
import { jsx as jsx13 } from "react/jsx-runtime";
var SvgExportIcon = (props) => /* @__PURE__ */ jsx13("svg", __spreadProps(__spreadValues({ width: 14, height: 14, viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props), { children: /* @__PURE__ */ jsx13("path", { d: "M5.5 2.5H2.5C1.67157 2.5 1 3.17157 1 4V11.5C1 12.3284 1.67157 13 2.5 13H10C10.8284 13 11.5 12.3284 11.5 11.5V8.5M8.5 1H13M13 1V5.5M13 1L5.5 8.5", stroke: "#27272A", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }) }));
var exportIcon_default = SvgExportIcon;

// src/components/profile-view/CreatorProfileChip.tsx
import { jsx as jsx14, jsxs as jsxs11 } from "react/jsx-runtime";
var ViewCreatorProfile = () => {
  const { platform } = useAuth6();
  const organizationId = platform == null ? void 0 : platform.org.id;
  const {
    mutateAsync: createConsumerProfileUri,
    isPending: consumerProfileUriPending,
    data: consumerProfileUriResult
  } = useCreateCreatorProfilePageUri(organizationId);
  return /* @__PURE__ */ jsxs11(
    ListItemButton3,
    {
      sx: {
        display: "flex",
        flexDirection: "row"
      },
      onClick: () => {
        if (consumerProfileUriResult) {
          const uri = (consumerProfileUriResult == null ? void 0 : consumerProfileUriResult.data).absolute;
          if (uri) {
            window.open(uri, "_blank");
          }
        } else {
          createConsumerProfileUri().then((result) => {
            const uri = (result == null ? void 0 : result.data).absolute;
            if (uri) {
              window.open(uri, "_blank");
            }
          });
        }
      },
      children: [
        /* @__PURE__ */ jsx14(exportIcon_default, {}),
        /* @__PURE__ */ jsx14(Typography9, { fontWeight: "600", marginLeft: "12px", children: consumerProfileUriPending ? "Getting Profile ..." : "View Public Profile" })
      ]
    }
  );
};
var CreatorProfileChip_default = ViewCreatorProfile;

// src/plugins/profile-edit/Plugin.tsx
import { jsx as jsx15 } from "react/jsx-runtime";
var ProfileEditPlugin = class extends Plugin2 {
  get info() {
    return {
      name: "profile-edit",
      title: "Profile Edit",
      disabledByDefault: false
    };
  }
  activate(registry) {
    return __async(this, null, function* () {
      registry.fillSlot(PORTAL_APP_BAR_USER_PROFILE_SLOT.id, () => /* @__PURE__ */ jsx15(CreatorProfileChip_default, {}));
      registry.fillSlot(PORTAL_APP_BAR_USER_PROFILE_SLOT.id, () => /* @__PURE__ */ jsx15(CreatorProfileEdit, {}));
      registry.fillSlot(PORTAL_APP_BAR_USER_PROFILE_SLOT.id, () => /* @__PURE__ */ jsx15(AccountDetails, {}));
      registry.registerCommand({
        id: "portal.profile-edit.open",
        title: "Open Profile Edit",
        handler: () => {
          openProfileEditModal();
        }
      });
      registry.subscribeEvent("api.action", (event) => {
        const { resource, data, type } = event.data;
        if (resource === "ORGANIZATION_PROFILE" && type === "UPDATE") {
          setOrganizationProfileData(data);
        }
      });
    });
  }
};
export {
  ProfileEditPlugin,
  ProfileSetupPlugin
};
