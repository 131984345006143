var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj))
    throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj))
    throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/Plugin.ts
var Plugin = class {
  get key() {
    return `${this.info.name}${this.info.version == null ? "" : `@${this.info.version}`}`;
  }
  get info() {
    throw new Error("Method not implemented.");
  }
  activate(registry) {
    return __async(this, null, function* () {
      throw new Error("Method not implemented.");
    });
  }
  deactivate(registry) {
  }
};

// src/PluginRegistry.ts
import debug4 from "debug";
import * as semver from "semver";
import { proxy as proxy3 } from "valtio";

// src/CommandRegistry.ts
import debug from "debug";
import { proxy } from "valtio";
var log = debug("plugin-system:CommandRegistry");
var CommandRegistry = class {
  constructor() {
    this.map = proxy({});
  }
  register(config, plugin) {
    log("register", { plugin: plugin.key }, config);
    let command = this.map[config.id];
    if (command) {
      throw new Error(`Command "${config.id}" already registered by plugin "${command.plugin.key}"`);
    }
    command = __spreadProps(__spreadValues({}, config), {
      plugin
    });
    this.map[command.id] = command;
  }
  get(id) {
    const command = this.map[id];
    if (!command) {
      console.error("Command not registered:", command);
    }
    return command;
  }
  execute(commandArgs) {
    log("execute", commandArgs);
    const args = typeof commandArgs === "string" ? { id: commandArgs } : commandArgs;
    const command = this.map[args.id];
    if (!command) {
      console.error("Command not registered:", command);
      return;
    }
    if (!command.isEnabled || command.isEnabled(args)) {
      return command.handler(args);
    }
  }
  unregister(id) {
    log("unregister", id);
    delete this.map[id];
  }
  uninstall(plugin) {
    Object.values(this.map).filter((command) => command.plugin.key === plugin.key).forEach((command) => {
      this.unregister(command.id);
    });
  }
};

// src/EventRegistry.ts
import debug2 from "debug";
var log2 = debug2("plugin-system:EventRegistry");
var subscriptionId = 0;
var EventRegistry = class {
  constructor() {
    this.map = {};
  }
  subscribe(id, listener, plugin) {
    log2("subscribe", { plugin: plugin == null ? void 0 : plugin.key }, id, listener);
    let subscriptions = this.map[id];
    if (!subscriptions) {
      subscriptions = [];
      this.map[id] = subscriptions;
    }
    const subscriptionIdLocal = ++subscriptionId;
    const unsubscribe = () => {
      log2("unsubscribe", id, listener);
      const index = subscriptions.findIndex((s) => s.id === subscriptionIdLocal);
      if (index !== -1) {
        subscriptions.splice(index, 1);
      }
    };
    const subscription = { id: subscriptionId, listener, unsubscribe, plugin };
    subscriptions.push(subscription);
    return unsubscribe;
  }
  publish(event) {
    const id = typeof event === "string" ? event : event.id;
    const eventObject = typeof event === "string" ? { id: event } : event;
    log2("publish", eventObject);
    const listeners = this.map[id];
    for (const i in listeners) {
      listeners[i].listener(eventObject);
    }
  }
  uninstall(plugin) {
    var _a;
    for (const event of Object.values(this.map)) {
      for (const listener of event) {
        if (((_a = listener.plugin) == null ? void 0 : _a.key) === plugin.key) {
          listener.unsubscribe();
        }
      }
    }
  }
};

// src/SlotRegistry.ts
import debug3 from "debug";
import { proxy as proxy2 } from "valtio";
var log3 = debug3("plugin-system:SlotRegistry");
var SlotRegistry = class {
  constructor() {
    this.map = proxy2({});
  }
  register(config, plugin) {
    log3("register", { plugin: plugin == null ? void 0 : plugin.key }, config);
    let slot = this.map[config.id];
    if (slot) {
      if (plugin == null) {
        log3("register", "plugin is null");
      } else if (slot.plugin == null) {
        slot.plugin = plugin;
      } else if (slot.plugin !== plugin) {
        throw new Error(`Slot "${config.id}" already registered by plugin "${slot.plugin.key}"`);
      } else {
        throw new Error(`Slot "${config.id}" already registered`);
      }
    } else {
      slot = __spreadProps(__spreadValues({}, config), {
        plugin,
        values: []
      });
      this.map[slot.id] = slot;
    }
  }
  unregister(id) {
    log3("unregister", id);
    delete this.map[id];
  }
  uninstall(plugin) {
    Object.values(this.map).filter((slot) => {
      var _a;
      return ((_a = slot.plugin) == null ? void 0 : _a.key) === plugin.key;
    }).forEach((slot) => {
      this.unregister(slot.id);
    });
    Object.values(this.map).forEach((slot) => {
      slot.values.filter((slotKeyValue) => {
        var _a;
        return ((_a = slotKeyValue.plugin) == null ? void 0 : _a.key) === plugin.key;
      }).forEach((slotKeyValue) => {
        this.removeValue(slot.id, slotKeyValue.value);
      });
    });
  }
  /**
   * Fill a slot with a value. If a plugin is passed, the slot will be
   * automatically cleared when the plugin is deactivated.
   *
   * The generic type `T` is the type of the value.
   *
   * @throws If the size of the slot is full.
   * @throws If the value does not match the schema.
   *
   * @param id The id of the slot.
   * @param value The value to fill the slot with.
   * @param options Options for filling the slot.
   * @param options.key The key of the value. If not set, the value will be appended or prepended to the slot depending on the option `action`.
   * @param options.order The order of the value. Used by the methods `getOrderedKeyValues` and `getOrderedValues`.
   * @param options.action If the key is not set, the value will be appended or prepended to the slot depending on this option.
   * @param options.ifKeyExists If the key is already used in the slot, this option defines what to do. If set to `replace`, the value will be replaced. If set to `ignore` (default), the value will be ignored.
   * @param plugin The plugin that fills the slot.
   *
   */
  fill(id, value, options = {}, plugin) {
    var _a;
    log3("fill", { plugin: plugin == null ? void 0 : plugin.key }, id, options, value);
    const slot = this.get(id);
    const parseResult = (_a = slot.schema) == null ? void 0 : _a.safeParse(value);
    if (parseResult && !parseResult.success) {
      console.error(parseResult.error.issues);
      throw new Error(`Error validating value for slot "${id}"`, {
        cause: parseResult.error.issues
      });
    }
    const valueKey = options.key ? options.key : findUnusedSlotKey(slot);
    const slotKeyValue = {
      value,
      key: valueKey,
      order: options.order,
      container: options.container,
      plugin
    };
    const existingKeyIndex = options.key == null ? -1 : slot.values.findIndex((item) => item.key === options.key);
    if (existingKeyIndex !== -1) {
      if (options.ifKeyExists === "replace") {
        slot.values[existingKeyIndex] = slotKeyValue;
      }
      return;
    }
    if (slot.size != null && slot.values.length >= slot.size) {
      throw new Error(`Slot "${id}" is full`);
    }
    if (options.action == null || options.action === "append") {
      slot.values.push(slotKeyValue);
    } else if (options.action === "prepend") {
      slot.values.unshift(slotKeyValue);
    }
  }
  removeValue(id, value) {
    log3("removeValue", id, value);
    const array = this.get(id).values;
    if (array) {
      array.splice(
        array.findIndex((item) => item.value === value),
        1
      );
    }
  }
  removeKey(id, key) {
    log3("removeKey", id, key);
    const array = this.get(id).values;
    if (array) {
      array.splice(
        array.findIndex((item) => item.key === key),
        1
      );
    }
  }
  get(id) {
    const slot = this.map[id];
    if (slot) {
      return slot;
    }
    const slotNew = proxy2({ id, values: [] });
    this.map[id] = slotNew;
    return slotNew;
  }
  getOrderedKeyValues(id) {
    const slot = this.get(id);
    const values = Array.from(slot.values);
    if (values.length === 0 && slot.placeholder != null) {
      return [{ value: slot.placeholder, key: "" }];
    }
    return values.sort((a, b) => (a.order || 0) - (b.order || 0));
  }
  getOrderedValues(id) {
    const result = this.getOrderedKeyValues(id).map((item) => item.value);
    log3("getOrderedValues", id, result);
    return result;
  }
  getValue(id, valueKey) {
    var _a, _b;
    const slot = this.get(id);
    const values = slot.values;
    if (values.length === 0) {
      return slot.placeholder;
    }
    if (valueKey == null) {
      return (_a = values[0]) == null ? void 0 : _a.value;
    }
    return (_b = values.find((item) => item.key === valueKey)) == null ? void 0 : _b.value;
  }
};
function findUnusedSlotKey(slot) {
  let key = slot.values.length;
  const keys = slot.values.map((item) => item.key);
  while (keys.includes(key.toString())) {
    key++;
  }
  return key.toString();
}

// src/graph.ts
var Graph = class {
  constructor() {
    this.vertices = [];
    this.edges = {};
  }
  addVertex(v) {
    this.vertices.push(v);
    this.edges[v] = [];
  }
  addEdge(v, w) {
    this.edges[v].push(w);
  }
  topologicalSortDFS() {
    const result = new Array();
    const visited = /* @__PURE__ */ new Set();
    for (const vertex of this.vertices) {
      if (visited.has(vertex)) {
        continue;
      }
      const stack = [vertex];
      while (stack.length > 0) {
        const current = stack[stack.length - 1];
        let dependencyPushed = false;
        for (const dependency of this.edges[current]) {
          if (!visited.has(dependency)) {
            visited.add(dependency);
            stack.push(dependency);
            dependencyPushed = true;
          } else if (!result.includes(dependency)) {
            throw new Error(
              `Cyclic dependency detected: ${current.toString()} -> ${dependency.toString()}`
            );
          }
        }
        if (!dependencyPushed) {
          stack.pop();
          if (!result.includes(current)) {
            result.push(current);
          }
        }
      }
    }
    return result;
  }
};

// src/slots.ts
import { z } from "zod";
var pluginActivationSchema = z.function().args(z.instanceof(Plugin)).returns(z.boolean());
var PLUGIN_ACTIVATION_SLOT = {
  id: "plugin-system.plugin-activation",
  schema: pluginActivationSchema
};

// src/PluginRegistry.ts
var log4 = debug4("plugin-system:PluginRegistry");
var _activatingPlugin, _isActivating, _activated;
var PluginRegistry = class {
  constructor() {
    this.map = proxy3({});
    this.commands = new CommandRegistry();
    this.slots = new SlotRegistry();
    this.events = new EventRegistry();
    __privateAdd(this, _activatingPlugin, void 0);
    __privateAdd(this, _isActivating, false);
    __privateAdd(this, _activated, {});
    log4("constructor");
    this.slots.register(PLUGIN_ACTIVATION_SLOT, void 0);
  }
  install(plugin) {
    var _a;
    const key = plugin.key;
    const errors = [];
    for (const dep of (_a = plugin.info.dependencies) != null ? _a : []) {
      try {
        this.getDependency(dep);
      } catch (e) {
        errors.push(`Dependency of "${key}": ${e.message}`);
      }
    }
    if (errors.length === 0) {
      this.map[key] = plugin;
    }
    return errors;
  }
  installMany(plugins) {
    var _a;
    const graph = new Graph();
    const pluginMap = {};
    for (const plugin of plugins) {
      const name = plugin.info.name;
      if (pluginMap[name]) {
        pluginMap[name].push(plugin);
      } else {
        pluginMap[name] = [plugin];
      }
      graph.addVertex(name);
    }
    for (const plugin of plugins) {
      const name = plugin.info.name;
      ((_a = plugin.info.dependencies) != null ? _a : []).forEach((dependency) => {
        const dependencyName = typeof dependency == "string" ? dependency : dependency.name;
        if (!pluginMap[dependencyName]) {
          graph.addVertex(dependencyName);
        }
        graph.addEdge(name, dependencyName);
      });
    }
    return flatten(
      graph.topologicalSortDFS().map((name) => pluginMap[name] && flatten(pluginMap[name].map(this.install.bind(this))))
    );
  }
  getDependency(dep) {
    const depName = typeof dep == "string" ? dep : dep.name;
    const depVersion = typeof dep == "string" ? void 0 : dep.version;
    return this.get(depName, depVersion);
  }
  get isActivating() {
    return __privateGet(this, _isActivating);
  }
  activateAll() {
    return __async(this, null, function* () {
      log4("activateAll");
      if (__privateGet(this, _isActivating)) {
        throw new Error("Cannot activate plugins because activation is already in progress");
      }
      try {
        const activated = [];
        __privateSet(this, _isActivating, true);
        for (const key of Object.keys(this.map)) {
          if (!__privateGet(this, _isActivating)) {
            activated.reverse().forEach(this.deactivate.bind(this));
            return false;
          }
          yield this.activate(key);
          activated.push(key);
        }
        return true;
      } finally {
        __privateSet(this, _isActivating, false);
      }
    });
  }
  activate(pluginKey) {
    return __async(this, null, function* () {
      var _a, _b;
      log4("activate", pluginKey);
      if (this.isActivated(pluginKey)) {
        log4("already activated", pluginKey);
        return;
      }
      const plugin = this.map[pluginKey];
      const activationHooks = this.slots.getOrderedValues(
        PLUGIN_ACTIVATION_SLOT.id
      );
      try {
        const dependencies = ((_b = (_a = plugin == null ? void 0 : plugin.info) == null ? void 0 : _a.dependencies) != null ? _b : []).map((dep) => this.getDependency(dep));
        const isEnabled = activationHooks.length === 0 ? !plugin.info.disabledByDefault : activationHooks.reduce((acc, cur) => acc && cur(plugin), true);
        if (!isEnabled) {
          return;
        }
        log4("checking dependencies");
        for (const dep of dependencies) {
          if (!this.isActivated(dep.key)) {
            log4("dependency not activated:", dep.key);
            log4("plugin is disabled by disabled dependencies");
            return;
          }
        }
        __privateSet(this, _activatingPlugin, plugin);
        __privateGet(this, _activated)[pluginKey] = true;
        yield plugin.activate(this);
      } finally {
        __privateSet(this, _activatingPlugin, void 0);
      }
    });
  }
  isActivated(pluginKey) {
    var _a;
    return (_a = __privateGet(this, _activated)[pluginKey]) != null ? _a : false;
  }
  cancelActivation() {
    log4("cancelActivation");
    __privateSet(this, _isActivating, false);
  }
  deactivate(pluginKey) {
    if (!__privateGet(this, _activated)[pluginKey]) {
      return;
    }
    const plugin = this.map[pluginKey];
    if (!plugin) {
      return;
    }
    log4("deactivate", pluginKey);
    this.commands.uninstall(plugin);
    this.slots.uninstall(plugin);
    plugin.deactivate(this);
    __privateGet(this, _activated)[pluginKey] = false;
  }
  uninstall(pluginKey) {
    const plugin = this.map[pluginKey];
    if (!plugin) {
      return;
    }
    log4("uninstall", pluginKey);
    this.deactivate(pluginKey);
    delete this.map[pluginKey];
  }
  deactivateAll() {
    log4("deactivateAll");
    this.cancelActivation();
    Object.keys(this.map).forEach(this.deactivate.bind(this));
  }
  uninstallAll() {
    log4("uninstallAll");
    this.cancelActivation();
    Object.keys(this.map).forEach(this.uninstall.bind(this));
  }
  get(key, requestedVersion) {
    const installed = Object.values(this.map).filter(
      (p) => typeof key == "string" ? p.info.name === key : p instanceof key
    );
    let fallback = void 0;
    let greatestVersion = void 0;
    let result = void 0;
    for (const plugin of installed) {
      if (plugin.info.version == null) {
        if (!requestedVersion) {
          fallback = plugin;
        }
        continue;
      }
      const installedVersion = plugin.info.version;
      let isValid = true;
      if (requestedVersion) {
        const versionDiff = semver.diff(installedVersion, requestedVersion);
        isValid = (versionDiff === null || versionDiff === "patch" || versionDiff === "minor") && semver.gte(installedVersion, requestedVersion);
      }
      if (isValid && (greatestVersion == null || semver.gt(installedVersion, greatestVersion))) {
        greatestVersion = installedVersion;
        result = plugin;
      }
    }
    if (result) {
      return result;
    }
    if (fallback) {
      return fallback;
    }
    throw new Error(
      `Could not find plugin "${typeof key == "string" ? key : key.name}${requestedVersion == null ? "" : "@" + requestedVersion}".`
    );
  }
  /**
   * Register a command. This method should be called during plugin activation.
   * The command will be unregistered automatically when the plugin is deactivated.
   *
   * The generic type `T` is the type of the command arguments. It is optional and is
   * used to enforce type safety in the command handler.
   *
   * @param config The command configuration object.
   * @param config.id is the id of the command.
   * @param config.handler is the command handler function. Receives the argument from the caller.
   * @param config.title (optional) is the title of the command.
   * @param config.isEnabled (optional) is a function that returns whether the command is enabled or not.
   *
   * @example
   * ```ts
   * registry.registerCommand({
   *   id: 'salute-command',
   *   title: 'Salute!',
   *   handler: () => console.log('Hello world!'),
   * })
   * ```
   *
   * @example
   * ```ts
   * type NavigateCommandArgs = { url: string }
   * registry.registerCommand<NavigateCommandArgs>({
   *   id: 'navigate',
   *   handler: (args) => localtion.href = args.url, // args is of type NavigateCommandArgs
   * })
   * // ...then in another part of the code:
   * registry.commands.execute('navigate', { url: 'https://google.com' })
   * ```
   *
   */
  registerCommand(config) {
    if (__privateGet(this, _activatingPlugin) == null) {
      throw new Error("Cannot register command outside of plugin activation");
    }
    this.commands.register(config, __privateGet(this, _activatingPlugin));
  }
  /**
   * Register a slot. This method should be called during plugin activation.
   * The slot will be unregistered automatically when the plugin is deactivated.
   *
   * @param config The slot configuration object.
   * @param config.id is the id of the slot.
   * @param config.size (optional) is the maximum number of values that can be filled into the slot.
   * @example
   * ```ts
   * registry.registerSlot({ id: 'my-slot', size: 1 })
   * ```
   * @param config.schema (optional) is a `zod` schema of the values that can be filled into the slot.
   * @example
   * ```ts
   * registry.registerSlot({ id: 'my-slot', schema: { type: 'string' } })
   * ```
   *
   */
  registerSlot(config) {
    if (__privateGet(this, _activatingPlugin) == null) {
      throw new Error("Cannot register slot outside of plugin activation");
    }
    this.slots.register(config, __privateGet(this, _activatingPlugin));
  }
  /**
   * Fill a slot with a value. If called during plugin activation, the slot will be
   * automatically cleared when the plugin is deactivated.
   *
   * The generic type `T` is the type of the value.
   *
   * @example
   * ```ts
   * registry.fillSlot('my-slot', 'my-value')
   * ```
   *
   * @param id The id of the slot.
   * @param value The value to fill the slot with.
   * @param options (optional) Options for the slot value.
   * @param options.key (optional) is a key that can be used to identify the value.
   * @param options.scope (optional) is a scope that can be used to identify the value.
   */
  fillSlot(id, value, options = {}) {
    this.slots.fill(id, value, options, __privateGet(this, _activatingPlugin));
  }
  subscribeEvent(id, listener) {
    return this.events.subscribe(id, listener, __privateGet(this, _activatingPlugin));
  }
};
_activatingPlugin = new WeakMap();
_isActivating = new WeakMap();
_activated = new WeakMap();
function flatten(arr) {
  return arr.reduce((acc, cur) => [...acc, ...cur], []);
}

// src/PluginProvider.tsx
import { useEffect, useState } from "react";

// src/PluginRegistryContext.tsx
import * as React from "react";
var PluginRegistryContext = React.createContext({});
var PluginRegistryContext_default = PluginRegistryContext;

// src/PluginProvider.tsx
import { jsx } from "react/jsx-runtime";
function PluginProvider({
  pluginRegistry,
  children
}) {
  const [activated, setActivated] = useState(false);
  useEffect(() => {
    if (!pluginRegistry.isActivating) {
      pluginRegistry.activateAll().then((success) => {
        if (success) {
          setActivated(true);
        }
      }).catch(console.error);
    }
  }, [pluginRegistry]);
  if (!activated) {
    return null;
  }
  return /* @__PURE__ */ jsx(PluginRegistryContext_default.Provider, { value: pluginRegistry, children });
}

// src/SlotRenderer.tsx
import debug5 from "debug";
import * as React2 from "react";

// src/hooks.tsx
import { useCallback, useContext } from "react";
import { useSnapshot } from "valtio";
function usePluginRegistry() {
  return useContext(PluginRegistryContext_default);
}
function useCommandExecutor(commandArgs) {
  const pluginRegistry = usePluginRegistry();
  return useCallback(
    () => pluginRegistry.commands.execute(commandArgs),
    [commandArgs, pluginRegistry]
  );
}
function useSlotKeyValues(slotId) {
  const pluginRegistry = usePluginRegistry();
  useSnapshot(pluginRegistry.slots.get(slotId));
  return pluginRegistry.slots.getOrderedKeyValues(slotId);
}
function useSlotValues(slotId) {
  const pluginRegistry = usePluginRegistry();
  useSnapshot(pluginRegistry.slots.get(slotId));
  return pluginRegistry.slots.getOrderedValues(slotId);
}
function useSlotValue(slotId, defaultValue) {
  var _a;
  const pluginRegistry = usePluginRegistry();
  useSnapshot(pluginRegistry.slots.get(slotId));
  return (_a = pluginRegistry.slots.getValue(slotId)) != null ? _a : defaultValue;
}
function usePluginState(name, defaultValue) {
  const pluginRegistry = usePluginRegistry();
  try {
    const apiPlugin = pluginRegistry.get(name);
    const apiState = useSnapshot(apiPlugin.state);
    return apiState != null ? apiState : defaultValue;
  } catch (e) {
    return defaultValue;
  }
}

// src/SlotRenderer.tsx
import { Fragment as Fragment2, jsx as jsx2, jsxs } from "react/jsx-runtime";
var log5 = debug5("plugin-system:SlotRenderer");
function SlotRenderer(_a) {
  var _b = _a, {
    slot,
    children,
    component: Component,
    container
  } = _b, rest = __objRest(_b, [
    "slot",
    "children",
    "component",
    "container"
  ]);
  log5("Rendering slot: %s", slot);
  const slotKeyValues = useSlotKeyValues(slot);
  log5(
    "Slot %s has %d key values: %s",
    slot,
    slotKeyValues.length,
    slotKeyValues.map((skv) => skv.key)
  );
  const slotHasContent = slotKeyValues.length > 0;
  slotKeyValues.sort((a, b) => (a.value.order || 0) - (b.value.order || 0));
  const contained = slotKeyValues.filter((skv) => !skv.container).map(
    (skv) => Component ? typeof skv.value == "function" ? /* @__PURE__ */ jsx2(Component, { children: /* @__PURE__ */ jsx2(skv.value, __spreadValues({}, rest)) }, skv.key) : typeof skv.value == "object" && !isReactElement(skv.value) ? /* @__PURE__ */ jsx2(Component, __spreadValues(__spreadValues({}, skv.value), rest), skv.key) : /* @__PURE__ */ jsx2(Component, __spreadProps(__spreadValues({}, rest), { children: skv.value }), skv.key) : typeof skv.value == "function" ? /* @__PURE__ */ jsx2(skv.value, __spreadValues({}, rest), skv.key) : typeof skv.value == "object" && !isReactElement(skv.value) ? /* @__PURE__ */ jsx2(React2.Fragment, { children: JSON.stringify(skv.value) }, skv.key) : /* @__PURE__ */ jsx2(React2.Fragment, { children: skv.value }, skv.key)
  );
  const containers = slotKeyValues.filter((skv) => skv.container);
  if (container && slotHasContent) {
    containers.push({ key: "container", value: container });
  }
  const containerElement = containers.reduceRight(
    (acc, container2) => /* @__PURE__ */ jsx2(container2.value, { children: acc }),
    /* @__PURE__ */ jsx2(Fragment2, { children: contained })
  );
  const isDebug = debug5.enabled("plugin-system:SlotRenderer");
  const placeholder = (children != null ? children : isDebug) ? /* @__PURE__ */ jsxs("div", { style: { outline: "1px dotted red" }, children: [
    "Slot: ",
    slot
  ] }) : null;
  return /* @__PURE__ */ jsx2(Fragment2, { children: slotHasContent ? containerElement : /* @__PURE__ */ jsx2(Fragment2, { children: placeholder }) });
}
function isReactElement(value) {
  var _a;
  return ((_a = value == null ? void 0 : value.$$typeof) == null ? void 0 : _a.toString()) === Symbol("react.element").toString();
}
export {
  PLUGIN_ACTIVATION_SLOT,
  Plugin,
  PluginProvider,
  PluginRegistry,
  SlotRenderer,
  useCommandExecutor,
  usePluginRegistry,
  usePluginState,
  useSlotKeyValues,
  useSlotValue,
  useSlotValues
};
