import { PluginRegistry, Plugin, PluginInfo } from 'plugin-system'
import { z } from 'zod'

export default class ShellPlugin extends Plugin {
  get info(): PluginInfo {
    return { name: 'shell' }
  }

  async activate(registry: PluginRegistry): Promise<void> {
    registry.registerSlot({ id: 'shell.content', schema: z.function() })
    registry.registerSlot({ id: 'shell.routes', schema: z.object({}) })
    registry.registerSlot({ id: 'shell.config', schema: z.object({}) })
  }
}
