// src/plugins/payments/Plugin.tsx
import debug from "debug";
import { loadStripe } from "@stripe/stripe-js";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { injectedWallet, metaMaskWallet, walletConnectWallet } from "@rainbow-me/rainbowkit/wallets";
import { http, WagmiProvider } from "wagmi";
import { polygon } from "wagmi/chains";
import "@rainbow-me/rainbowkit/styles.css";
import { Plugin } from "plugin-system";
import { getConfig as getConfig2, PORTAL_TOP_BAR_SLOT } from "common-ui";

// src/components/MainModal.tsx
import { useSnapshot as useSnapshot8 } from "valtio";
import { NotificationEvent as NotificationEvent3, SimpleModal, getConfig } from "common-ui";
import { ErrorBoundary } from "react-error-boundary";
import { useEffect as useEffect13 } from "react";
import { usePluginRegistry as usePluginRegistry4 } from "plugin-system";
import { Typography as Typography6, Link } from "@mui/material";

// src/state.ts
import { proxy } from "valtio";
import { subscribeKey } from "valtio/utils";
var defaultState = {
  open: false,
  activePage: "balance",
  previousPage: void 0,
  amount: void 0,
  minPayment: void 0,
  txs: [],
  paymentMethod: void 0,
  defaultPaymentMethod: void 0,
  pollForBalanceUpdates: false,
  expectedBalance: void 0,
  shouldntClose: void 0,
  shouldntCloseReason: void 0
};
var state = proxy(defaultState);
function openPaymentsModal({
  page,
  amount,
  minPayment
} = {}) {
  resetDefaultState();
  setAmount(amount);
  setMinPayment(minPayment);
  setPageState(page ?? defaultState.activePage);
  state.open = true;
}
function setPageState(activePage, previousPage = void 0) {
  state.activePage = activePage;
  state.shouldntClose = void 0;
  state.shouldntCloseReason = void 0;
  state.previousPage = previousPage;
}
function setAmount(amount) {
  state.amount = amount;
}
function setMinPayment(amount) {
  state.minPayment = amount;
}
function setPaymentMethod(paymentMethod) {
  state.paymentMethod = paymentMethod;
}
function setDefaultPaymentMethod(paymentMethod) {
  state.defaultPaymentMethod = paymentMethod;
}
function setExpectedBalance(expected) {
  state.expectedBalance = expected;
}
function resetDefaultState() {
  Object.keys(defaultState).forEach((key) => {
    if (key === "defaultPaymentMethod") {
      return;
    }
    if (key === "open") {
      return;
    }
    if (key === "pollForBalanceUpdates") {
      return;
    }
    if (key === "expectedBalance") {
      return;
    }
    state[key] = defaultState[key];
  });
}
function disableBalancePolling() {
  state.pollForBalanceUpdates = false;
  setExpectedBalance();
}
function enableBalancePolling(expectedBalance) {
  state.pollForBalanceUpdates = true;
  if (expectedBalance) {
    setExpectedBalance(expectedBalance);
  }
}
var state_default = state;
subscribeKey(state, "pollForBalanceUpdates", (v) => {
  if (v) {
    setTimeout(() => disableBalancePolling(), 5 * 60 * 1e3);
  }
});

// src/components/AccountBalance.tsx
import { Box as Box2, Stack as Stack4 } from "@mui/material";

// src/components/ActionButtons.tsx
import { Button, Stack } from "@mui/material";

// src/components/useClosePaymentsModal.tsx
import { usePluginRegistry } from "plugin-system";
import { useCallback } from "react";
import { useSnapshot } from "valtio";
var useClosePaymentsModal = () => {
  const { open, shouldntClose, shouldntCloseReason } = useSnapshot(state_default);
  const pluginRegistry = usePluginRegistry();
  return useCallback(() => {
    if (!open)
      return;
    if (shouldntClose === true) {
      pluginRegistry.commands.execute({
        id: "portal.confirm-dialog",
        dialogTitle: "Hold Up!",
        dialogSubtitle: Boolean(shouldntCloseReason) ? shouldntCloseReason : "Are you sure you want to leave this page? Any unsaved changes might be lost",
        confirmButtonLabel: "Confirm",
        onConfirm: () => state_default.open = false
      });
    } else
      state_default.open = false;
  }, [open, shouldntClose, pluginRegistry.commands, shouldntCloseReason]);
};

// src/components/ActionButtons.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var ActionButtons = ({
  backSlot,
  nextSlot,
  onBack,
  onNext = () => {
  },
  cancelButtonText = "Cancel",
  nextButtonText = "Next",
  disableNextButton = false,
  sx
}) => {
  const closeMainModal = useClosePaymentsModal();
  return /* @__PURE__ */ jsxs(
    Stack,
    {
      direction: "row",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "16px",
      sx: { ...sx ?? {} },
      children: [
        backSlot ? backSlot : /* @__PURE__ */ jsx(
          Button,
          {
            variant: "outlined",
            sx: { flexGrow: 0, height: "48px", width: "50%" },
            onClick: onBack ?? closeMainModal,
            "data-testid": "payments-modal-cancel-button",
            children: onBack ? "Back" : cancelButtonText
          }
        ),
        nextSlot ? nextSlot : /* @__PURE__ */ jsx(
          Button,
          {
            variant: "contained",
            onClick: onNext,
            disabled: disableNextButton,
            sx: {
              flexGrow: 0,
              height: "48px",
              width: "50%"
            },
            "data-testid": "payments-modal-next-button",
            children: nextButtonText
          }
        )
      ]
    }
  );
};
var ActionButtons_default = ActionButtons;

// src/components/BalanceStack.tsx
import IconButton from "@mui/material/IconButton";
import Stack3 from "@mui/material/Stack";
import Typography3 from "@mui/material/Typography";
import RefreshIcon from "@mui/icons-material/Refresh";

// src/components/StatusIndicator.tsx
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Stack2 from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { jsx as jsx2, jsxs as jsxs2 } from "react/jsx-runtime";
var StatusCircle = styled(Box)`
  width: ${(props) => props.width || "14px"};
  height: ${(props) => props.height || "14px"};
  border-radius: 14px;

  background-color: ${(props) => props.theme.palette?.[props?.status]?.main};

  animation: ${(props) => props.status === "success" ? "none" : `pulse 1s infinite ease`};
  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(97, 244, 255, 0.7);
    }

    60% {
      transform: scale(1);
      box-shadow: 0 0 0 6px rgba(97, 244, 255, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(97, 244, 255, 0);
    }
  }
`;
var StatusIndicator = ({
  status,
  withLabel,
  ...rest
}) => {
  if (!withLabel) {
    return /* @__PURE__ */ jsx2(StatusCircle, { status, ...rest });
  }
  return /* @__PURE__ */ jsxs2(Stack2, { direction: "row", width: "100%", alignItems: "center", gap: "8px", children: [
    /* @__PURE__ */ jsx2(StatusCircle, { status, width: "10px", height: "10px" }),
    /* @__PURE__ */ jsx2(Typography, { variant: "body2", children: "Sufficient for active and planned drops" })
  ] });
};
var StatusIndicator_default = StatusIndicator;

// src/components/BalanceTotal.tsx
import Typography2 from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";

// src/hooks/useAuthAccountBalance.tsx
import { useBalance } from "common-api";
import { useAuth } from "common-ui";
import { useEffect, useState } from "react";
import { useSnapshot as useSnapshot2 } from "valtio";
var DEFAULT_REFETCH_INTERVAL = 5 * 60 * 1e3;
var BALANCE_UPDATE_POLLING_INTERVAL = 5 * 1e3;
var useAuthAccountBalance = (queryOptions = {}) => {
  const snap = useSnapshot2(state_default);
  const [refetchInterval, setRefetchInterval] = useState(DEFAULT_REFETCH_INTERVAL);
  useEffect(() => {
    if (snap.pollForBalanceUpdates) {
      setRefetchInterval(BALANCE_UPDATE_POLLING_INTERVAL);
    } else {
      setRefetchInterval(DEFAULT_REFETCH_INTERVAL);
    }
  }, [snap.pollForBalanceUpdates]);
  const { platform } = useAuth();
  const { data, isPending, isError, isFetching, refetch } = useBalance(platform?.org?.id ?? "", {
    ...queryOptions,
    refetchInterval
  });
  const { balance, status } = data || {};
  useEffect(() => {
    if (isError) {
      setRefetchInterval(DEFAULT_REFETCH_INTERVAL);
    }
  }, [isError]);
  useEffect(() => {
    if (!snap.expectedBalance) {
      return;
    }
    if (Number(balance?.total ?? 0) >= snap.expectedBalance) {
      disableBalancePolling();
    }
  }, [snap.expectedBalance, balance?.total]);
  return {
    balance,
    status,
    isPending,
    isFetching,
    isError,
    refetch
  };
};

// src/components/BalanceTotal.tsx
import { jsx as jsx3, jsxs as jsxs3 } from "react/jsx-runtime";
var BalanceTotal = (props) => {
  const { balance, isFetching, isPending } = useAuthAccountBalance();
  return isFetching || isPending ? /* @__PURE__ */ jsx3(CircularProgress, { size: props?.variant?.startsWith("h") ? 30 : 18 }) : /* @__PURE__ */ jsxs3(Typography2, { ...props, children: [
    "$",
    balance?.total || "0"
  ] });
};
var BalanceTotal_default = BalanceTotal;

// src/components/BalanceStack.tsx
import { jsx as jsx4, jsxs as jsxs4 } from "react/jsx-runtime";
var BalanceStack = () => {
  const { status, refetch } = useAuthAccountBalance();
  return /* @__PURE__ */ jsxs4(Stack3, { width: "100%", children: [
    /* @__PURE__ */ jsxs4(Stack3, { direction: "row", alignItems: "center", children: [
      /* @__PURE__ */ jsx4(Typography3, { variant: "body1", children: "Total Balance" }),
      /* @__PURE__ */ jsx4(IconButton, { "aria-label": "refresh", onClick: refetch, size: "small", children: /* @__PURE__ */ jsx4(RefreshIcon, { color: "primary" }) })
    ] }),
    /* @__PURE__ */ jsx4(BalanceTotal_default, { variant: "h2", maxWidth: "50%" }),
    status && /* @__PURE__ */ jsx4(StatusIndicator_default, { status, withLabel: true })
  ] });
};
var BalanceStack_default = BalanceStack;

// src/components/AccountBalance.tsx
import { jsx as jsx5, jsxs as jsxs5 } from "react/jsx-runtime";
var AccountBalance = () => {
  return /* @__PURE__ */ jsxs5(Stack4, { paddingTop: "16px", gap: "42px", children: [
    /* @__PURE__ */ jsx5(BalanceStack_default, {}),
    /* @__PURE__ */ jsx5(
      ActionButtons_default,
      {
        nextButtonText: "Add Funds",
        onNext: () => {
          setPageState("select-payment-method", "balance");
        },
        backSlot: /* @__PURE__ */ jsx5(Box2, {})
      }
    )
  ] });
};
var AccountBalance_default = AccountBalance;

// src/components/Success.tsx
import { Stack as Stack5 } from "@mui/material";
import { useMobileEnabled } from "common-ui";
import { jsx as jsx6, jsxs as jsxs6 } from "react/jsx-runtime";
var Success = ({ onCancel }) => {
  const isMobile = useMobileEnabled();
  return /* @__PURE__ */ jsxs6(Stack5, { paddingTop: "16px", gap: "42px", children: [
    /* @__PURE__ */ jsx6(BalanceStack_default, {}),
    /* @__PURE__ */ jsx6(
      ActionButtons_default,
      {
        onNext: () => {
          resetDefaultState();
          setPageState("deposit");
        },
        nextButtonText: isMobile ? "Add More" : "Add More Funds",
        onCancel,
        cancelButtonText: "Close"
      }
    )
  ] });
};
var Success_default = Success;

// src/components/PaymentMethod.tsx
import { FormControl, FormControlLabel, Radio, RadioGroup, Stack as Stack6, Typography as Typography4 } from "@mui/material";
import { useSnapshot as useSnapshot3 } from "valtio";
import { useEffect as useEffect2 } from "react";
import { jsx as jsx7, jsxs as jsxs7 } from "react/jsx-runtime";
var RadioButtonLabel = ({ label, subtext }) => {
  return /* @__PURE__ */ jsxs7(Stack6, { paddingLeft: "15px", children: [
    /* @__PURE__ */ jsx7(
      Typography4,
      {
        variant: "bodyL",
        fontWeight: 600,
        sx: { color: (theme) => theme.palette.neutral_900 },
        children: label
      }
    ),
    /* @__PURE__ */ jsx7(
      Typography4,
      {
        variant: "body1",
        fontWeight: 400,
        sx: { color: (theme) => theme.palette.neutral_500 },
        children: subtext
      }
    )
  ] });
};
var DEFAULT_PAYMENT_METHOD = "FIAT";
var PaymentMethod = ({ onCancel }) => {
  const snap = useSnapshot3(state_default);
  const handleChange = (event) => {
    setPaymentMethod(event.target.value);
  };
  useEffect2(() => {
    if (!snap.paymentMethod) {
      setPaymentMethod(snap.defaultPaymentMethod ?? DEFAULT_PAYMENT_METHOD);
    }
  }, [snap.paymentMethod, snap.defaultPaymentMethod]);
  return /* @__PURE__ */ jsxs7(Stack6, { gap: "42px", paddingTop: "8px", children: [
    /* @__PURE__ */ jsx7(FormControl, { children: /* @__PURE__ */ jsxs7(
      RadioGroup,
      {
        "aria-labelledby": "demo-controlled-radio-buttons-group",
        name: "controlled-radio-buttons-group",
        value: snap.paymentMethod ?? DEFAULT_PAYMENT_METHOD,
        onChange: handleChange,
        sx: { gap: "36px" },
        children: [
          /* @__PURE__ */ jsx7(
            FormControlLabel,
            {
              value: "FIAT",
              control: /* @__PURE__ */ jsx7(Radio, {}),
              sx: { alignItems: "flex-start" },
              label: /* @__PURE__ */ jsx7(
                RadioButtonLabel,
                {
                  label: "Debit or Credit Card",
                  subtext: "An easy-to-use option with convenient payment processing, but it may incur a few\n              additional transaction fees."
                }
              )
            }
          ),
          /* @__PURE__ */ jsx7(
            FormControlLabel,
            {
              value: "CRYPTO",
              control: /* @__PURE__ */ jsx7(Radio, {}),
              sx: { alignItems: "flex-start" },
              label: /* @__PURE__ */ jsx7(
                RadioButtonLabel,
                {
                  label: "Crypto Wallet",
                  subtext: "Top up your Phosphor account balance with Polygon USDC."
                }
              )
            }
          )
        ]
      }
    ) }),
    /* @__PURE__ */ jsx7(
      ActionButtons_default,
      {
        disableNextButton: !snap.paymentMethod,
        onBack: () => {
          setPageState("balance");
        },
        onNext: () => {
          setPageState("select-amount", "select-payment-method");
        }
      }
    )
  ] });
};
var PaymentMethod_default = PaymentMethod;

// src/components/Deposit.tsx
import { useSnapshot as useSnapshot6 } from "valtio";
import { useCallback as useCallback6, useEffect as useEffect11 } from "react";

// src/components/DepositCrypto/DepositCryptoForm.tsx
import { useCallback as useCallback5, useEffect as useEffect8, useState as useState5 } from "react";
import { useAccount as useAccount4 } from "wagmi";
import { NotificationEvent as NotificationEvent2, useAuth as useAuth3 } from "common-ui";
import { useSnapshot as useSnapshot4 } from "valtio";
import {
  Alert,
  CircularProgress as CircularProgress2,
  FormHelperText,
  Stack as Stack8,
  Step,
  StepLabel,
  Stepper
} from "@mui/material";
import { usePluginRegistry as usePluginRegistry3 } from "plugin-system";

// src/components/DepositCrypto/ConnectButton.tsx
import { ConnectButton as OGConnectButton } from "@rainbow-me/rainbowkit";
import Button3 from "@mui/material/Button";

// src/components/DepositCrypto/ConnectedWallet.tsx
import { Stack as Stack7, Typography as Typography5, Button as Button2 } from "@mui/material";
import { useAccount } from "wagmi";
import { EthereumAddress, useMobileEnabled as useMobileEnabled2 } from "common-ui";

// src/hooks/useUsdcContractReads.tsx
import { useEffect as useEffect3 } from "react";
import { useBlockNumber, useReadContracts } from "wagmi";
import { erc20Abi, formatUnits } from "viem";

// src/utils/constants.ts
var MIN_CRYPTO_DEPOSIT = 1;
var MIN_FIAT_DEPOSIT = 10;
var DEFAULT_ESTIMATE_SALE_TYPE = "CRYPTO";
var PLATFORM_DEPOSIT_PAYMENT_TOKEN_ADDRESS = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";
var TESTNET_USDC_CONTRACT_ADDRESS = "0x616553F076C6F66739a99Fef373C6b4ae1b22A7A";

// src/hooks/useUsdcContractReads.tsx
var useUsdcContractReads = (accountAddress = "0x0", chainId = 80002) => {
  const chainIdTokenAddressMap = {
    137: PLATFORM_DEPOSIT_PAYMENT_TOKEN_ADDRESS,
    80002: TESTNET_USDC_CONTRACT_ADDRESS
  };
  const usdcTokenContract = {
    address: chainIdTokenAddressMap[chainId] ?? TESTNET_USDC_CONTRACT_ADDRESS
  };
  const { data: blockNumber } = useBlockNumber({ watch: true });
  const { data: balance, refetch } = useReadContracts({
    allowFailure: false,
    contracts: [
      {
        address: usdcTokenContract.address,
        abi: erc20Abi,
        functionName: "balanceOf",
        args: [accountAddress]
      },
      {
        address: usdcTokenContract.address,
        abi: erc20Abi,
        functionName: "decimals"
      },
      {
        address: usdcTokenContract.address,
        abi: erc20Abi,
        functionName: "symbol"
      }
    ]
  });
  useEffect3(() => {
    if (!blockNumber)
      return;
    if (Number(blockNumber) % 5 === 0) {
      refetch();
    }
  }, [blockNumber]);
  let displayBalance = "Switch network!";
  if (balance) {
    displayBalance = `${formatUnits(balance[0], balance[1])} ${balance[2]}`;
  }
  return {
    balanceOf: balance ? balance[0] : 0,
    decimals: balance ? balance[1] : 0,
    symbol: balance ? balance[2] : "USDC",
    displayBalance: balance ? `${displayBalance}` : "Switch network!"
  };
};

// src/components/DepositCrypto/ConnectedWallet.tsx
import { jsx as jsx8, jsxs as jsxs8 } from "react/jsx-runtime";
var ConnectedWallet = () => {
  const { address, chain } = useAccount();
  const usdcData = useUsdcContractReads(address, chain?.id);
  const isMobile = useMobileEnabled2();
  if (address && chain) {
    return /* @__PURE__ */ jsxs8(Stack7, { spacing: 1, children: [
      /* @__PURE__ */ jsx8(Typography5, { children: "Connected Wallet" }),
      /* @__PURE__ */ jsxs8(
        Button2,
        {
          sx: {
            display: "flex",
            justifyContent: "space-between",
            bgcolor: "shades.white_10",
            height: "40px",
            pointerEvents: "none"
          },
          fullWidth: true,
          variant: "outlined",
          children: [
            /* @__PURE__ */ jsx8(EthereumAddress, { networkId: chain?.id, value: address, shortFormat: isMobile }),
            /* @__PURE__ */ jsx8(Typography5, { children: usdcData?.displayBalance ? ` (${usdcData.displayBalance})` : "" })
          ]
        }
      )
    ] });
  }
  return null;
};

// src/components/DepositCrypto/ConnectButton.tsx
import { Fragment, jsx as jsx9 } from "react/jsx-runtime";
var ConnectButton = ({
  disconnectedSlot,
  ...buttonProps
}) => {
  return /* @__PURE__ */ jsx9(OGConnectButton.Custom, { children: ({ account, chain, openChainModal, openConnectModal, authenticationStatus, mounted }) => {
    const ready = mounted && authenticationStatus !== "loading";
    const connected = ready && account && chain && (!authenticationStatus || authenticationStatus === "authenticated");
    return /* @__PURE__ */ jsx9(Fragment, { children: (() => {
      if (!connected) {
        return disconnectedSlot ? disconnectedSlot : /* @__PURE__ */ jsx9(Button3, { onClick: openConnectModal, ...buttonProps, children: "Connect Wallet" });
      }
      if (chain.unsupported) {
        return /* @__PURE__ */ jsx9(Button3, { onClick: openChainModal, ...buttonProps, children: "Switch network" });
      }
      return /* @__PURE__ */ jsx9(ConnectedWallet, {});
    })() });
  } });
};
var ConnectButton_default = ConnectButton;

// src/components/DepositCrypto/DepositCrypto.tsx
import { useCallback as useCallback4, useEffect as useEffect7, useReducer } from "react";
import { usePluginRegistry as usePluginRegistry2 } from "plugin-system";
import Button4 from "@mui/material/Button";
import {
  EthereumAddress as EthereumAddress2,
  LoadingButton,
  useConfig,
  NotificationEvent,
  useMobileEnabled as useMobileEnabled3
} from "common-ui";
import { useInvalidateResourceQuery } from "common-api";
import Box3 from "@mui/material/Box";
import { useTheme } from "@mui/material";

// src/components/DepositCrypto/hooks/useTokenAllowance.ts
import { useEffect as useEffect5, useMemo, useState as useState3 } from "react";
import { useAccount as useAccount3 } from "wagmi";

// src/components/DepositCrypto/hooks/useContractInteraction.tsx
import { useCallback as useCallback2, useEffect as useEffect4, useState as useState2 } from "react";
import { useAccount as useAccount2, useWaitForTransactionReceipt } from "wagmi";

// src/generated.ts
import {
  createReadContract,
  createWriteContract,
  createSimulateContract,
  createWatchContractEvent
} from "wagmi/codegen";
import {
  createUseReadContract,
  createUseWriteContract,
  createUseSimulateContract,
  createUseWatchContractEvent
} from "wagmi/codegen";

// src/deployments/PlatformDeposit.ts
var PlatformDepositDeployments = {
  137: { prd: "0xCee4d6C8818cbb9AF4c1AEE2270Bb3c08C2584a1" },
  80002: {
    dev: "0x9b182f1B53d8C223d57633d697F6A2be39AE43D9",
    staging: "0x4814FBa10d18d3032B696ED5DBBFd3de54e77E40"
  }
};

// src/generated.ts
var platformDepositAbi = [
  {
    stateMutability: "nonpayable",
    type: "constructor",
    inputs: [
      { name: "paymentTokenAddress_", internalType: "address", type: "address" },
      { name: "payoutAddress_", internalType: "address", type: "address" },
      { name: "maxDeposit_", internalType: "uint256", type: "uint256" }
    ]
  },
  { type: "error", inputs: [], name: "CannotBeZeroAddress" },
  { type: "error", inputs: [], name: "CannotExceedMaxDeposit" },
  { type: "error", inputs: [], name: "InsufficientBalance" },
  { type: "error", inputs: [], name: "ValueCannotBeZero" },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "clientId", internalType: "bytes32", type: "bytes32", indexed: true },
      { name: "depositor", internalType: "address", type: "address", indexed: false },
      { name: "amount", internalType: "uint256", type: "uint256", indexed: false }
    ],
    name: "Deposit"
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "previousOwner", internalType: "address", type: "address", indexed: true },
      { name: "newOwner", internalType: "address", type: "address", indexed: true }
    ],
    name: "OwnershipTransferStarted"
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "previousOwner", internalType: "address", type: "address", indexed: true },
      { name: "newOwner", internalType: "address", type: "address", indexed: true }
    ],
    name: "OwnershipTransferred"
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "clientId", internalType: "bytes32", type: "bytes32", indexed: true },
      { name: "purchaseId", internalType: "bytes32", type: "bytes32", indexed: true },
      { name: "amount", internalType: "uint256", type: "uint256", indexed: false }
    ],
    name: "PaymentTransfer"
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32", indexed: true },
      { name: "previousAdminRole", internalType: "bytes32", type: "bytes32", indexed: true },
      { name: "newAdminRole", internalType: "bytes32", type: "bytes32", indexed: true }
    ],
    name: "RoleAdminChanged"
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32", indexed: true },
      { name: "account", internalType: "address", type: "address", indexed: true },
      { name: "sender", internalType: "address", type: "address", indexed: true }
    ],
    name: "RoleGranted"
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32", indexed: true },
      { name: "account", internalType: "address", type: "address", indexed: true },
      { name: "sender", internalType: "address", type: "address", indexed: true }
    ],
    name: "RoleRevoked"
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "clientId", internalType: "bytes32", type: "bytes32", indexed: true },
      { name: "depositor", internalType: "address", type: "address", indexed: false },
      { name: "amount", internalType: "uint256", type: "uint256", indexed: false }
    ],
    name: "Withdraw"
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "DEFAULT_ADMIN_ROLE",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }]
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "PAYOUT_EXECUTOR",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }]
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "acceptOwnership",
    outputs: []
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "accounts", internalType: "address[]", type: "address[]" }],
    name: "addPayoutRole",
    outputs: []
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "clientId", internalType: "bytes32", type: "bytes32" }],
    name: "balanceOf",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }]
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "clientId", internalType: "bytes32", type: "bytes32" },
      { name: "amount", internalType: "uint256", type: "uint256" }
    ],
    name: "deposit",
    outputs: []
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "role", internalType: "bytes32", type: "bytes32" }],
    name: "getRoleAdmin",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }]
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32" },
      { name: "account", internalType: "address", type: "address" }
    ],
    name: "grantRole",
    outputs: []
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32" },
      { name: "account", internalType: "address", type: "address" }
    ],
    name: "hasRole",
    outputs: [{ name: "", internalType: "bool", type: "bool" }]
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "maxDeposit",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }]
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "owner",
    outputs: [{ name: "", internalType: "address", type: "address" }]
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "paymentTokenAddress",
    outputs: [{ name: "", internalType: "address", type: "address" }]
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "payoutAddress",
    outputs: [{ name: "", internalType: "address", type: "address" }]
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "pendingOwner",
    outputs: [{ name: "", internalType: "address", type: "address" }]
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "clientId", internalType: "bytes32", type: "bytes32" },
      { name: "purchaseId", internalType: "bytes32", type: "bytes32" },
      { name: "amount", internalType: "uint256", type: "uint256" }
    ],
    name: "purchasePayout",
    outputs: []
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "accounts", internalType: "address[]", type: "address[]" }],
    name: "removePayoutRole",
    outputs: []
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "renounceOwnership",
    outputs: []
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32" },
      { name: "account", internalType: "address", type: "address" }
    ],
    name: "renounceRole",
    outputs: []
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32" },
      { name: "account", internalType: "address", type: "address" }
    ],
    name: "revokeRole",
    outputs: []
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "payoutAddress_", internalType: "address", type: "address" }],
    name: "setPayoutAddress",
    outputs: []
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "interfaceId", internalType: "bytes4", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ name: "", internalType: "bool", type: "bool" }]
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "newOwner", internalType: "address", type: "address" }],
    name: "transferOwnership",
    outputs: []
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "clientId", internalType: "bytes32", type: "bytes32" },
      { name: "amount", internalType: "uint256", type: "uint256" }
    ],
    name: "withdraw",
    outputs: []
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "clientId", internalType: "bytes32", type: "bytes32" },
      { name: "depositor", internalType: "address", type: "address" }
    ],
    name: "withdrawable",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }]
  }
];
var platformDepositAddress = {
  137: PlatformDepositDeployments[137].prd,
  80002: PlatformDepositDeployments[80002].dev
};
var platformDepositConfig = {
  address: platformDepositAddress,
  abi: platformDepositAbi
};
var erc20Abi2 = [
  {
    type: "event",
    inputs: [
      { name: "owner", type: "address", indexed: true },
      { name: "spender", type: "address", indexed: true },
      { name: "value", type: "uint256", indexed: false }
    ],
    name: "Approval"
  },
  {
    type: "event",
    inputs: [
      { name: "from", type: "address", indexed: true },
      { name: "to", type: "address", indexed: true },
      { name: "value", type: "uint256", indexed: false }
    ],
    name: "Transfer"
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "owner", type: "address" },
      { name: "spender", type: "address" }
    ],
    name: "allowance",
    outputs: [{ type: "uint256" }]
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "spender", type: "address" },
      { name: "amount", type: "uint256" }
    ],
    name: "approve",
    outputs: [{ type: "bool" }]
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ type: "uint256" }]
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "decimals",
    outputs: [{ type: "uint8" }]
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "name",
    outputs: [{ type: "string" }]
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "symbol",
    outputs: [{ type: "string" }]
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalSupply",
    outputs: [{ type: "uint256" }]
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "recipient", type: "address" },
      { name: "amount", type: "uint256" }
    ],
    name: "transfer",
    outputs: [{ type: "bool" }]
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "sender", type: "address" },
      { name: "recipient", type: "address" },
      { name: "amount", type: "uint256" }
    ],
    name: "transferFrom",
    outputs: [{ type: "bool" }]
  }
];
var readPlatformDeposit = /* @__PURE__ */ createReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress
});
var readPlatformDepositDefaultAdminRole = /* @__PURE__ */ createReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "DEFAULT_ADMIN_ROLE"
});
var readPlatformDepositPayoutExecutor = /* @__PURE__ */ createReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "PAYOUT_EXECUTOR"
});
var readPlatformDepositBalanceOf = /* @__PURE__ */ createReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "balanceOf"
});
var readPlatformDepositGetRoleAdmin = /* @__PURE__ */ createReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "getRoleAdmin"
});
var readPlatformDepositHasRole = /* @__PURE__ */ createReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "hasRole"
});
var readPlatformDepositMaxDeposit = /* @__PURE__ */ createReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "maxDeposit"
});
var readPlatformDepositOwner = /* @__PURE__ */ createReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "owner"
});
var readPlatformDepositPaymentTokenAddress = /* @__PURE__ */ createReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "paymentTokenAddress"
});
var readPlatformDepositPayoutAddress = /* @__PURE__ */ createReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "payoutAddress"
});
var readPlatformDepositPendingOwner = /* @__PURE__ */ createReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "pendingOwner"
});
var readPlatformDepositSupportsInterface = /* @__PURE__ */ createReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "supportsInterface"
});
var readPlatformDepositWithdrawable = /* @__PURE__ */ createReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "withdrawable"
});
var writePlatformDeposit = /* @__PURE__ */ createWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress
});
var writePlatformDepositAcceptOwnership = /* @__PURE__ */ createWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "acceptOwnership"
});
var writePlatformDepositAddPayoutRole = /* @__PURE__ */ createWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "addPayoutRole"
});
var writePlatformDepositDeposit = /* @__PURE__ */ createWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "deposit"
});
var writePlatformDepositGrantRole = /* @__PURE__ */ createWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "grantRole"
});
var writePlatformDepositPurchasePayout = /* @__PURE__ */ createWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "purchasePayout"
});
var writePlatformDepositRemovePayoutRole = /* @__PURE__ */ createWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "removePayoutRole"
});
var writePlatformDepositRenounceOwnership = /* @__PURE__ */ createWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "renounceOwnership"
});
var writePlatformDepositRenounceRole = /* @__PURE__ */ createWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "renounceRole"
});
var writePlatformDepositRevokeRole = /* @__PURE__ */ createWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "revokeRole"
});
var writePlatformDepositSetPayoutAddress = /* @__PURE__ */ createWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "setPayoutAddress"
});
var writePlatformDepositTransferOwnership = /* @__PURE__ */ createWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "transferOwnership"
});
var writePlatformDepositWithdraw = /* @__PURE__ */ createWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "withdraw"
});
var prepareWritePlatformDeposit = /* @__PURE__ */ createSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress
});
var prepareWritePlatformDepositAcceptOwnership = /* @__PURE__ */ createSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "acceptOwnership"
});
var prepareWritePlatformDepositAddPayoutRole = /* @__PURE__ */ createSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "addPayoutRole"
});
var prepareWritePlatformDepositDeposit = /* @__PURE__ */ createSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "deposit"
});
var prepareWritePlatformDepositGrantRole = /* @__PURE__ */ createSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "grantRole"
});
var prepareWritePlatformDepositPurchasePayout = /* @__PURE__ */ createSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "purchasePayout"
});
var prepareWritePlatformDepositRemovePayoutRole = /* @__PURE__ */ createSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "removePayoutRole"
});
var prepareWritePlatformDepositRenounceOwnership = /* @__PURE__ */ createSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "renounceOwnership"
});
var prepareWritePlatformDepositRenounceRole = /* @__PURE__ */ createSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "renounceRole"
});
var prepareWritePlatformDepositRevokeRole = /* @__PURE__ */ createSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "revokeRole"
});
var prepareWritePlatformDepositSetPayoutAddress = /* @__PURE__ */ createSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "setPayoutAddress"
});
var prepareWritePlatformDepositTransferOwnership = /* @__PURE__ */ createSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "transferOwnership"
});
var prepareWritePlatformDepositWithdraw = /* @__PURE__ */ createSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "withdraw"
});
var watchPlatformDepositEvent = /* @__PURE__ */ createWatchContractEvent({
  abi: platformDepositAbi,
  address: platformDepositAddress
});
var watchPlatformDepositDepositEvent = /* @__PURE__ */ createWatchContractEvent({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  eventName: "Deposit"
});
var watchPlatformDepositOwnershipTransferStartedEvent = /* @__PURE__ */ createWatchContractEvent({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  eventName: "OwnershipTransferStarted"
});
var watchPlatformDepositOwnershipTransferredEvent = /* @__PURE__ */ createWatchContractEvent({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  eventName: "OwnershipTransferred"
});
var watchPlatformDepositPaymentTransferEvent = /* @__PURE__ */ createWatchContractEvent({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  eventName: "PaymentTransfer"
});
var watchPlatformDepositRoleAdminChangedEvent = /* @__PURE__ */ createWatchContractEvent({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  eventName: "RoleAdminChanged"
});
var watchPlatformDepositRoleGrantedEvent = /* @__PURE__ */ createWatchContractEvent({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  eventName: "RoleGranted"
});
var watchPlatformDepositRoleRevokedEvent = /* @__PURE__ */ createWatchContractEvent({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  eventName: "RoleRevoked"
});
var watchPlatformDepositWithdrawEvent = /* @__PURE__ */ createWatchContractEvent({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  eventName: "Withdraw"
});
var readErc20 = /* @__PURE__ */ createReadContract({
  abi: erc20Abi2
});
var readErc20Allowance = /* @__PURE__ */ createReadContract({
  abi: erc20Abi2,
  functionName: "allowance"
});
var readErc20BalanceOf = /* @__PURE__ */ createReadContract({
  abi: erc20Abi2,
  functionName: "balanceOf"
});
var readErc20Decimals = /* @__PURE__ */ createReadContract({
  abi: erc20Abi2,
  functionName: "decimals"
});
var readErc20Name = /* @__PURE__ */ createReadContract({
  abi: erc20Abi2,
  functionName: "name"
});
var readErc20Symbol = /* @__PURE__ */ createReadContract({
  abi: erc20Abi2,
  functionName: "symbol"
});
var readErc20TotalSupply = /* @__PURE__ */ createReadContract({
  abi: erc20Abi2,
  functionName: "totalSupply"
});
var writeErc20 = /* @__PURE__ */ createWriteContract({ abi: erc20Abi2 });
var writeErc20Approve = /* @__PURE__ */ createWriteContract({
  abi: erc20Abi2,
  functionName: "approve"
});
var writeErc20Transfer = /* @__PURE__ */ createWriteContract({
  abi: erc20Abi2,
  functionName: "transfer"
});
var writeErc20TransferFrom = /* @__PURE__ */ createWriteContract({
  abi: erc20Abi2,
  functionName: "transferFrom"
});
var prepareWriteErc20 = /* @__PURE__ */ createSimulateContract({ abi: erc20Abi2 });
var prepareWriteErc20Approve = /* @__PURE__ */ createSimulateContract({
  abi: erc20Abi2,
  functionName: "approve"
});
var prepareWriteErc20Transfer = /* @__PURE__ */ createSimulateContract({
  abi: erc20Abi2,
  functionName: "transfer"
});
var prepareWriteErc20TransferFrom = /* @__PURE__ */ createSimulateContract({
  abi: erc20Abi2,
  functionName: "transferFrom"
});
var watchErc20Event = /* @__PURE__ */ createWatchContractEvent({ abi: erc20Abi2 });
var watchErc20ApprovalEvent = /* @__PURE__ */ createWatchContractEvent({
  abi: erc20Abi2,
  eventName: "Approval"
});
var watchErc20TransferEvent = /* @__PURE__ */ createWatchContractEvent({
  abi: erc20Abi2,
  eventName: "Transfer"
});
var usePlatformDepositRead = /* @__PURE__ */ createUseReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress
});
var usePlatformDepositDefaultAdminRole = /* @__PURE__ */ createUseReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "DEFAULT_ADMIN_ROLE"
});
var usePlatformDepositPayoutExecutor = /* @__PURE__ */ createUseReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "PAYOUT_EXECUTOR"
});
var usePlatformDepositBalanceOf = /* @__PURE__ */ createUseReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "balanceOf"
});
var usePlatformDepositGetRoleAdmin = /* @__PURE__ */ createUseReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "getRoleAdmin"
});
var usePlatformDepositHasRole = /* @__PURE__ */ createUseReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "hasRole"
});
var usePlatformDepositMaxDeposit = /* @__PURE__ */ createUseReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "maxDeposit"
});
var usePlatformDepositOwner = /* @__PURE__ */ createUseReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "owner"
});
var usePlatformDepositPaymentTokenAddress = /* @__PURE__ */ createUseReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "paymentTokenAddress"
});
var usePlatformDepositPayoutAddress = /* @__PURE__ */ createUseReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "payoutAddress"
});
var usePlatformDepositPendingOwner = /* @__PURE__ */ createUseReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "pendingOwner"
});
var usePlatformDepositSupportsInterface = /* @__PURE__ */ createUseReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "supportsInterface"
});
var usePlatformDepositWithdrawable = /* @__PURE__ */ createUseReadContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "withdrawable"
});
var usePlatformDepositWrite = /* @__PURE__ */ createUseWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress
});
var usePlatformDepositAcceptOwnership = /* @__PURE__ */ createUseWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "acceptOwnership"
});
var usePlatformDepositAddPayoutRole = /* @__PURE__ */ createUseWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "addPayoutRole"
});
var usePlatformDepositDeposit = /* @__PURE__ */ createUseWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "deposit"
});
var usePlatformDepositGrantRole = /* @__PURE__ */ createUseWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "grantRole"
});
var usePlatformDepositPurchasePayout = /* @__PURE__ */ createUseWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "purchasePayout"
});
var usePlatformDepositRemovePayoutRole = /* @__PURE__ */ createUseWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "removePayoutRole"
});
var usePlatformDepositRenounceOwnership = /* @__PURE__ */ createUseWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "renounceOwnership"
});
var usePlatformDepositRenounceRole = /* @__PURE__ */ createUseWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "renounceRole"
});
var usePlatformDepositRevokeRole = /* @__PURE__ */ createUseWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "revokeRole"
});
var usePlatformDepositSetPayoutAddress = /* @__PURE__ */ createUseWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "setPayoutAddress"
});
var usePlatformDepositTransferOwnership = /* @__PURE__ */ createUseWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "transferOwnership"
});
var usePlatformDepositWithdraw = /* @__PURE__ */ createUseWriteContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "withdraw"
});
var usePreparePlatformDepositAcceptOwnership = /* @__PURE__ */ createUseSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "acceptOwnership"
});
var usePreparePlatformDepositAddPayoutRole = /* @__PURE__ */ createUseSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "addPayoutRole"
});
var usePreparePlatformDepositDeposit = /* @__PURE__ */ createUseSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "deposit"
});
var usePreparePlatformDepositGrantRole = /* @__PURE__ */ createUseSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "grantRole"
});
var usePreparePlatformDepositPurchasePayout = /* @__PURE__ */ createUseSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "purchasePayout"
});
var usePreparePlatformDepositRemovePayoutRole = /* @__PURE__ */ createUseSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "removePayoutRole"
});
var usePreparePlatformDepositRenounceOwnership = /* @__PURE__ */ createUseSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "renounceOwnership"
});
var usePreparePlatformDepositRenounceRole = /* @__PURE__ */ createUseSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "renounceRole"
});
var usePreparePlatformDepositRevokeRole = /* @__PURE__ */ createUseSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "revokeRole"
});
var usePreparePlatformDepositSetPayoutAddress = /* @__PURE__ */ createUseSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "setPayoutAddress"
});
var usePreparePlatformDepositTransferOwnership = /* @__PURE__ */ createUseSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "transferOwnership"
});
var usePreparePlatformDepositWithdraw = /* @__PURE__ */ createUseSimulateContract({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  functionName: "withdraw"
});
var usePlatformDepositEvent = /* @__PURE__ */ createUseWatchContractEvent({
  abi: platformDepositAbi,
  address: platformDepositAddress
});
var usePlatformDepositDepositEvent = /* @__PURE__ */ createUseWatchContractEvent({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  eventName: "Deposit"
});
var usePlatformDepositOwnershipTransferStartedEvent = /* @__PURE__ */ createUseWatchContractEvent({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  eventName: "OwnershipTransferStarted"
});
var usePlatformDepositOwnershipTransferredEvent = /* @__PURE__ */ createUseWatchContractEvent({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  eventName: "OwnershipTransferred"
});
var usePlatformDepositPaymentTransferEvent = /* @__PURE__ */ createUseWatchContractEvent({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  eventName: "PaymentTransfer"
});
var usePlatformDepositRoleAdminChangedEvent = /* @__PURE__ */ createUseWatchContractEvent({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  eventName: "RoleAdminChanged"
});
var usePlatformDepositRoleGrantedEvent = /* @__PURE__ */ createUseWatchContractEvent({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  eventName: "RoleGranted"
});
var usePlatformDepositRoleRevokedEvent = /* @__PURE__ */ createUseWatchContractEvent({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  eventName: "RoleRevoked"
});
var usePlatformDepositWithdrawEvent = /* @__PURE__ */ createUseWatchContractEvent({
  abi: platformDepositAbi,
  address: platformDepositAddress,
  eventName: "Withdraw"
});
var useErc20Read = /* @__PURE__ */ createUseReadContract({ abi: erc20Abi2 });
var useErc20Allowance = /* @__PURE__ */ createUseReadContract({
  abi: erc20Abi2,
  functionName: "allowance"
});
var useErc20BalanceOf = /* @__PURE__ */ createUseReadContract({
  abi: erc20Abi2,
  functionName: "balanceOf"
});
var useErc20Decimals = /* @__PURE__ */ createUseReadContract({
  abi: erc20Abi2,
  functionName: "decimals"
});
var useErc20Name = /* @__PURE__ */ createUseReadContract({
  abi: erc20Abi2,
  functionName: "name"
});
var useErc20Symbol = /* @__PURE__ */ createUseReadContract({
  abi: erc20Abi2,
  functionName: "symbol"
});
var useErc20TotalSupply = /* @__PURE__ */ createUseReadContract({
  abi: erc20Abi2,
  functionName: "totalSupply"
});
var useErc20Write = /* @__PURE__ */ createUseWriteContract({ abi: erc20Abi2 });
var useErc20Approve = /* @__PURE__ */ createUseWriteContract({
  abi: erc20Abi2,
  functionName: "approve"
});
var useErc20Transfer = /* @__PURE__ */ createUseWriteContract({
  abi: erc20Abi2,
  functionName: "transfer"
});
var useErc20TransferFrom = /* @__PURE__ */ createUseWriteContract({
  abi: erc20Abi2,
  functionName: "transferFrom"
});
var usePrepareErc20Approve = /* @__PURE__ */ createUseSimulateContract({
  abi: erc20Abi2,
  functionName: "approve"
});
var usePrepareErc20Transfer = /* @__PURE__ */ createUseSimulateContract({
  abi: erc20Abi2,
  functionName: "transfer"
});
var usePrepareErc20TransferFrom = /* @__PURE__ */ createUseSimulateContract({
  abi: erc20Abi2,
  functionName: "transferFrom"
});
var useErc20Event = /* @__PURE__ */ createUseWatchContractEvent({ abi: erc20Abi2 });
var useErc20ApprovalEvent = /* @__PURE__ */ createUseWatchContractEvent({
  abi: erc20Abi2,
  eventName: "Approval"
});
var useErc20TransferEvent = /* @__PURE__ */ createUseWatchContractEvent({
  abi: erc20Abi2,
  eventName: "Transfer"
});

// src/components/DepositCrypto/hooks/useContractInteraction.tsx
function useContractInteraction(contractArgs, useContractHook, options = {}) {
  const [txHash, setTxHash] = useState2(null);
  const { onStart, onSubmit, onError, onFinally } = options;
  const {
    writeContract = void 0,
    writeContractAsync = void 0,
    status: writeStatus
  } = useContractHook();
  const { data: paymentTokenAddress } = usePlatformDepositPaymentTokenAddress();
  const { chainId } = useAccount2();
  const handleSubmit = useCallback2(async () => {
    if (writeContractAsync) {
      try {
        if (onStart) {
          await onStart();
        }
        const tx = await writeContractAsync({
          address: paymentTokenAddress,
          chainId,
          args: [contractArgs[0], contractArgs[1]]
        });
        setTxHash(tx);
        if (onSubmit && tx) {
          await onSubmit(tx);
        }
      } catch (error) {
        console.error(error);
        if (onError) {
          onError(error);
        }
      } finally {
        if (onFinally) {
          onFinally();
        }
      }
    }
  }, [writeContractAsync, onStart, contractArgs, onSubmit, onError, onFinally]);
  const { data: txReceipt } = useWaitForTransactionReceipt({
    hash: txHash
  });
  useEffect4(() => {
    if (txReceipt && options?.onReceipt) {
      options?.onReceipt(txReceipt);
    }
  }, [txReceipt]);
  return {
    writeContract,
    writeContractAsync,
    handleSubmit,
    writeStatus
  };
}

// src/utils/helpers.ts
import { ethers } from "ethers";
import { parse } from "uuid";
function addGasMargin(value) {
  return value * (10000n + 2000n) / 10000n;
}
function uuidToBytes32(uuid) {
  return ethers.zeroPadValue(parse(uuid), 32);
}
function decimalStringToUSDC(fiatAmount) {
  return ethers.parseUnits(fiatAmount, 6);
}
function usdcToDecimalString(usdcAmount) {
  return ethers.formatUnits(usdcAmount, 6);
}

// src/components/DepositCrypto/hooks/useTokenAllowance.ts
function useTokenAllowance(amount, network, tokenAddress, options = {}, spender = "0x0") {
  const { handlePrepareError, disabled } = options;
  const [prepareError, setPrepareError] = useState3(false);
  const [watchAllowance, setWatchAllowance] = useState3(false);
  const { address } = useAccount3();
  const {
    data,
    isLoading,
    status,
    refetch,
    isError: isAllowanceError,
    error: allowanceError
  } = useErc20Allowance({
    address: tokenAddress,
    args: [address, spender],
    chainId: network,
    query: {
      enabled: Boolean(tokenAddress)
    }
    // cacheOnBlock: true,  // not sure what this is now after wagmi v2...
  });
  useEffect5(() => {
    if (isAllowanceError) {
      if (handlePrepareError) {
        handlePrepareError(allowanceError);
      } else {
        setPrepareError(true);
      }
    }
  }, [isAllowanceError, handlePrepareError, allowanceError]);
  useEffect5(() => {
    if (watchAllowance) {
      setTimeout(() => {
        refetch();
      }, 3e4);
    }
  }, [refetch, watchAllowance]);
  const isApproved = useMemo(() => {
    if (!tokenAddress)
      return false;
    if (status !== "success")
      return false;
    if (!data)
      return false;
    return decimalStringToUSDC(amount.toString()) <= data;
  }, [amount, status, data, tokenAddress]);
  const {
    isLoading: prepareLoading,
    error,
    isError,
    isSuccess
  } = usePrepareErc20Approve({
    address: tokenAddress,
    chainId: network,
    args: [spender, decimalStringToUSDC(amount.toString())],
    query: {
      enabled: !disabled
    }
  });
  useEffect5(() => {
    if (isError) {
      if (disabled) {
        return;
      }
      if (handlePrepareError) {
        handlePrepareError(error);
      } else {
        setPrepareError(true);
      }
    } else if (isSuccess) {
      setPrepareError(false);
    }
  }, [isError, disabled, handlePrepareError, error, isSuccess]);
  const contractArgs = [spender, decimalStringToUSDC(amount.toString())];
  const { handleSubmit, writeContract, writeContractAsync, writeStatus } = useContractInteraction(
    contractArgs,
    useErc20Approve,
    {
      ...options,
      onStart: () => {
        setWatchAllowance(true);
        if (options.onStart)
          options.onStart();
      },
      onError: (err) => {
        setWatchAllowance(false);
        if (options.onError)
          options.onError(err);
      }
    }
  );
  useEffect5(() => {
    if (isApproved) {
      setWatchAllowance(false);
      options?.onAllowanceComplete?.();
    }
  }, [isApproved]);
  return {
    data,
    isApproved,
    status,
    isLoading,
    prepareLoading,
    writeContract,
    writeStatus,
    writeContractAsync,
    prepareError,
    watchAllowance,
    setWatchAllowance,
    handleSubmit
  };
}

// src/components/DepositCrypto/hooks/useDepositCrypto.tsx
import { useEffect as useEffect6, useState as useState4 } from "react";
function useDepositCrypto(amount, orgId, network, options = {}) {
  const { handlePrepareError, disabled } = options;
  const [prepareError, setPrepareError] = useState4(false);
  const {
    isLoading: prepareLoading,
    isError,
    error,
    isSuccess
  } = usePreparePlatformDepositDeposit({
    chainId: network,
    args: [uuidToBytes32(orgId), decimalStringToUSDC(amount.toString())],
    query: {
      enabled: !disabled
    }
  });
  useEffect6(() => {
    if (isError) {
      if (disabled) {
        return;
      }
      if (handlePrepareError) {
        handlePrepareError(error);
      } else {
        setPrepareError(true);
      }
    } else if (isSuccess) {
      setPrepareError(false);
    }
  }, [isError, disabled, handlePrepareError, error, isSuccess]);
  const contractArgs = [
    uuidToBytes32(orgId),
    decimalStringToUSDC(amount.toString())
  ];
  const { handleSubmit, writeContract, writeContractAsync, writeStatus } = useContractInteraction(
    contractArgs,
    usePlatformDepositDeposit,
    {
      ...options,
      onReceipt: (receipt) => {
        if (options.onReceipt) {
          options.onReceipt(receipt);
        }
        if (options.onComplete) {
          options.onComplete();
        }
      }
    }
  );
  return {
    prepareLoading,
    writeContract,
    writeStatus,
    writeContractAsync,
    prepareError,
    handleSubmit
  };
}

// src/hooks/useDefaultPaymentMethod.tsx
import { useAuth as useAuth2 } from "common-ui";
import { useCallback as useCallback3 } from "react";
var useDefaultPaymentMethod = () => {
  const { platform } = useAuth2();
  const defaultPaymentMethod = platform?.org?.default_payment_method;
  const setDefaultPaymentMethod2 = useCallback3(async () => {
    console.log("Multiple payment methods are allowed, skipping set default");
    return;
  }, []);
  return { defaultPaymentMethod, setDefaultPaymentMethod: setDefaultPaymentMethod2 };
};
var useDefaultPaymentMethod_default = useDefaultPaymentMethod;

// src/deployments/index.ts
function getContractDeployments(contract) {
  switch (contract) {
    case "PlatformDeposit":
      return PlatformDepositDeployments;
    default:
      return null;
  }
}
function getContractAddress(contract, network, environment = "dev") {
  const contractDeployments = getContractDeployments(contract);
  return contractDeployments ? contractDeployments[network][environment] : void 0;
}
function getContractNetworks(contract) {
  const contractDeployments = getContractDeployments(contract);
  return contractDeployments ? Object.keys(contractDeployments) : [];
}

// src/components/DepositCrypto/DepositCrypto.tsx
import { jsx as jsx10, jsxs as jsxs9 } from "react/jsx-runtime";
var reducer = (state2, action) => {
  switch (action.type) {
    case "TX_SUBMITTING":
      return {
        tx: {
          ...action.payload
        },
        step: state2.step === "approve" ? "approving" : "depositing"
      };
    case "APPROVAL_COMPLETE":
      return {
        step: "deposit"
      };
    case "DEPOSIT_COMPLETE":
      return {
        step: "success"
      };
    default:
      return state2;
  }
};
var DepositCrypto = ({
  amount,
  orgId,
  disabled,
  onStepChange
}) => {
  const theme = useTheme();
  const isMobile = useMobileEnabled3();
  const [state2, dispatch] = useReducer(reducer, {
    step: "approve"
  });
  const { paymentsNetworkId, environment } = useConfig();
  const network = paymentsNetworkId;
  const invalidate = useInvalidateResourceQuery();
  const { setDefaultPaymentMethod: setDefaultPaymentMethod2 } = useDefaultPaymentMethod();
  const pluginRegistry = usePluginRegistry2();
  const handleError = useCallback4(
    (error) => {
      if (disabled) {
        return;
      }
      pluginRegistry.events.publish(
        new NotificationEvent({
          message: error,
          options: {
            type: "error"
          }
        })
      );
    },
    [pluginRegistry.events, disabled]
  );
  const { data: paymentTokenAddress } = usePlatformDepositPaymentTokenAddress();
  const {
    isApproved,
    writeContract: approve,
    writeStatus: approveWriteStatus,
    handleSubmit: handleApprove,
    prepareError: approvePrepareError,
    prepareLoading: approvePrepareLoading
  } = useTokenAllowance(
    amount,
    network,
    paymentTokenAddress,
    {
      disabled,
      onSubmit: (txHash) => dispatch({ type: "TX_SUBMITTING", payload: { hash: txHash } }),
      onError: (err) => handleError(err),
      onAllowanceComplete: () => dispatch({ type: "APPROVAL_COMPLETE" })
    },
    getContractAddress("PlatformDeposit", network, environment)
  );
  const {
    writeContract: deposit,
    writeStatus: depositWriteStatus,
    handleSubmit: handleDeposit,
    prepareError: depositPrepareError,
    prepareLoading: depositPrepareLoading
  } = useDepositCrypto(amount, orgId, network, {
    disabled: disabled || !isApproved,
    onStart: async () => {
      await setDefaultPaymentMethod2("CRYPTO");
    },
    onSubmit: (txHash) => {
      enableBalancePolling();
      dispatch({ type: "TX_SUBMITTING", payload: { hash: txHash } });
    },
    onError: (err) => {
      handleError(err);
    },
    onComplete: () => {
      invalidate("CREATOR_BALANCE");
      dispatch({ type: "DEPOSIT_COMPLETE" });
    }
  });
  useEffect7(() => {
    if (!onStepChange)
      return;
    onStepChange(state2.step, state2.tx?.hash);
  }, [state2.step, onStepChange, state2.tx?.hash]);
  useEffect7(() => {
    if (state2.step === "success") {
      return setPageState("success");
    }
  }, [state2]);
  const formatEthAddress = useCallback4(() => {
    return "";
  }, []);
  useEffect7(() => {
    if (approveWriteStatus === "loading" || depositWriteStatus === "loading") {
      pluginRegistry.events.publish(
        new NotificationEvent({
          message: "Please confirm the suggested transaction in your wallet",
          options: {
            toastId: "creator-fund-crypto-tx-confirm-toast",
            type: "info"
          }
        })
      );
    } else if (approveWriteStatus === "error" || depositWriteStatus === "error") {
      pluginRegistry.events.publish(
        new NotificationEvent({
          message: "The transaction we suggested was rejected. Please try again and confirm the suggested transaction in your wallet",
          options: {
            toastId: "creator-fund-crypto-tx-rejected-toast",
            type: "error"
          }
        })
      );
    }
  }, [approveWriteStatus, depositWriteStatus, pluginRegistry]);
  if (Boolean(state2.tx?.hash) || approveWriteStatus === "loading" || depositWriteStatus === "loading") {
    return /* @__PURE__ */ jsxs9(Box3, { sx: { display: "flex", alignItems: "center", position: "relative", width: "50%" }, children: [
      Boolean(state2.tx?.hash) && /* @__PURE__ */ jsx10(
        Box3,
        {
          sx: {
            position: "absolute",
            top: "-3px",
            left: "-3px",
            zIndex: 1,
            borderRadius: 2,
            backgroundColor: theme.palette.secondary.light,
            display: "flex",
            justifyContent: "center",
            p: "2px",
            ".MuiSvgIcon-root": {
              fill: "white !important"
            }
          },
          children: /* @__PURE__ */ jsx10(
            EthereumAddress2,
            {
              type: "tx",
              value: state2.tx?.hash,
              networkId: paymentsNetworkId,
              showCopyIcon: true,
              showExplorerIcon: true,
              openExplorerOnClick: true,
              customFormat: formatEthAddress
            }
          )
        }
      ),
      approveWriteStatus === "loading" || depositWriteStatus === "loading" ? /* @__PURE__ */ jsx10(
        LoadingButton,
        {
          loadingText: isMobile ? "Loading..." : "Awaiting Transaction Signing",
          loading: true,
          sx: { flexGrow: 0, height: "48px", width: "100%" }
        }
      ) : /* @__PURE__ */ jsx10(
        LoadingButton,
        {
          loadingText: isMobile ? "Loading..." : state2.step === "approve" || state2.step === "approving" ? "Awaiting approval confirmation..." : state2.step === "deposit" || state2.step === "depositing" ? "Awaiting deposit confirmation..." : "Loading",
          loading: true,
          sx: { flexGrow: 0, height: "48px", width: "100%" }
        }
      )
    ] });
  }
  if (state2.step === "approve") {
    return /* @__PURE__ */ jsx10(
      Button4,
      {
        variant: "contained",
        sx: { p: 3, flexGrow: 0, height: "48px", width: "50%" },
        disabled: !approve || approvePrepareError || approvePrepareLoading || disabled,
        onClick: handleApprove,
        children: isMobile ? "Approve" : "Approve Spend"
      }
    );
  }
  if (state2.step === "deposit") {
    return /* @__PURE__ */ jsx10(
      Button4,
      {
        variant: "contained",
        sx: { p: 3, flexGrow: 0, height: "48px", width: "50%" },
        disabled: !deposit || depositPrepareError || depositPrepareLoading || disabled,
        onClick: handleDeposit,
        children: isMobile ? "Pay" : "Confirm & Pay"
      }
    );
  }
  return null;
};
var DepositCrypto_default = DepositCrypto;

// src/components/DepositCrypto/DepositCryptoForm.tsx
import { jsx as jsx11, jsxs as jsxs10 } from "react/jsx-runtime";
var SUBSTEP_APPROVE = 0;
var SUBSTEP_DEPOSIT = 1;
function DepositCryptoForm() {
  const pluginRegistry = usePluginRegistry3();
  const [activeSubstep, setActiveSubStep] = useState5({ value: SUBSTEP_APPROVE });
  const { platform } = useAuth3();
  const orgId = platform?.org?.id;
  const { amount } = useSnapshot4(state_default);
  const [errorMessage, setErrorMessage] = useState5(null);
  const { address, chain } = useAccount4();
  const usdcData = useUsdcContractReads(address, chain?.id);
  const [hasEnoughUsdc, setHasEnoughUsdc] = useState5(true);
  useEffect8(() => {
    if (!amount) {
      setPageState("select-amount", "select-payment-method");
    }
    if ((amount ?? 0) < MIN_CRYPTO_DEPOSIT) {
      setErrorMessage(`Deposit amount must be at least $${MIN_CRYPTO_DEPOSIT}`);
    } else if (errorMessage) {
      setErrorMessage(null);
    }
  }, [amount, errorMessage]);
  useEffect8(() => {
    const usdcConvertedBalance = Number(usdcData?.balanceOf) / 10 ** (usdcData?.decimals ?? 1);
    if (address && chain) {
      if (usdcConvertedBalance < 1) {
        setHasEnoughUsdc(false);
        setErrorMessage(
          `You do not currently have enough ${usdcData?.symbol} on ${chain?.name}. Please get some before continuing.`
        );
      } else if (amount && usdcConvertedBalance < amount) {
        setHasEnoughUsdc(false);
        setErrorMessage(`You don't have enough ${usdcData?.symbol} on ${chain?.name} to deposit`);
      } else if (amount && usdcConvertedBalance >= amount) {
        setHasEnoughUsdc(true);
        setErrorMessage(null);
      }
    }
  }, [address, amount, chain, chain?.name, errorMessage, usdcData]);
  const handleDepositCryptoStubstepChange = useCallback5(
    (step, txHash) => {
      if (step === "approve" || step === "approving")
        setActiveSubStep({
          value: SUBSTEP_APPROVE,
          txHash
        });
      if (step === "deposit" || step === "depositing") {
        setActiveSubStep((old) => {
          if (old?.value === SUBSTEP_APPROVE && Boolean(old?.txHash)) {
            pluginRegistry.events.publish(
              new NotificationEvent2({
                message: "Received confirmation that spend approval was successful.",
                options: {
                  type: "success"
                }
              })
            );
          }
          return {
            value: SUBSTEP_DEPOSIT,
            txHash
          };
        });
      }
    },
    [pluginRegistry.events]
  );
  useEffect8(() => {
    if (Boolean(activeSubstep?.txHash) || activeSubstep?.value === SUBSTEP_DEPOSIT) {
      state_default.shouldntClose = true;
      state_default.shouldntCloseReason = "The spend/deposit process is still ongoing. We strongly suggest going through the entire process before closing the modal. Are you sure you want to close this modal?";
    } else {
      state_default.shouldntClose = void 0;
      state_default.shouldntCloseReason = void 0;
    }
  }, [activeSubstep]);
  return /* @__PURE__ */ jsxs10(Stack8, { sx: { marginTop: "30px" }, spacing: "30px", children: [
    /* @__PURE__ */ jsxs10(Stepper, { activeStep: activeSubstep.value, alternativeLabel: true, children: [
      /* @__PURE__ */ jsx11(Step, { children: activeSubstep.value === SUBSTEP_APPROVE && Boolean(activeSubstep.txHash) ? /* @__PURE__ */ jsx11(StepLabel, { children: `Approving Spend For ${amount} Polygon USDC` }) : /* @__PURE__ */ jsx11(StepLabel, { children: `Approve Spend For ${amount} Polygon USDC` }) }, SUBSTEP_APPROVE),
      /* @__PURE__ */ jsx11(Step, { children: activeSubstep.value === SUBSTEP_DEPOSIT && Boolean(activeSubstep.txHash) ? /* @__PURE__ */ jsx11(
        StepLabel,
        {
          icon: /* @__PURE__ */ jsx11(CircularProgress2, { size: 25 }),
          children: `Transfering ${amount} Polygon USDC To Phosphor Account Balance`
        }
      ) : /* @__PURE__ */ jsx11(StepLabel, { children: `Transfer ${amount} Polygon USDC To Phosphor Account Balance` }) }, SUBSTEP_DEPOSIT)
    ] }),
    /* @__PURE__ */ jsx11(ConnectedWallet, {}),
    Boolean(errorMessage) && /* @__PURE__ */ jsx11(FormHelperText, { error: true, children: errorMessage }),
    /* @__PURE__ */ jsx11(
      ActionButtons_default,
      {
        nextSlot: address && chain && orgId && amount ? /* @__PURE__ */ jsx11(
          DepositCrypto_default,
          {
            amount,
            disabled: Boolean(errorMessage) || !hasEnoughUsdc,
            orgId,
            onStepChange: handleDepositCryptoStubstepChange
          }
        ) : /* @__PURE__ */ jsx11(ConnectButton_default, { variant: "contained", sx: { flexGrow: 0, height: "48px", width: "50%" } }),
        onBack: () => {
          if (!Boolean(activeSubstep?.txHash)) {
            setPageState("select-amount", "select-payment-method");
          } else {
            pluginRegistry.events.publish(
              new NotificationEvent2({
                message: "You are currently in the process of approving or depositing funds. Please wait for the process to complete.",
                options: {
                  type: "info"
                }
              })
            );
          }
        }
      }
    ),
    Boolean(activeSubstep?.txHash) && /* @__PURE__ */ jsx11(Alert, { severity: "info", children: activeSubstep.value === SUBSTEP_APPROVE ? "Please wait for the spend approval to complete. This might take a while, depending on network activity. Please do not close this modal while we are awaiting confirmation." : "Please wait for the deposit to complete. This might take a while, depending on network activity. Please do not close this modal while we are awaiting confirmation." })
  ] });
}
var DepositCryptoForm_default = DepositCryptoForm;

// src/components/DepositFiat/StripeDepositForm.tsx
import { useEffect as useEffect10, useState as useState7 } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { useCreateStripePaymentIntent } from "common-api";
import { useSnapshot as useSnapshot5 } from "valtio";
import { SpinnerLoader, useAuth as useAuth4 } from "common-ui";
import TextField from "@mui/material/TextField";
import Stack10 from "@mui/material/Stack";

// src/components/DepositFiat/CheckoutForm.tsx
import { useEffect as useEffect9, useState as useState6 } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Stack9 from "@mui/material/Stack";
import { useInvalidateResourceQuery as useInvalidateResourceQuery2 } from "common-api";
import { Alert as Alert2 } from "@mui/material";
import { jsx as jsx12, jsxs as jsxs11 } from "react/jsx-runtime";
function CheckoutForm() {
  const invalidate = useInvalidateResourceQuery2();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentIntent, setPaymentIntent] = useState6();
  const [errorMessage, setErrorMessage] = useState6(null);
  const [submitting, setSubmitting] = useState6(false);
  useEffect9(() => {
    console.log("payment intent", paymentIntent);
  }, [paymentIntent]);
  const { setDefaultPaymentMethod: setDefaultPaymentMethod2 } = useDefaultPaymentMethod_default();
  const handleSubmit = async (e) => {
    if (!stripe || !elements) {
      return;
    }
    setSubmitting(true);
    try {
      await setDefaultPaymentMethod2("FIAT");
      const resp = await stripe.confirmPayment({
        elements,
        redirect: "if_required"
      });
      enableBalancePolling();
      if (resp?.error) {
        if (resp?.error?.type === "card_error" || resp?.error?.type === "validation_error") {
          setErrorMessage(resp?.error?.message ?? "");
        } else {
          setErrorMessage("An unexpected error occurred.");
        }
      }
      if (resp?.paymentIntent?.status === "succeeded") {
        await invalidate("CREATOR_BALANCE");
        return setPageState("success");
      }
      console.log("payment intent not completed yet");
      setPaymentIntent(resp?.paymentIntent);
    } catch (error) {
      if (error?.type === "card_error" || error?.type === "validation_error") {
        setErrorMessage(error?.message ?? "");
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    } finally {
      setSubmitting(false);
    }
  };
  return /* @__PURE__ */ jsxs11(Stack9, { spacing: "42px", children: [
    /* @__PURE__ */ jsx12(
      PaymentElement,
      {
        id: "payment-element",
        options: {
          layout: "tabs"
        }
      }
    ),
    errorMessage && /* @__PURE__ */ jsx12(Alert2, { severity: "error", children: errorMessage }),
    /* @__PURE__ */ jsx12(
      ActionButtons_default,
      {
        onNext: handleSubmit,
        onBack: () => setPageState("select-amount", "select-payment-method"),
        disableNextButton: submitting || !stripe || !elements
      }
    )
  ] });
}

// src/components/DepositFiat/StripeDepositForm.tsx
import { Fragment as Fragment2, jsx as jsx13, jsxs as jsxs12 } from "react/jsx-runtime";
function DepositFiat() {
  const [clientSecret, setClientSecret] = useState7("");
  const { mutateAsync, isPending } = useCreateStripePaymentIntent();
  const snap = useSnapshot5(state_default);
  const { platform } = useAuth4();
  const orgId = platform?.org?.id;
  useEffect10(() => {
    async function createPaymentIntent() {
      try {
        const resp = await mutateAsync({
          amount: `${snap.amount * 100}`,
          // amount in cents
          currency: "USD",
          organization_id: orgId
        });
        const clientSecret2 = resp?.data?.client_secret;
        setClientSecret(clientSecret2);
      } catch (e) {
        console.error(e);
      }
    }
    if (snap.amount && snap.amount >= MIN_FIAT_DEPOSIT) {
      createPaymentIntent();
    }
  }, [mutateAsync, snap.amount, orgId]);
  return /* @__PURE__ */ jsxs12(Stack10, { spacing: "42px", children: [
    /* @__PURE__ */ jsx13(
      TextField,
      {
        fullWidth: true,
        id: "amount",
        name: "amount",
        label: "Amount (USD)",
        type: "text",
        autoFocus: true,
        InputProps: { readOnly: true },
        value: snap.amount,
        disabled: true
      }
    ),
    isPending ? /* @__PURE__ */ jsx13(SpinnerLoader, {}) : /* @__PURE__ */ jsx13(Fragment2, { children: clientSecret && snap.stripePromise && /* @__PURE__ */ jsx13(
      Elements,
      {
        stripe: snap.stripePromise,
        options: {
          clientSecret,
          appearance: {
            theme: "night"
            // note: can customize via variables here
          },
          loader: "always"
        },
        children: /* @__PURE__ */ jsx13(CheckoutForm, {})
      }
    ) })
  ] });
}
var StripeDepositForm_default = DepositFiat;

// src/components/Deposit.tsx
import { jsx as jsx14 } from "react/jsx-runtime";
var Deposit = () => {
  const snap = useSnapshot6(state_default);
  const { balance } = useAuthAccountBalance();
  const setExpectedBalanceAfterDeposit = useCallback6(
    (amount) => {
      if (!balance)
        return;
      setExpectedBalance(Number(balance.total) + amount);
    },
    [balance]
  );
  useEffect11(() => {
    if (!snap.paymentMethod) {
      setPageState("select-payment-method", "balance");
      return;
    }
    if (!snap.paymentMethod && snap.defaultPaymentMethod) {
      setPaymentMethod(snap.defaultPaymentMethod);
      return;
    }
    if (!snap.paymentMethod && !snap.defaultPaymentMethod) {
      setPageState("select-payment-method", "balance");
      return;
    }
    if (snap.amount) {
      setAmount(
        Math.max(
          snap.paymentMethod === "CRYPTO" ? MIN_CRYPTO_DEPOSIT : MIN_FIAT_DEPOSIT,
          snap.amount ?? 0,
          snap.minPayment ?? 0
        )
      );
      setExpectedBalanceAfterDeposit(snap.amount);
    }
  }, [
    snap.paymentMethod,
    snap.defaultPaymentMethod,
    snap.amount,
    snap.minPayment,
    setExpectedBalanceAfterDeposit
  ]);
  switch (snap.paymentMethod) {
    case "CRYPTO":
      return /* @__PURE__ */ jsx14(DepositCryptoForm_default, {});
    case "FIAT":
      return /* @__PURE__ */ jsx14(StripeDepositForm_default, {});
    default:
      return null;
  }
};
var Deposit_default = Deposit;

// src/components/SelectAmount.tsx
import Stack11 from "@mui/material/Stack";
import TextField2 from "@mui/material/TextField";
import { useEffect as useEffect12, useMemo as useMemo2, useState as useState8 } from "react";
import { useSnapshot as useSnapshot7 } from "valtio";
import { Alert as Alert3 } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { jsx as jsx15, jsxs as jsxs13 } from "react/jsx-runtime";
function SelectAmount({ onCancel }) {
  const snap = useSnapshot7(state_default);
  const minAmount = useMemo2(
    () => snap.paymentMethod === "CRYPTO" ? MIN_CRYPTO_DEPOSIT : MIN_FIAT_DEPOSIT,
    [snap.paymentMethod]
  );
  const [fiatAmount, setFiatAmount] = useState8(
    state_default.amount ?? Math.max(state_default.minPayment ?? 0, minAmount)
  );
  const [errorMessage, setErrorMessage] = useState8(null);
  useEffect12(() => {
    if (fiatAmount && !snap.amount) {
      setAmount(fiatAmount);
    }
    if (fiatAmount < minAmount) {
      setErrorMessage(`Deposit amount must be at least $${minAmount}`);
    } else if (snap.minPayment && fiatAmount < snap.minPayment) {
      setErrorMessage(`The drop requires a payment of at least $${snap.minPayment}`);
    } else if (errorMessage) {
      setErrorMessage(null);
    }
  }, [fiatAmount, errorMessage, minAmount, snap.amount, snap.minPayment]);
  return /* @__PURE__ */ jsxs13(Stack11, { sx: { marginTop: "30px" }, spacing: "30px", children: [
    /* @__PURE__ */ jsx15(
      TextField2,
      {
        fullWidth: true,
        id: "amount",
        name: "amount",
        label: snap.paymentMethod === "FIAT" ? "Amount (USD)" : "Amount (Polygon USDC)",
        required: true,
        type: "text",
        autoFocus: true,
        InputProps: {
          inputProps: { inputMode: "numeric", pattern: "[0-9]*", min: minAmount }
        },
        value: fiatAmount,
        onChange: (e) => {
          let value = parseInt(e.target.value, 10) || 0;
          if (value < 0)
            value = 0;
          setFiatAmount(value);
          setAmount(value);
        },
        error: Boolean(errorMessage),
        helperText: errorMessage
      }
    ),
    snap.paymentMethod === "FIAT" && /* @__PURE__ */ jsxs13(
      Alert3,
      {
        severity: "info",
        iconMapping: {
          info: /* @__PURE__ */ jsx15(CreditCardIcon, {})
        },
        sx: { whiteSpace: "pre-line" },
        children: [
          "$",
          minAmount,
          " is the minimum amount for funding via credit card",
          snap.minPayment ? `
 $${snap.minPayment} is the minimum payment required for the drop` : ""
        ]
      }
    ),
    snap.paymentMethod === "CRYPTO" && /* @__PURE__ */ jsxs13(Alert3, { severity: "info", sx: { whiteSpace: "pre-line" }, children: [
      minAmount,
      " Polygon USDC is the minimum amount for funding via crypto",
      snap.minPayment ? `
 $${snap.minPayment} is the minimum payment required for the drop` : ""
    ] }),
    /* @__PURE__ */ jsx15(
      ActionButtons_default,
      {
        onNext: () => {
          setPageState("deposit", "select-amount");
        },
        onBack: () => setPageState("select-payment-method", "balance"),
        disableNextButton: Boolean(errorMessage)
      }
    )
  ] });
}
var SelectAmount_default = SelectAmount;

// src/events.ts
var PaymentsBalanceToppedUpEvent = class {
  constructor(data) {
    this.data = data;
    this.id = "payments.balance.topped-up";
  }
};

// src/components/MainModal.tsx
import { jsx as jsx16, jsxs as jsxs14 } from "react/jsx-runtime";
var TITLES = {
  balance: "Account Balance",
  "deposit-crypto": "Add Funds",
  "deposit-fiat": "Add Funds",
  success: "Confirmation",
  "select-payment-method": "Select Funding Method",
  deposit: "Add Funds",
  "select-amount": "Add Funds"
};
function MainModal() {
  const closeMainModal = useClosePaymentsModal();
  const pluginRegistry = usePluginRegistry4();
  const { paymentsNetworkId } = getConfig(pluginRegistry);
  const snap = useSnapshot8(state_default);
  const DESCRIPTIONS = {
    balance: "Your current balance used to cover drop costs.",
    "deposit-crypto": "Add funds to your Account Balance to cover drop costs.",
    "deposit-fiat": "Add funds to your Account Balance to cover drop costs.",
    success: snap.amount ? `Your payment of $${snap.amount} has been received. It may take a few minutes for your balance to update.` : "Your payment has been received. It may take a few minutes for your balance to update.",
    "select-payment-method": "Please select your preferred funding method, you will only have one opportunity to make this decision.",
    deposit: "Add funds to your Account Balance to cover drop costs.",
    "select-amount": "Add funds to your Account Balance to cover drop costs."
  };
  const getUSDCLink = () => {
    if (paymentsNetworkId === 137) {
      return "https://polygonscan.com/token/0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";
    } else {
      return "https://www.oklink.com/amoy/address/0x616553f076c6f66739a99fef373c6b4ae1b22a7a";
    }
  };
  const CAPTIONS = {
    usdcEduCopy: /* @__PURE__ */ jsxs14(Typography6, { variant: "caption", sx: { mt: -1 }, children: [
      "At the moment we only accept",
      " ",
      /* @__PURE__ */ jsx16(Link, { target: "_blank", href: getUSDCLink(), children: "USDC on the Polygon network" }),
      "."
    ] })
  };
  useEffect13(() => {
    if (snap?.activePage === "success" && Boolean(snap?.amount)) {
      pluginRegistry.events.publish(
        new PaymentsBalanceToppedUpEvent({
          amount: snap.amount,
          currency: "USD"
        })
      );
      pluginRegistry.events.publish(
        new NotificationEvent3({
          message: `Your payment of $${snap.amount} has been received. It may take a few minutes for your balance to update.`,
          options: {
            type: "success"
          }
        })
      );
    }
  }, [snap?.amount, snap?.activePage, pluginRegistry]);
  return /* @__PURE__ */ jsx16(
    SimpleModal,
    {
      open: snap.open,
      title: TITLES[snap.activePage],
      onClose: closeMainModal,
      description: DESCRIPTIONS[snap.activePage],
      caption: snap.paymentMethod === "CRYPTO" && snap.activePage !== "select-payment-method" && snap.activePage !== "success" ? CAPTIONS.usdcEduCopy : "",
      paperSxProps: {
        minHeight: "300px"
      },
      canCloseByClickingOutside: true,
      children: /* @__PURE__ */ jsxs14(ErrorBoundary, { fallback: /* @__PURE__ */ jsx16("div", { children: "Something went wrong" }), children: [
        snap.activePage === "balance" && /* @__PURE__ */ jsx16(AccountBalance_default, {}),
        snap.activePage === "select-payment-method" && /* @__PURE__ */ jsx16(PaymentMethod_default, {}),
        snap.activePage === "select-amount" && /* @__PURE__ */ jsx16(SelectAmount_default, {}),
        snap.activePage === "deposit" && /* @__PURE__ */ jsx16(Deposit_default, {}),
        snap.activePage === "success" && /* @__PURE__ */ jsx16(Success_default, {})
      ] })
    }
  );
}

// src/components/BalanceChip.tsx
import { Stack as Stack12, Tooltip } from "@mui/material";
import { useMobileEnabled as useMobileEnabled4 } from "common-ui";

// src/assets/icons/balanceIcon.svg
import * as React from "react";
import { jsx as jsx17 } from "react/jsx-runtime";
var SvgBalanceIcon = (props) => /* @__PURE__ */ jsx17("svg", { width: 20, height: 20, viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props, children: /* @__PURE__ */ jsx17("path", { d: "M9 3.66667V14.3333M6.33333 11.8283L7.11455 12.4142C8.15591 13.1953 9.84429 13.1953 10.8856 12.4142C11.927 11.6332 11.927 10.3668 10.8856 9.58579C10.3649 9.19522 9.6824 8.99996 8.9999 9C8.35552 9.00004 7.71129 8.8048 7.21964 8.41424C6.23644 7.63319 6.23644 6.36686 7.21964 5.58581C8.20284 4.80476 9.79692 4.80476 10.7801 5.58581L11.1489 5.87878M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z", stroke: "#27272A", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }) });
var balanceIcon_default = SvgBalanceIcon;

// src/components/BalanceChip.tsx
import { jsx as jsx18, jsxs as jsxs15 } from "react/jsx-runtime";
var BalanceChip = () => {
  const { status } = useAuthAccountBalance();
  const isMobile = useMobileEnabled4();
  return /* @__PURE__ */ jsx18(
    Stack12,
    {
      direction: "column",
      height: "50px",
      maxWidth: "200px",
      width: "fit-content",
      minWidth: "20px",
      justifyContent: "center",
      color: "text.primary",
      sx: {
        padding: "10px 16px",
        borderRadius: "8px",
        border: "1px solid",
        borderColor: (theme) => theme.palette.neutral_100,
        "&:hover": {
          cursor: "pointer",
          bgcolor: "shades.white_20"
        },
        overflow: "hidden"
      },
      onClick: () => openPaymentsModal(),
      "data-testid": "balance-chip",
      children: /* @__PURE__ */ jsx18(Tooltip, { title: "Add funds", children: /* @__PURE__ */ jsxs15(Stack12, { direction: "row", spacing: 1, alignItems: "center", children: [
        status && /* @__PURE__ */ jsx18(StatusIndicator, { status }),
        !isMobile && /* @__PURE__ */ jsx18(balanceIcon_default, {}),
        /* @__PURE__ */ jsx18(BalanceTotal_default, { variant: "body1" })
      ] }) })
    }
  );
};
var BalanceChip_default = BalanceChip;

// src/types/types.ts
import { polygonAmoy } from "wagmi/chains";

// src/assets/icons/polygon-matic-logo.svg
import * as React2 from "react";
import { jsx as jsx19, jsxs as jsxs16 } from "react/jsx-runtime";
var SvgPolygonMaticLogo = (props) => /* @__PURE__ */ jsxs16("svg", { id: "katman_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 800 600", style: {
  enableBackground: "new 0 0 800 600"
}, xmlSpace: "preserve", ...props, children: [
  /* @__PURE__ */ jsx19("style", { type: "text/css", children: "\r\n	.st0{fill:#8247E5;}\r\n	.st1{clip-path:url(#SVGID_00000170985874484226784740000010197121833159053193_);}\r\n	.st2{fill:#FFFFFF;}\r\n" }),
  /* @__PURE__ */ jsx19("path", { className: "st0", d: "M399.8,45.4L399.8,45.4C540.2,45.4,654,159.2,654,299.6l0,0c0,140.4-113.8,254.1-254.1,254.1l0,0 c-140.4,0-254.1-113.8-254.1-254.1l0,0C145.7,159.2,259.5,45.4,399.8,45.4z" }),
  /* @__PURE__ */ jsxs16("g", { children: [
    /* @__PURE__ */ jsx19("defs", { children: /* @__PURE__ */ jsx19("rect", { id: "SVGID_1_", x: 231.5, y: 155.3, width: 333.1, height: 291.9 }) }),
    /* @__PURE__ */ jsx19("clipPath", { id: "SVGID_00000119824040907289936360000005545950198423505064_", children: /* @__PURE__ */ jsx19("use", { xlinkHref: "#SVGID_1_", style: {
      overflow: "visible"
    } }) }),
    /* @__PURE__ */ jsx19("g", { style: {
      clipPath: "url(#SVGID_00000119824040907289936360000005545950198423505064_)"
    }, children: /* @__PURE__ */ jsx19("path", { className: "st2", d: "M483.1,244.2c-6.1-3.5-13.9-3.5-20.8,0L413.7,273l-33,18.3L333,320c-6.1,3.5-13.9,3.5-20.8,0l-37.3-22.7 c-6.1-3.5-10.4-10.5-10.4-18.3v-43.6c0-7,3.5-13.9,10.4-18.3l37.3-21.8c6.1-3.5,13.9-3.5,20.8,0l37.3,22.7 c6.1,3.5,10.4,10.5,10.4,18.3v28.8l33-19.2v-29.6c0-7-3.5-13.9-10.4-18.3l-69.4-41c-6.1-3.5-13.9-3.5-20.8,0L242,198.9 c-6.9,3.5-10.4,10.5-10.4,17.4v81.9c0,7,3.5,13.9,10.4,18.3l70.3,41c6.1,3.5,13.9,3.5,20.8,0l47.7-27.9l33-19.2l47.7-27.9 c6.1-3.5,13.9-3.5,20.8,0l37.3,21.8c6.1,3.5,10.4,10.5,10.4,18.3v43.6c0,7-3.5,13.9-10.4,18.3l-36.4,21.8 c-6.1,3.5-13.9,3.5-20.8,0L425,384.5c-6.1-3.5-10.4-10.5-10.4-18.3v-27.9l-33,19.2v28.8c0,7,3.5,13.9,10.4,18.3l70.3,41 c6.1,3.5,13.9,3.5,20.8,0l70.3-41c6.1-3.5,10.4-10.5,10.4-18.3v-82.8c0-7-3.5-13.9-10.4-18.3L483.1,244.2z" }) })
  ] })
] });
var polygon_matic_logo_default = SvgPolygonMaticLogo;

// src/types/types.ts
var customAmoyTestnet = {
  ...polygonAmoy,
  name: "Polygon Amoy",
  iconUrl: polygon_matic_logo_default
};

// src/components/DropCostEstimate/DropCostEstimate.tsx
import { Box as Box4, CircularProgress as CircularProgress3, Stack as Stack13, Typography as Typography7 } from "@mui/material";
import { useDropCostEstimate, useDropFlowCostEstimate } from "common-api";
import { StructuralSkeleton } from "common-ui";

// src/utils/estimationHelpers.ts
function getDistributionsTotal(dropCost) {
  const distributions = Object.keys(dropCost?.estimates?.distributions ?? {});
  if (distributions.length === 0) {
    return 0;
  }
  const distributionSubTotal = distributions.reduce((acc, distribution) => {
    return acc + Number(dropCost?.estimates?.distributions?.[distribution].sub_total);
  }, 0);
  return distributionSubTotal;
}
function getHasDeployCost(dropCost) {
  return Boolean(dropCost?.estimates?.deploy?.sub_total) && Number(dropCost?.sub_total ?? 0) > 0;
}
function getHasDistributionCosts(dropCost) {
  return getDistributionsTotal(dropCost) > 0;
}
function getDeployTotal(dropCost) {
  return Number(dropCost?.estimates?.deploy?.sub_total);
}
function formatPrice(price) {
  if (!price) {
    return "-";
  }
  return `$${price.toFixed(2)}`;
}
function getTotalDropCost(dropCost) {
  return Number(dropCost?.sub_total ?? 0);
}

// src/components/DropCostEstimate/DropCostEstimate.tsx
import { jsx as jsx20, jsxs as jsxs17 } from "react/jsx-runtime";
var DropCostEstimateBlock = ({
  dropId,
  dropFlowId,
  expanded,
  onlyTotal,
  sx
}) => {
  const { data: dropCostEstimate, isFetching: fetchingDropCostEstimate } = useDropCostEstimate(
    !Boolean(dropFlowId) ? dropId ?? "" : "",
    state_default.paymentMethod ?? DEFAULT_ESTIMATE_SALE_TYPE
  );
  const { data: flowCostEstimate, isFetching: fetchingFlowCostEstimate } = useDropFlowCostEstimate(
    dropId,
    Boolean(dropFlowId) ? dropFlowId ?? "" : "",
    state_default.paymentMethod ?? DEFAULT_ESTIMATE_SALE_TYPE
  );
  const isFetching = !Boolean(dropFlowId) ? fetchingDropCostEstimate : fetchingFlowCostEstimate;
  const data = !Boolean(dropFlowId) ? dropCostEstimate : flowCostEstimate;
  return /* @__PURE__ */ jsx20(
    DropCostEstimateView,
    {
      dropCost: data,
      expanded,
      onlyTotal,
      labels: {
        total: "Cost Estimate",
        deploy: void 0,
        distribution: void 0
      },
      isDataPending: isFetching
    }
  );
};
var EstimateRow = ({ children }) => {
  return /* @__PURE__ */ jsx20(Stack13, { direction: "row", justifyContent: "space-between", alignItems: "center", width: "100%", children });
};
var CollapsedUntilLoadedDetails = ({
  dropCost,
  labels,
  isDataPending
}) => {
  const distributionSubTotal = getDistributionsTotal(dropCost);
  const hasDeployCost = getHasDeployCost(dropCost);
  const hasDistributionCosts = getHasDistributionCosts(dropCost);
  return /* @__PURE__ */ jsxs17(
    Box4,
    {
      height: isDataPending ? 0 : hasDistributionCosts ? "40px" : hasDeployCost ? "20px" : 0,
      visibility: isDataPending ? "hidden" : "visible",
      sx: {
        transition: "height 350ms, visibility 3500ms 350ms, opacity 350ms 350ms",
        opacity: isDataPending ? 0 : 1
      },
      children: [
        hasDeployCost && /* @__PURE__ */ jsxs17(EstimateRow, { children: [
          /* @__PURE__ */ jsx20(Typography7, { variant: "body1", children: labels?.deploy ?? "Contract Deployment (Now)" }),
          /* @__PURE__ */ jsx20(Typography7, { variant: "body1", minWidth: "40px", textAlign: "end", children: formatPrice(getDeployTotal(dropCost)) })
        ] }),
        hasDistributionCosts && /* @__PURE__ */ jsxs17(EstimateRow, { children: [
          /* @__PURE__ */ jsx20(Typography7, { variant: "body1", children: labels?.distribution ?? "Distribution Coverage (Later)" }),
          /* @__PURE__ */ jsx20(Typography7, { variant: "body1", minWidth: "40px", textAlign: "end", children: formatPrice(distributionSubTotal) })
        ] })
      ]
    }
  );
};
var ExpandedDetails = ({ dropCost, labels, isDataPending }) => {
  const distributions = Object.keys(dropCost?.estimates?.distributions ?? {});
  const distributionSubTotal = distributions.reduce((acc, distribution) => {
    return acc + Number(dropCost?.estimates?.distributions?.[distribution].sub_total);
  }, 0);
  const hasDeployCost = getHasDeployCost(dropCost);
  const hasDistributionCosts = getHasDistributionCosts(dropCost);
  if (isDataPending)
    return /* @__PURE__ */ jsx20(Box4, { sx: { height: "45px", mt: 1 }, children: /* @__PURE__ */ jsx20(StructuralSkeleton, { columnDivisions: 2, rowDivisions: 2 }) });
  return /* @__PURE__ */ jsxs17(Stack13, { gap: "2px", children: [
    hasDeployCost && /* @__PURE__ */ jsxs17(EstimateRow, { children: [
      /* @__PURE__ */ jsx20(Typography7, { variant: "body2", children: labels?.deploy ?? "Contract Deployment (Now)" }),
      /* @__PURE__ */ jsx20(Typography7, { variant: "body2", minWidth: "40px", textAlign: "end", children: formatPrice(getDeployTotal(dropCost)) })
    ] }),
    hasDistributionCosts && /* @__PURE__ */ jsxs17(EstimateRow, { children: [
      /* @__PURE__ */ jsxs17(Typography7, { variant: "body2", children: [
        labels?.distribution ?? "Distribution Coverage (Later)",
        " "
      ] }),
      /* @__PURE__ */ jsx20(Typography7, { variant: "body2", minWidth: "40px", textAlign: "end", children: formatPrice(distributionSubTotal) })
    ] })
  ] });
};
var DropCostEstimateView = ({
  dropCost,
  expanded,
  labels,
  onlyTotal,
  isDataPending,
  sx
}) => {
  return /* @__PURE__ */ jsxs17(Stack13, { gap: "4px", sx, "data-testid": "cost-estimate-view", children: [
    /* @__PURE__ */ jsxs17(EstimateRow, { children: [
      /* @__PURE__ */ jsx20(Typography7, { variant: "bodyXL", sx: { mr: 1 }, children: labels?.total ?? "Cost Estimate" }),
      isDataPending ? /* @__PURE__ */ jsxs17(Box4, { sx: { display: "flex", columnGap: 1, alignItems: "center" }, children: [
        /* @__PURE__ */ jsx20(CircularProgress3, { size: 30 }),
        /* @__PURE__ */ jsx20(Typography7, { variant: "body1", children: "Loading..." })
      ] }) : /* @__PURE__ */ jsx20(Typography7, { variant: "bodyXL", minWidth: "40px", textAlign: "end", children: formatPrice(getTotalDropCost(dropCost)) })
    ] }),
    !onlyTotal && (expanded ? /* @__PURE__ */ jsx20(ExpandedDetails, { dropCost, labels }) : /* @__PURE__ */ jsx20(
      CollapsedUntilLoadedDetails,
      {
        dropCost,
        labels,
        isDataPending
      }
    ))
  ] });
};

// src/components/DropPayment.tsx
import Stack15 from "@mui/material/Stack";
import {
  useDropCostEstimate as useDropCostEstimate2,
  useDropFlowCostEstimate as useDropFlowCostEstimate2
} from "common-api";
import { Box as Box6 } from "@mui/material";
import { StructuralSkeleton as StructuralSkeleton2 } from "common-ui";

// src/components/BalanceCard.tsx
import { Box as Box5, Button as Button5, Stack as Stack14, Typography as Typography8 } from "@mui/material";
import IconButton2 from "@mui/material/IconButton";
import RefreshIcon2 from "@mui/icons-material/Refresh";
import { useMemo as useMemo3 } from "react";
import { jsx as jsx21, jsxs as jsxs18 } from "react/jsx-runtime";
var BalanceCard = ({ depositAmount }) => {
  const { refetch, balance } = useAuthAccountBalance();
  const minPayment = useMemo3(() => {
    const deposit = depositAmount ? Number(depositAmount) : 0;
    const accountBalance = Number(balance?.total ?? 0);
    return Math.max(0, deposit - accountBalance);
  }, [depositAmount, balance]);
  return /* @__PURE__ */ jsxs18(
    Box5,
    {
      sx: {
        borderRadius: "8px",
        backgroundColor: "secondary_50",
        position: "relative",
        p: 2
      },
      children: [
        /* @__PURE__ */ jsxs18(Stack14, { width: "100%", direction: "row", justifyContent: "space-between", alignItems: "center", children: [
          /* @__PURE__ */ jsxs18(Stack14, { direction: "row", alignItems: "center", color: "text.primary", children: [
            /* @__PURE__ */ jsx21(Typography8, { variant: "body1", children: "Your Account Balance" }),
            /* @__PURE__ */ jsx21(IconButton2, { color: "inherit", "aria-label": "refresh", onClick: refetch, size: "small", children: /* @__PURE__ */ jsx21(RefreshIcon2, {}) })
          ] }),
          /* @__PURE__ */ jsx21(BalanceTotal_default, { variant: "body1", fontWeight: 800 })
        ] }),
        /* @__PURE__ */ jsx21(Stack14, { direction: "row", justifyContent: "flex-end", sx: { padding: 0 }, children: /* @__PURE__ */ jsx21(
          Button5,
          {
            onClick: () => {
              openPaymentsModal({
                page: "deposit",
                minPayment: minPayment > 0 ? minPayment : void 0
              });
            },
            size: "small",
            variant: "contained",
            color: "secondary",
            "data-testid": "balance-card-add-funds",
            children: "Add Funds To Account Balance"
          }
        ) })
      ]
    }
  );
};
var BalanceCard_default = BalanceCard;

// src/components/DropPayment.tsx
import { jsx as jsx22, jsxs as jsxs19 } from "react/jsx-runtime";
var DropPayment = ({ dropId, dropFlowId, renderProp }) => {
  const { balance, isFetching: fetchingBalance, isError: balanceError } = useAuthAccountBalance();
  const {
    data: dropEstimate,
    isFetching: fetchingDropEstimate,
    isPending: pendingDropEstimate,
    isError: dropEstimateError
  } = useDropCostEstimate2(
    !Boolean(dropFlowId) ? dropId ?? "" : "",
    state_default.paymentMethod ?? DEFAULT_ESTIMATE_SALE_TYPE
  );
  const {
    data: flowEstimate,
    isFetching: fetchingFlowEstimate,
    isPending: pendingFlowEstimate,
    isError: flowEstimateError
  } = useDropFlowCostEstimate2(
    dropId,
    Boolean(dropFlowId) ? dropFlowId ?? "" : "",
    state_default.paymentMethod ?? DEFAULT_ESTIMATE_SALE_TYPE
  );
  useAuthAccountBalance();
  const estimate = !Boolean(dropFlowId) ? dropEstimate : flowEstimate;
  const fetchingEstimate = !Boolean(dropFlowId) ? fetchingDropEstimate : fetchingFlowEstimate;
  const estimateError = !Boolean(dropFlowId) ? dropEstimateError : flowEstimateError;
  return /* @__PURE__ */ jsxs19(Stack15, { gap: "12px", children: [
    /* @__PURE__ */ jsx22(DropCostEstimateBlock, { dropId, dropFlowId, expanded: true }),
    !fetchingDropEstimate && !fetchingFlowEstimate && !pendingDropEstimate && !pendingFlowEstimate ? /* @__PURE__ */ jsx22(BalanceCard_default, { depositAmount: estimate?.sub_total }) : /* @__PURE__ */ jsx22(Box6, { sx: { height: "75px" }, children: /* @__PURE__ */ jsx22(StructuralSkeleton2, { columnDivisions: 1, rowDivisions: 1 }) }),
    renderProp({
      balance,
      estimate,
      isDataFetching: fetchingBalance || fetchingEstimate,
      isError: balanceError || estimateError
    })
  ] });
};

// src/components/ActionPayment.tsx
import Stack16 from "@mui/material/Stack";
import { useStatelessDropCostEstimate } from "common-api";
import { StructuralSkeleton as StructuralSkeleton3 } from "common-ui";
import { Box as Box7 } from "@mui/material";
import { jsx as jsx23, jsxs as jsxs20 } from "react/jsx-runtime";
var ActionCostEstimateBlock = ({
  costEstimationDetails,
  onlyTotal,
  labels
}) => {
  const { data, isPending } = useStatelessDropCostEstimate(costEstimationDetails);
  return /* @__PURE__ */ jsx23(
    DropCostEstimateView,
    {
      dropCost: data,
      onlyTotal,
      labels,
      isDataPending: isPending
    }
  );
};
var ActionPayment = ({
  costEstimationDetails,
  labels,
  onlyTotal,
  renderProp
}) => {
  const {
    balance,
    isPending: balancePending,
    isFetching: balanceFetching,
    isError: balanceError
  } = useAuthAccountBalance();
  const {
    data: estimate,
    isPending: estimatePending,
    isFetching: estimateFetching,
    isError: estimateError
  } = useStatelessDropCostEstimate(costEstimationDetails);
  useAuthAccountBalance();
  return /* @__PURE__ */ jsxs20(Stack16, { gap: "12px", children: [
    /* @__PURE__ */ jsx23(
      ActionCostEstimateBlock,
      {
        costEstimationDetails,
        labels,
        onlyTotal
      }
    ),
    !estimateFetching && !estimatePending ? /* @__PURE__ */ jsx23(BalanceCard_default, { depositAmount: estimate?.sub_total }) : /* @__PURE__ */ jsx23(Box7, { sx: { height: "75px" }, children: /* @__PURE__ */ jsx23(StructuralSkeleton3, { columnDivisions: 1, rowDivisions: 1 }) }),
    renderProp({
      balance,
      estimate,
      isDataPending: balancePending || estimatePending,
      isDataFetching: balanceFetching || estimateFetching,
      isError: balanceError || estimateError
    })
  ] });
};

// src/plugins/payments/Plugin.tsx
import { jsx as jsx24, jsxs as jsxs21 } from "react/jsx-runtime";
var log = debug("payments:PaymentsPlugin");
var NETWORK_ID_TO_CHAIN = {
  80002: customAmoyTestnet,
  137: polygon
};
var PaymentsPlugin = class extends Plugin {
  get info() {
    return {
      name: "payments",
      title: "Payments",
      disabledByDefault: false
    };
  }
  async activate(registry) {
    const { wcProjectId, paymentsNetworkId } = getConfig2(registry);
    const { stripeKey } = getConfig2(registry);
    const chain = NETWORK_ID_TO_CHAIN[paymentsNetworkId];
    registry.fillSlot(PORTAL_TOP_BAR_SLOT.id, /* @__PURE__ */ jsx24(BalanceChip_default, {}), {
      order: 0
    });
    registry.fillSlot(
      "drop.cost",
      ({ props }) => /* @__PURE__ */ jsx24(DropCostEstimateBlock, { ...props })
    );
    registry.fillSlot(
      "drop.cost-estimate.context",
      ({
        props
      }) => /* @__PURE__ */ jsx24(DropPayment, { ...props })
    );
    registry.fillSlot(
      "drop.cost-estimate.view-existing",
      ({ props }) => /* @__PURE__ */ jsx24(DropCostEstimateView, { ...props })
    );
    registry.fillSlot(
      "action.cost-estimate.context",
      ({
        props
      }) => /* @__PURE__ */ jsx24(ActionPayment, { ...props })
    );
    registry.registerCommand({
      id: "portal.payments.open",
      title: "Create Payments",
      handler: ({ page, amount }) => {
        openPaymentsModal({ page, amount });
      }
    });
    registry.subscribeEvent("auth.platform.set", async (event) => {
      const { org } = event.data;
      log("Setting default payment method to:", org.default_payment_method);
      setDefaultPaymentMethod(org.default_payment_method);
    });
    log("paymentsNetworkId", paymentsNetworkId);
    if (!wcProjectId) {
      log("Missing WalletConnect project ID");
      return;
    }
    if (!chain) {
      log("Unsupported network ID", paymentsNetworkId);
      return;
    }
    const config = getDefaultConfig({
      appName: `CNFT Creator Portal`,
      projectId: wcProjectId,
      chains: [chain],
      transports: {
        [customAmoyTestnet.id]: http(),
        [polygon.id]: http()
      },
      wallets: [
        {
          groupName: "Recommended",
          wallets: [metaMaskWallet]
        },
        {
          groupName: "Supported",
          wallets: [injectedWallet, walletConnectWallet]
        }
      ]
    });
    registry.fillSlot(
      "shell.content",
      ({ children }) => /* @__PURE__ */ jsx24(WagmiProvider, { config, children: /* @__PURE__ */ jsxs21(RainbowKitProvider, { initialChain: chain, children: [
        /* @__PURE__ */ jsx24(MainModal, {}),
        children
      ] }) }),
      { container: true, order: 1 }
    );
    if (!stripeKey) {
      log("Stripe key not set, skipping stripe initialization");
      return;
    }
    const stripePromise = loadStripe(stripeKey);
    state_default.stripePromise = stripePromise;
  }
};

// src/abi/PlatformDeposit.ts
var PlatformDeposit_default = [
  {
    inputs: [
      {
        internalType: "address",
        name: "paymentTokenAddress_",
        type: "address"
      },
      {
        internalType: "address",
        name: "payoutAddress_",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "maxDeposit_",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "constructor"
  },
  {
    inputs: [],
    name: "CannotBeZeroAddress",
    type: "error"
  },
  {
    inputs: [],
    name: "CannotExceedMaxDeposit",
    type: "error"
  },
  {
    inputs: [],
    name: "InsufficientBalance",
    type: "error"
  },
  {
    inputs: [],
    name: "ValueCannotBeZero",
    type: "error"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "clientId",
        type: "bytes32"
      },
      {
        indexed: false,
        internalType: "address",
        name: "depositor",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256"
      }
    ],
    name: "Deposit",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address"
      }
    ],
    name: "OwnershipTransferStarted",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address"
      }
    ],
    name: "OwnershipTransferred",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "clientId",
        type: "bytes32"
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "purchaseId",
        type: "bytes32"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256"
      }
    ],
    name: "PaymentTransfer",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32"
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "previousAdminRole",
        type: "bytes32"
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "newAdminRole",
        type: "bytes32"
      }
    ],
    name: "RoleAdminChanged",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32"
      },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address"
      }
    ],
    name: "RoleGranted",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32"
      },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address"
      }
    ],
    name: "RoleRevoked",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "clientId",
        type: "bytes32"
      },
      {
        indexed: false,
        internalType: "address",
        name: "depositor",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256"
      }
    ],
    name: "Withdraw",
    type: "event"
  },
  {
    inputs: [],
    name: "DEFAULT_ADMIN_ROLE",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "PAYOUT_EXECUTOR",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "acceptOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "accounts",
        type: "address[]"
      }
    ],
    name: "addPayoutRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "clientId",
        type: "bytes32"
      }
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "clientId",
        type: "bytes32"
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256"
      }
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32"
      }
    ],
    name: "getRoleAdmin",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32"
      },
      {
        internalType: "address",
        name: "account",
        type: "address"
      }
    ],
    name: "grantRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32"
      },
      {
        internalType: "address",
        name: "account",
        type: "address"
      }
    ],
    name: "hasRole",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "maxDeposit",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "paymentTokenAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "payoutAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "pendingOwner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "clientId",
        type: "bytes32"
      },
      {
        internalType: "bytes32",
        name: "purchaseId",
        type: "bytes32"
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256"
      }
    ],
    name: "purchasePayout",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "accounts",
        type: "address[]"
      }
    ],
    name: "removePayoutRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32"
      },
      {
        internalType: "address",
        name: "account",
        type: "address"
      }
    ],
    name: "renounceRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32"
      },
      {
        internalType: "address",
        name: "account",
        type: "address"
      }
    ],
    name: "revokeRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "payoutAddress_",
        type: "address"
      }
    ],
    name: "setPayoutAddress",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4"
      }
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address"
      }
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "clientId",
        type: "bytes32"
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256"
      }
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "clientId",
        type: "bytes32"
      },
      {
        internalType: "address",
        name: "depositor",
        type: "address"
      }
    ],
    name: "withdrawable",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  }
];
export {
  PaymentsPlugin,
  PlatformDeposit_default as PlatformDepositAbi,
  addGasMargin,
  customAmoyTestnet,
  decimalStringToUSDC,
  erc20Abi2 as erc20Abi,
  getContractAddress,
  getContractDeployments,
  getContractNetworks,
  platformDepositAbi,
  platformDepositAddress,
  platformDepositConfig,
  prepareWriteErc20,
  prepareWriteErc20Approve,
  prepareWriteErc20Transfer,
  prepareWriteErc20TransferFrom,
  prepareWritePlatformDeposit,
  prepareWritePlatformDepositAcceptOwnership,
  prepareWritePlatformDepositAddPayoutRole,
  prepareWritePlatformDepositDeposit,
  prepareWritePlatformDepositGrantRole,
  prepareWritePlatformDepositPurchasePayout,
  prepareWritePlatformDepositRemovePayoutRole,
  prepareWritePlatformDepositRenounceOwnership,
  prepareWritePlatformDepositRenounceRole,
  prepareWritePlatformDepositRevokeRole,
  prepareWritePlatformDepositSetPayoutAddress,
  prepareWritePlatformDepositTransferOwnership,
  prepareWritePlatformDepositWithdraw,
  readErc20,
  readErc20Allowance,
  readErc20BalanceOf,
  readErc20Decimals,
  readErc20Name,
  readErc20Symbol,
  readErc20TotalSupply,
  readPlatformDeposit,
  readPlatformDepositBalanceOf,
  readPlatformDepositDefaultAdminRole,
  readPlatformDepositGetRoleAdmin,
  readPlatformDepositHasRole,
  readPlatformDepositMaxDeposit,
  readPlatformDepositOwner,
  readPlatformDepositPaymentTokenAddress,
  readPlatformDepositPayoutAddress,
  readPlatformDepositPayoutExecutor,
  readPlatformDepositPendingOwner,
  readPlatformDepositSupportsInterface,
  readPlatformDepositWithdrawable,
  usdcToDecimalString,
  useErc20Allowance,
  useErc20ApprovalEvent,
  useErc20Approve,
  useErc20BalanceOf,
  useErc20Decimals,
  useErc20Event,
  useErc20Name,
  useErc20Read,
  useErc20Symbol,
  useErc20TotalSupply,
  useErc20Transfer,
  useErc20TransferEvent,
  useErc20TransferFrom,
  useErc20Write,
  usePlatformDepositAcceptOwnership,
  usePlatformDepositAddPayoutRole,
  usePlatformDepositBalanceOf,
  usePlatformDepositDefaultAdminRole,
  usePlatformDepositDeposit,
  usePlatformDepositDepositEvent,
  usePlatformDepositEvent,
  usePlatformDepositGetRoleAdmin,
  usePlatformDepositGrantRole,
  usePlatformDepositHasRole,
  usePlatformDepositMaxDeposit,
  usePlatformDepositOwner,
  usePlatformDepositOwnershipTransferStartedEvent,
  usePlatformDepositOwnershipTransferredEvent,
  usePlatformDepositPaymentTokenAddress,
  usePlatformDepositPaymentTransferEvent,
  usePlatformDepositPayoutAddress,
  usePlatformDepositPayoutExecutor,
  usePlatformDepositPendingOwner,
  usePlatformDepositPurchasePayout,
  usePlatformDepositRead,
  usePlatformDepositRemovePayoutRole,
  usePlatformDepositRenounceOwnership,
  usePlatformDepositRenounceRole,
  usePlatformDepositRevokeRole,
  usePlatformDepositRoleAdminChangedEvent,
  usePlatformDepositRoleGrantedEvent,
  usePlatformDepositRoleRevokedEvent,
  usePlatformDepositSetPayoutAddress,
  usePlatformDepositSupportsInterface,
  usePlatformDepositTransferOwnership,
  usePlatformDepositWithdraw,
  usePlatformDepositWithdrawEvent,
  usePlatformDepositWithdrawable,
  usePlatformDepositWrite,
  usePrepareErc20Approve,
  usePrepareErc20Transfer,
  usePrepareErc20TransferFrom,
  usePreparePlatformDepositAcceptOwnership,
  usePreparePlatformDepositAddPayoutRole,
  usePreparePlatformDepositDeposit,
  usePreparePlatformDepositGrantRole,
  usePreparePlatformDepositPurchasePayout,
  usePreparePlatformDepositRemovePayoutRole,
  usePreparePlatformDepositRenounceOwnership,
  usePreparePlatformDepositRenounceRole,
  usePreparePlatformDepositRevokeRole,
  usePreparePlatformDepositSetPayoutAddress,
  usePreparePlatformDepositTransferOwnership,
  usePreparePlatformDepositWithdraw,
  uuidToBytes32,
  watchErc20ApprovalEvent,
  watchErc20Event,
  watchErc20TransferEvent,
  watchPlatformDepositDepositEvent,
  watchPlatformDepositEvent,
  watchPlatformDepositOwnershipTransferStartedEvent,
  watchPlatformDepositOwnershipTransferredEvent,
  watchPlatformDepositPaymentTransferEvent,
  watchPlatformDepositRoleAdminChangedEvent,
  watchPlatformDepositRoleGrantedEvent,
  watchPlatformDepositRoleRevokedEvent,
  watchPlatformDepositWithdrawEvent,
  writeErc20,
  writeErc20Approve,
  writeErc20Transfer,
  writeErc20TransferFrom,
  writePlatformDeposit,
  writePlatformDepositAcceptOwnership,
  writePlatformDepositAddPayoutRole,
  writePlatformDepositDeposit,
  writePlatformDepositGrantRole,
  writePlatformDepositPurchasePayout,
  writePlatformDepositRemovePayoutRole,
  writePlatformDepositRenounceOwnership,
  writePlatformDepositRenounceRole,
  writePlatformDepositRevokeRole,
  writePlatformDepositSetPayoutAddress,
  writePlatformDepositTransferOwnership,
  writePlatformDepositWithdraw
};
